import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import useEntityData from '../../containers/api/useEntityData';
import { SystemUser } from './dto';

const useAdminData = () => {
  const { entity, fetchEntity } = useEntityData<SystemUser>({
    url: API_CONSTANTS.USER_CURRENT
  });

  return {
    admin: entity,
    fetchAdmin: fetchEntity
  };
};

export default useAdminData;

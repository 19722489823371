import p from '../../components/Page';
import { styled } from '@mui/material/styles';

export const RootStyle = styled(p as any)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  zIndex: 10,
  position: 'relative',
  width: '100%',
  height: '100%'
}));

export const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2, 0)
}));

export const CardDivider = styled('div')(({ theme }) => ({
  width: 1,
  height: 150,
  marginLeft: 40,
  borderRight: '1px solid lightgray',
  marginRight: 40
}));

import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { BillAddSchema, initialValues } from './schema';
import TextField from '../../components/TextField';
import { AddBillDTO, AxiosContext } from '../../containers/api';
import React, { useContext, useEffect } from 'react';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import { SnackbarContext } from '../../containers/SnackbarContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import useStyles from '../../components/AppModal/styles';
import useUserPermissions from '../../hooks/useUserPermissions';
import { useMetaDate } from '../../containers/MetaDataContext';
import { useAuth } from '../../containers/AuthContext';

// ----------------------------------------------------------------------

const mapFormToAddDTO = (values: any): AddBillDTO => {
  return {
    notes: values.notes,
    password: values.password,
    points: values.points
  };
};

export const BillForm: React.FC<{
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onRefetch: () => void;
  onAddBill: () => void;
}> = ({ isModalOpened, setIsModalOpened, onRefetch, onAddBill }) => {
  const permissions = useUserPermissions();
  const { publicAxios } = useContext(AxiosContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { metaDataState } = useMetaDate();
  const auth = useAuth();
  const classes = useStyles();

  const formik = useFormik<any>({
    initialValues: initialValues,
    validationSchema: BillAddSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  const { errors, touched, values, getFieldProps, resetForm, isValid } = formik;

  useEffect(() => {
    resetForm();
  }, [isModalOpened]);

  const validateForm = () => {
    if ((metaDataState?.currentUser?.points ?? 0) < values.points) {
      showSnackbar({
        message: `قيمة سند الصرف اكبر من رصيد المستخدم (${metaDataState?.currentUser?.points}) `,
        severity: 'error'
      });
      return false;
    }

    return true;
  };

  const onSubmitForm = () => {
    if (!validateForm()) {
      return;
    }
    publicAxios
      .post(API_CONSTANTS.ADD_BILL, mapFormToAddDTO(values))
      .then(() => {
        showSnackbar({ message: 'تمت اضافة سند صرف بنجاح' });
        resetForm();
        setIsModalOpened(false);
        auth?.refetchCurrentUser(publicAxios);
        onRefetch();
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
      });
  };

  const handleClose = () => {
    resetForm();
    setIsModalOpened(false);
  };

  return (
    <>
      {permissions.transactions.addBill && (
        <Button variant='contained' onClick={onAddBill}>
          + اضافة سند صرف
        </Button>
      )}
      {isModalOpened && (
        <Dialog
          open={isModalOpened}
          onClose={() => {}}
          scroll='paper'
          fullWidth
          disableEscapeKeyDown
          aria-labelledby='app-modal-title'
          aria-describedby='app-modal-description'
        >
          <DialogTitle id='app-modal-title'>اضافة سند صرف</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='number'
                  label='القيمة'
                  {...getFieldProps('points')}
                  variant='filled'
                  error={Boolean(touched.points && errors.points)}
                  helperText={touched.points && errors.points}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='password'
                  label='كلمة السر'
                  {...getFieldProps('password')}
                  variant='filled'
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  type='text'
                  label='ملاحظات'
                  {...getFieldProps('notes')}
                  variant='filled'
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.actionButton}
              color='error'
              variant='contained'
              onClick={handleClose}
            >
              الغاء
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={!isValid}
              onClick={() => onSubmitForm()}
            >
              دفع
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default BillForm;

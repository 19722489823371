import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '../TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useResetPassword from '../../containers/api/useResetPassword';

export const ChangeUserPasswordDialog: React.FC<{
  userName: string;
  open: boolean;
  setOpen: (val: boolean) => void;
}> = ({ userName, open, setOpen }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const { changePassword } = useResetPassword();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePassword = () => {
    changePassword('account', userName, oldPassword, newPassword).then(() => {
      setOldPassword('');
      setNewPassword('');
      setOpen(false);
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>تغيير كلمة السر</DialogTitle>
      <DialogContent>
        <DialogContentText>
          يرجى ادخال كلمة السر الحالية والجديدة
        </DialogContentText>
        <TextField
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          autoFocus
          label='كلمة السر الحالية'
          type='password'
          variant='filled'
          fullWidth
        />
        <TextField
          sx={{ marginTop: 2 }}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          variant='filled'
          label='كلمة السر الجديدة'
          type='password'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>الغاء</Button>
        <Button onClick={handleChangePassword}>حفظ</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeUserPasswordDialog;

import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { MarketSettleUpSchema, marketSettleUpInitialValues } from './schema';
import TextField from '../../components/TextField';
import {
  Market,
  AxiosContext,
  MarketPointsSettleUpDTO
} from '../../containers/api';
import React, { useContext, useEffect } from 'react';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import { SnackbarContext } from '../../containers/SnackbarContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import useStyles from '../../components/AppModal/styles';
import { useMetaDate } from '../../containers/MetaDataContext';

export const MarketPointsSettleUpModal: React.FC<{
  market?: Market;
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}> = ({ market, isOpen, onSubmit, onClose }) => {
  const { publicAxios } = useContext(AxiosContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { metaDataState } = useMetaDate();
  const classes = useStyles();

  const {
    errors,
    touched,
    values,
    getFieldProps,
    resetForm,
    isValid,
    setFieldValue
  } = useFormik<any>({
    initialValues: marketSettleUpInitialValues,
    validationSchema: MarketSettleUpSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  useEffect(() => {
    resetForm();
    if (isOpen) {
      setFieldValue('marketUsername', market?.username);
      setFieldValue(
        'salesRepAdminUsername',
        metaDataState?.currentUser?.username
      );
    }
  }, [isOpen]);

  const validateForm = (values: MarketPointsSettleUpDTO, market?: Market) => {
    if ((market?.commissionPoints ?? 0) < values?.points) {
      showSnackbar({
        message: 'الحد الاقصى المسموح به هو: ' + market?.commissionPoints,
        severity: 'error'
      });
      return false;
    }
    return true;
  };

  const onSubmitForm = () => {
    const validForm = validateForm(values, market);
    if (!validForm) {
      return;
    }
    publicAxios
      .post(API_CONSTANTS.MARKET_SETTLE_UP, values)
      .then(() => {
        showSnackbar({ message: 'تم الاعفاء بنجاح' });
        resetForm();
        onSubmit();
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
      });
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const getPointsLimits = () => {
    const max = values?.type === 'Withdrawal' ? market?.points : null;
    return { min: 1, max };
  };

  return (
    <>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={() => {}}
          scroll='paper'
          fullWidth
          disableEscapeKeyDown
          aria-labelledby='app-modal-title'
          aria-describedby='app-modal-description'
        >
          <DialogTitle id='app-modal-title'>إعفاء عمولة</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='number'
                  label='القيمة'
                  inputProps={getPointsLimits()}
                  {...getFieldProps('points')}
                  variant='filled'
                  error={Boolean(touched.points && errors.points)}
                  helperText={touched.points && errors.points}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='password'
                  label='كلمة السر'
                  {...getFieldProps('password')}
                  variant='filled'
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
                <TextField
                  fullWidth
                  type='hidden'
                  {...getFieldProps('marketUsername')}
                  style={{ display: 'none' }}
                />
                <TextField
                  fullWidth
                  type='hidden'
                  {...getFieldProps('salesRepAdminUsername')}
                  style={{ display: 'none' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  type='text'
                  label='ملاحظات'
                  {...getFieldProps('notes')}
                  variant='filled'
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.actionButton}
              color='error'
              variant='contained'
              onClick={handleClose}
            >
              الغاء
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={!isValid}
              onClick={() => onSubmitForm()}
            >
              {values?.type === 'Withdrawal' ? 'استلام' : 'دفع'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MarketPointsSettleUpModal;

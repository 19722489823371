import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import TextField from '../TextField';
import useStyles from './styles';
import { useDebounce } from '../../hooks/useDebounce';

interface AutoCompleteProps {
  inputLabel: string;
  isLoading: boolean;
  optionLabelAccessor: string;
  options: Array<any>;
  defaultSelections?: any;
  error?: boolean;
  helperText?: string;
  onSelectionChange: (value: any) => void;
  fetchOptions: (searchText: string) => void;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  inputLabel,
  options: entities,
  defaultSelections,
  isLoading,
  optionLabelAccessor,
  error = false,
  helperText = '',
  fetchOptions,
  onSelectionChange
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue);

  const classes = useStyles();

  useEffect(() => {
    let isStillRendered = true;
    if (isStillRendered) {
      fetchOptions(searchValue);
    }
    return () => {
      isStillRendered = false;
    };
  }, [debouncedValue]);

  useEffect(() => {
    if (isLoading) {
      setOptions([]);
    } else {
      setOptions(entities);
    }
  }, [isLoading, entities]);

  const handleSelectionChange = (e: any, val: any) => {
    if (!val) {
      setSearchValue('');
    }
    onSelectionChange(val);
  };

  return (
    <Autocomplete
      disablePortal
      fullWidth
      id='combo-box-demo'
      options={options}
      isOptionEqualToValue={(option, value) =>
        (value.id ?? value?.[0]?.id) === option.id
      }
      getOptionLabel={(option) =>
        option[optionLabelAccessor] ?? option?.[0]?.[optionLabelAccessor] ?? '-'
      }
      filterOptions={(x) => x}
      defaultValue={defaultSelections}
      noOptionsText='لا يوجد بيانات'
      loading={isLoading}
      loadingText='جاري البحث...'
      filterSelectedOptions
      onChange={handleSelectionChange}
      classes={{ endAdornment: classes.icon, inputRoot: classes.input }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          fullWidth
          variant='filled'
          label={inputLabel}
          inputProps={{
            ...params?.inputProps,
            onChange: (e: any) => {
              if (params?.inputProps?.onChange) {
                params.inputProps.onChange(e);
              }
              setSearchValue(e.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>{params.InputProps?.endAdornment}</React.Fragment>
            )
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default AutoComplete;

import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Popover,
  TableHead,
  TableSortLabel
} from '@mui/material';
import p from '../../components/Page';
import Scroll from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  TransactionsListHead,
  TransactionsListToolbar
} from '../../sections/@dashboard/transactions';
import ItemMoreMenu from '../../components/ItemMoreMenu';
import useEntityData from '../../containers/api/useEntityData';
import { Transaction as TransactionDTO } from '../../containers/api/dto';
import { formatDecimals, formatDateTime } from '../../utils';
import AppBackdrop from '../../components/AppBackdrop';
import Iconify from '../../components/Iconify';
import { AppDialogContext } from '../../containers/AppDialogContext';
import moment from 'moment';
import BillForm from './BillForm';
import useUserPermissions from '../../hooks/useUserPermissions';
import { DEFAULT_FILTERS_STATE } from './Constants';

const Scrollbar: any = Scroll;
const Page: any = p;
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'type', label: 'نوع الحركة', alignRight: false },
  { id: 'firstPartyUsername', label: 'الطرف الاول', alignRight: false },
  { id: 'secondPartyUsername', label: 'الطرف الثاني', alignRight: false },
  { id: 'amount', label: 'قيمة الحوالة (₪)', alignRight: false },
  { id: 'transactionDate', label: 'تاريخ الحوالة', alignRight: false },
  { id: 'notes', label: 'الملاحظات', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

const Transactions: React.FC = () => {
  const [page, setPage] = useState(0);
  // TODO: combine filters into one state
  const [isArchived, setIsArchived] = useState(
    DEFAULT_FILTERS_STATE.isArchived
  );
  const [filterMarketName, setFilterMarketName] = useState(
    DEFAULT_FILTERS_STATE.filterMarketName
  );
  const [filterCategory, setFilterCategory] = useState(
    DEFAULT_FILTERS_STATE.filterCategory
  );
  const [filterType, setFilterType] = useState(
    DEFAULT_FILTERS_STATE.filterType
  );
  const [filterSalesRepName, setFilterSalesRepName] = useState(
    DEFAULT_FILTERS_STATE.filterSalesRepName
  );
  const [filterSalesRepAdminName, setFilterSalesRepAdminName] = useState(
    DEFAULT_FILTERS_STATE.filterSalesRepAdminName
  );
  const [filterStartDate, setFilterStartDate] = useState(
    DEFAULT_FILTERS_STATE.filterStartDate
  );
  const [filterEndDate, setFilterEndDate] = useState(
    DEFAULT_FILTERS_STATE.filterEndDate
  );

  // Totals
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalBill, setTotalBill] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const [totalSettleUp, setTotalSettleUp] = useState(0);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [hoveredTransaction, setHoveredTransaction] = useState<
    TransactionDTO | undefined
  >();
  const appDialogService = useContext(AppDialogContext);
  const permissions = useUserPermissions();

  const [rowsPerPage, setRowsPerPage] = useState(8);

  const { fetchEntities, entities, totalCount, isLoading, deleteEntity } =
    useEntityData<TransactionDTO>({
      url: `transaction`,
      entityKey: 'transactions',
      entityName: 'الحوالات'
    });

  useEffect(() => {
    handleFetchData();
  }, [page, rowsPerPage]);

  const handleFetchData = (eagerFilters: any = {}) => {
    fetchEntities({
      queryParams: {
        salesRepAdminName:
          eagerFilters?.filterSalesRepAdminName ?? filterSalesRepAdminName,
        salesRepName: eagerFilters?.filterSalesRepName ?? filterSalesRepName,
        marketName: eagerFilters?.filterMarketName ?? filterMarketName,
        type: eagerFilters?.filterType ?? filterType,
        category: `${eagerFilters?.filterCategory ?? filterCategory}`,
        isArchived: `${eagerFilters?.isArchived ?? isArchived}`,
        startDate: eagerFilters?.filterStartDate ?? filterStartDate,
        endDate: eagerFilters?.filterEndDate ?? filterEndDate,
        Page: page + 1,
        PageSize: rowsPerPage
      }
    }).then((response: any) => {
      setTotalDeposit(response.totalDeposit);
      setTotalBill(response.totalBill);
      setTotalWithdrawal(response.totalWithdrawal);
      setTotalSettleUp(response.totalSettleUp);
    });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterMarketName = (event: any) => {
    setFilterMarketName(event?.target?.value);
  };
  const handleFilterCategory = (event: any) => {
    setFilterCategory(event?.target?.value);
    handleSearch({ isArchived, filterCategory: event?.target?.value });
  };
  const handleFilterType = (event: any) => {
    setFilterType(event?.target?.value);
  };
  const handleIsArchived = (event: any) => {
    setIsArchived(event?.target?.checked);
    handleSearch({ isArchived: event?.target?.checked });
  };

  const handleFilterSalesRepName = (event: any) => {
    setFilterSalesRepName(event?.target?.value);
  };
  const handleFilterSalesRepAdminName = (event: any) => {
    setFilterSalesRepAdminName(event?.target?.value);
  };
  const handleFilterStartDate = (event: Date | null) => {
    setFilterStartDate(
      event ? moment(event).startOf('day').utc().format() : ''
    );
  };
  const handleFilterEndDate = (event: Date | null) => {
    setFilterEndDate(event ? moment(event).endOf('day').utc().format() : '');
  };

  const handleSearch = (eagerFilters?: any) => {
    setPage(0);
    handleFetchData(eagerFilters);
  };

  const handleReset = () => {
    setPage(0);
    setFilterMarketName(DEFAULT_FILTERS_STATE.filterMarketName);
    setFilterCategory(DEFAULT_FILTERS_STATE.filterCategory);
    setFilterType(DEFAULT_FILTERS_STATE.filterType);
    setFilterSalesRepName(DEFAULT_FILTERS_STATE.filterSalesRepName);
    setFilterSalesRepAdminName(DEFAULT_FILTERS_STATE.filterSalesRepAdminName);
    setFilterStartDate(DEFAULT_FILTERS_STATE.filterStartDate);
    setFilterEndDate(DEFAULT_FILTERS_STATE.filterEndDate);
    setIsArchived(false);
    handleFetchData({ ...DEFAULT_FILTERS_STATE });
  };

  const handleArchiveTransaction = (transaction: TransactionDTO) => {
    appDialogService.showAppDialog({
      onSubmit: () => {
        deleteEntity(transaction.id, { message: 'تم أرشفة الحركة بنجاح' });
        appDialogService.hideAppDialog();
      },
      title: 'تأكيد العملية',
      text: 'هل انت متأكد من أرشفة الحركة'
    });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCount) : 0;

  const isEmptyList = entities.length === 0;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (transaction: TransactionDTO, event: any) => {
    setHoveredTransaction(transaction);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setHoveredTransaction(undefined);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleAddBill = () => {
    setIsModalOpened(true);
  };

  const handleRefetch = () => {
    handleFetchData();
  };

  const tableHeaders = useMemo(() => {
    const newHeaders = TABLE_HEAD.slice();
    const firstPartyUsername = newHeaders.find(
      (x) => x.id === 'firstPartyUsername'
    );
    const secondPartyUsername = newHeaders.find(
      (x) => x.id === 'secondPartyUsername'
    );
    if (firstPartyUsername && secondPartyUsername) {
      if (filterCategory === 'SalesRep_Market') {
        firstPartyUsername.label = 'مندوب المبيعات';
        secondPartyUsername.label = 'المتجر';
      }
      if (filterCategory === 'SalesRepAdmin_SalesRep') {
        firstPartyUsername.label = 'المشرف';
        secondPartyUsername.label = 'مندوب المبيعات';
      }
      if (filterCategory === 'SalesRepAdmin_Market') {
        firstPartyUsername.label = 'المشرف';
        secondPartyUsername.label = 'المتجر';
      }
      if (filterCategory === 'Bill') {
        firstPartyUsername.label = 'المشرف';
        secondPartyUsername.label = '-';
      }
    }
    return newHeaders;
  }, [filterCategory]);

  const shouldShowTransTotals =
    filterCategory === 'SalesRepAdmin_SalesRep' ||
    filterCategory === 'SalesRep_Market';

  return (
    <Page title='اكسترا كاش'>
      <Container>
        <Popover
          id='mouse-over-popover'
          sx={{
            pointerEvents: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='left' style={{ textAlign: 'right' }}>
                    <TableSortLabel hideSortIcon active={false}>
                      الملاحظات
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover key={hoveredTransaction?.id} tabIndex={-1}>
                  <TableCell align='right'>
                    {hoveredTransaction?.notes}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Popover>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            الحركات
          </Typography>
          <BillForm
            isModalOpened={isModalOpened}
            setIsModalOpened={setIsModalOpened}
            onRefetch={handleRefetch}
            onAddBill={handleAddBill}
          />
        </Stack>

        <Card>
          <AppBackdrop open={isLoading} />
          <TransactionsListToolbar
            filterCategory={filterCategory}
            onFilterCategory={handleFilterCategory}
            filterType={filterType}
            onFilterType={handleFilterType}
            filterMarketName={filterMarketName}
            onFilterMarketName={handleFilterMarketName}
            isArchived={isArchived}
            onIsArchived={handleIsArchived}
            filterSalesRepName={filterSalesRepName}
            onFilterSalesRepName={handleFilterSalesRepName}
            filterSalesRepAdminName={filterSalesRepAdminName}
            onFilterSalesRepAdminName={handleFilterSalesRepAdminName}
            filterStartDate={filterStartDate}
            onFilterStartDate={handleFilterStartDate}
            filterEndDate={filterEndDate}
            onFilterEndDate={handleFilterEndDate}
            onSearch={handleSearch}
            onReset={handleReset}
          />
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            {shouldShowTransTotals && (
              <>
                <Typography
                  component='span'
                  sx={{ marginRight: 2 }}
                  variant='subtitle2'
                >
                  مجموع المدفوعات: {totalDeposit}
                </Typography>
                <Typography
                  component='span'
                  sx={{ marginRight: 2 }}
                  variant='subtitle2'
                >
                  مجموع المستردات: {totalWithdrawal}
                </Typography>
              </>
            )}
            {filterCategory === 'SalesRepAdmin_Market' && (
              <Typography
                component='span'
                sx={{ marginRight: 2 }}
                variant='subtitle2'
              >
                مجموع الاعفاءات: {totalSettleUp}
              </Typography>
            )}
            {filterCategory === 'Bill' && (
              <Typography
                component='span'
                sx={{ marginRight: 2 }}
                variant='subtitle2'
              >
                مجموع سندات الصرف: {totalBill}
              </Typography>
            )}
          </div>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TransactionsListHead
                  headLabel={tableHeaders}
                  rowCount={totalCount}
                />
                <TableBody>
                  {entities.map((row: TransactionDTO) => {
                    const {
                      id,
                      amount,
                      firstPartyUsername,
                      secondPartyUsername,
                      typeDisplay,
                      isArchived,
                      transactionDate,
                      notes
                    } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role='checkbox'>
                        <TableCell component='th' scope='row' align='right'>
                          <Stack
                            direction='row'
                            alignItems='center'
                            spacing={2}
                          >
                            <Typography variant='subtitle2' noWrap>
                              {typeDisplay}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align='right'>
                          {firstPartyUsername}
                        </TableCell>
                        <TableCell align='right'>
                          {secondPartyUsername}
                        </TableCell>
                        <TableCell align='right'>
                          {formatDecimals(amount)}
                        </TableCell>
                        <TableCell align='right'>
                          {formatDateTime(transactionDate)}
                        </TableCell>
                        <TableCell align='right'>
                          {!!notes?.length && (
                            <>
                              <IconButton
                                color='primary'
                                aria-owns={
                                  open ? 'mouse-over-popover' : undefined
                                }
                                aria-haspopup='true'
                                onMouseEnter={(e) => handlePopoverOpen(row, e)}
                                onMouseLeave={handlePopoverClose}
                              >
                                <Iconify
                                  icon='ci:info-circle'
                                  width={20}
                                  height={20}
                                  sx={undefined}
                                />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          {permissions.transactions.archive && !isArchived ? (
                            <ItemMoreMenu
                              options={[
                                {
                                  label: 'أرشفة',
                                  action: () => handleArchiveTransaction(row),
                                  icon: 'gridicons:trash'
                                }
                              ]}
                            />
                          ) : (
                            <span />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isEmptyList && (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery='' />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[8, 16, 32]}
            component='div'
            count={totalCount}
            labelRowsPerPage='العدد لكل صفحة'
            labelDisplayedRows={({ from, to, page, count }) => {
              return `${from} - ${to} (العدد الكلي ${count})`;
            }}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default Transactions;

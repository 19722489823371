const DEFAULT_FILTERS_STATE = {
  isArchived: false,
  filterMarketName: '',
  filterCategory: 'SalesRep_Market',
  filterType: '',
  filterSalesRepName: '',
  filterSalesRepAdminName: '',
  filterStartDate: '',
  filterEndDate: ''
};

export { DEFAULT_FILTERS_STATE };

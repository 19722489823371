import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import useEntityData from '../../containers/api/useEntityData';

const useRolesData = () => {
  const { entities, fetchEntities, isLoading } = useEntityData<any>({
    url: API_CONSTANTS.ROLES
  });

  return {
    isLoading,
    roles: entities as string[],
    fetchRoles: fetchEntities
  };
};

export default useRolesData;

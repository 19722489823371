import * as Yup from 'yup';
import {
  SalesRepAddDTO,
  SalesRepPointsTransferDTO
} from '../../containers/api';

const phoneRegExp = /^[0]{1}\d{9}$/g;

export const SalesRepAddSchema = Yup.object().shape({
  username: Yup.string().required('اسم الحساب مطلوب'),
  password: Yup.string().min(4, 'كلمة السر قصيرة').required('كلمة السر مطلوبة'),
  firstName: Yup.string().required('الاسم مطلوب'),
  lastName: Yup.string(),
  cityId: Yup.number(),
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح'),
  middleName: Yup.string(),
  address: Yup.string(),
  town: Yup.string(),
  email: Yup.string().email('يرجى ادخال بريد الكتروني صحيح')
});

export const SalesRepEditSchema = Yup.object().shape({
  firstName: Yup.string().required('الاسم مطلوب'),
  lastName: Yup.string(),
  cityId: Yup.number(),
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح'),
  middleName: Yup.string(),
  address: Yup.string(),
  town: Yup.string(),
  email: Yup.string().email('يرجى ادخال بريد الكتروني صحيح')
});

export const initialValues: SalesRepAddDTO = {
  username: '',
  password: '',
  firstName: '',
  phone: '',
  lastName: undefined,
  cityId: undefined,
  middleName: undefined,
  address: undefined,
  town: undefined,
  email: undefined
};

export const SalesRepTransferSchema = Yup.object().shape({
  salesRepAdminUsername: Yup.string().required('المشرف مطلوب'),
  salesRepUsername: Yup.string().required('مندوب المبيعات مطلوب'),
  points: Yup.number().min(1, 'تجاوزت الحد الادنى').required('الرصيد مطلوب'),
  password: Yup.string().required('الرقم السري مطلوب'),
  type: Yup.string().required('نوع الحوالة مطلوب'),
  notes: Yup.string().nullable()
});

export const salesRepTransferInitialValues: SalesRepPointsTransferDTO = {
  salesRepAdminUsername: '',
  salesRepUsername: '',
  points: 1,
  password: '',
  type: 'Deposit',
  notes: ''
};

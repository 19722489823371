import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import useEntityData from '../../containers/api/useEntityData';
import { SystemUser } from './dto';

const useAdminProfilesData = () => {
  const { entities, fetchEntities, isLoading } = useEntityData<SystemUser>({
    url: API_CONSTANTS.MARKET_ADMINS
  });

  return {
    isLoading,
    profiles: entities,
    fetchAdminProfiles: fetchEntities
  };
};

export default useAdminProfilesData;

import { TextFieldProps } from '@mui/material';
import * as React from 'react';
import TextField from '../TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MUIDatePicker, {
  DatePickerProps as MUIDatePickerProps
} from '@mui/lab/DatePicker';
import moment from 'moment';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDebounce } from '../../hooks/useDebounce';

interface DatePickerProps {
  isUTC?: boolean;
  onDateChange?: (date: Date | null) => void;
  Component?: React.FC<any>;
  datePickerProps?: Partial<MUIDatePickerProps>;
  formik?: ReturnType<typeof useFormik>;
}

export const DatePicker: React.FC<TextFieldProps & DatePickerProps> = ({
  value,
  name,
  isUTC,
  formik,
  datePickerProps = {},
  Component = TextField,
  onDateChange,
  ...props
}) => {
  const debouncedProps = useDebounce(props, 100);
  const [dateValue, setDateValue] = React.useState<Date | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  useEffect(() => {
    const defaultValue = !!value ? moment(value as string).toDate() : null;
    setDateValue(defaultValue);
  }, [value]);

  const renderInput = React.useCallback(
    (params) => {
      return <Component {...params} {...props} name={name} />;
    },
    [JSON.stringify(debouncedProps)]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDatePicker
        {...datePickerProps}
        value={dateValue}
        open={isOpen}
        clearable
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={(newValue: any) => {
          setIsOpen(false);
          let newDate = newValue;
          if (newDate && isUTC) {
            newDate = moment(newDate).utc().toDate();
          }
          setDateValue(newDate);
          onDateChange && onDateChange(newDate);
          formik?.setFieldValue(
            name ?? '',
            newDate
              ? moment(newDate).format(isUTC ? undefined : 'YYYY-MM-DD')
              : newDate
          );
        }}
        renderInput={renderInput}
      />
    </LocalizationProvider>
  );
};

export default React.memo(DatePicker);

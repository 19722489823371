import { Grid } from '@mui/material';
import React from 'react';

// ----------------------------------------------------------------------

export const RegisterationSuccess: React.FC = ({}) => {
  const handleGoToAppStore = () => {
    window.open(
      'https://apps.apple.com/us/app/%D8%A7%D9%83%D8%B3%D8%AA%D8%B1%D8%A7-%D9%83%D8%A7%D8%B4/id1640502844',
      '_blank'
    );
  };

  const handleGoToGoogleStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.excash.app',
      '_blank'
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img
            alt='App Store'
            src='/static/app_store.png'
            onClick={handleGoToAppStore}
          />
          <img
            alt='Google Store'
            src='/static/google_store.png'
            style={{ marginTop: 20 }}
            onClick={handleGoToGoogleStore}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterationSuccess;

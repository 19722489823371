import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { SnackbarConsumer } from '../containers/SnackbarContext';
import AppSnackbar from '../components/Snackbar';

// ----------------------------------------------------------------------

const RootStyle = styled(Page as any)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  zIndex: 10
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '10%',
          background:
            'linear-gradient(157deg, rgba(35,35,35,1) 11%, rgba(246,184,67,1) 100%)',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          color: 'white',
          fontSize: 19,
          fontWeight: 'bold',
          boxShadow: '0px 10px 29px 4px rgb(0 0 0 / 45%)',
          borderBottomRightRadius: '40%',
          borderBottomLeftRadius: '40%'
        }}
      >
        التسجيل
      </div>
      <RootStyle title='اكسترا كاش'>
        <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Typography variant='h3' sx={{ px: 5, mb: 5 }}>
            تسجيل حساب جديد
          </Typography>
          <img
            alt='register'
            src='/static/illustrations/illustration_register.png'
          />
        </SectionStyle>

        <Container>
          <ContentStyle>
            <Box
              component='img'
              src='/static/logo.png'
              style={{
                width: '100%',
                paddingLeft: 24,
                paddingRight: 24,
                marginTop: 50,
                marginBottom: 40
              }}
            />
            <div>
              <Outlet />
            </div>
          </ContentStyle>
        </Container>
        <SnackbarConsumer>
          {({ snackBarState, hideSnackbar }) => (
            <AppSnackbar {...snackBarState} handleClose={hideSnackbar} />
          )}
        </SnackbarConsumer>
      </RootStyle>
    </>
  );
}

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  uploadText: {
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'rgb(238, 238, 238)',
    borderStyle: 'dashed',
    backgroundColor: 'rgb(250, 250, 250)',
    color: 'rgb(189, 189, 189)'
  },
  filesListTitle: {
    marginTop: theme.spacing(1),
    textAlign: 'left'
  },
  filesList: {
    textAlign: 'left'
  }
}));

export default useStyles;

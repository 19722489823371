import React, { createContext, useContext, useState } from 'react';
import { SystemUser, BasicEntity } from './api';

export interface MetaDataState {
  cities: BasicEntity[];
  adminUserProfiles: SystemUser[];
  marketCategories: BasicEntity[];
  roles: string[];
  currentUser: SystemUser | undefined;
}

const initialState: MetaDataState = {
  cities: [],
  adminUserProfiles: [],
  marketCategories: [],
  roles: [],
  currentUser: undefined
};

interface IMetaDataContext {
  metaDataState: MetaDataState;
  setMetaDataState: (state: Partial<MetaDataState>) => void;
}

const MetaDataContext = createContext<IMetaDataContext>({
  metaDataState: initialState,
  setMetaDataState: (state: Partial<MetaDataState>) => {}
});

const { Provider, Consumer } = MetaDataContext;

const MetaDataProvider = ({ children }: { children: any }) => {
  const [metaDataState, setMetaDataState] =
    useState<MetaDataState>(initialState);

  const setMetaData = (state: Partial<MetaDataState>) => {
    setMetaDataState((oldState) => ({ ...oldState, ...state }));
  };

  return (
    <Provider
      value={{
        metaDataState,
        setMetaDataState: setMetaData
      }}
    >
      {children}
    </Provider>
  );
};

const useMetaDate = () => useContext(MetaDataContext);

export {
  useMetaDate,
  MetaDataContext,
  MetaDataProvider,
  Consumer as MetaDataConsumer
};

import { Permissions } from '../types';

const SALES_ADMIN_PERMISSIONS: Permissions = {
  dashboard: {
    monthlyPurchases: {
      read: false
    },
    totalPurchases: {
      read: false
    },
    totalReferrals: {
      read: true
    },
    points: {
      read: true
    },
    userProfilesCount: {
      read: false
    },
    totalCommissions: {
      read: true
    },
    totalBills: {
      read: true
    }
  },
  users: {
    read: false,
    write: false,
    update: false,
    deactivate: false,
    resetPassword: false,
    generateCode: false
  },
  markets: {
    read: true,
    write: false,
    update: false,
    deactivate: false,
    resetPassword: false,
    changePassword: false,
    transferPoints: false,
    settleUp: true
  },
  orders: {
    read: false,
    write: false,
    archive: false
  },
  admins: {
    read: false,
    write: false,
    update: false,
    deactivate: false,
    resetPassword: false
  },
  transactions: {
    read: true,
    archive: true,
    addBill: true
  },
  sales: {
    read: true,
    write: true,
    update: true,
    deactivate: true,
    resetPassword: true,
    transferPoints: true
  },
  qrCodes: {
    generate: false
  },
  prize: {
    read: false
  }
};

export default SALES_ADMIN_PERMISSIONS;

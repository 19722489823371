// material
import {
  Box,
  Grid,
  Container,
  Typography,
  styled,
  alpha,
  Tooltip
} from '@mui/material';
import { useEffect } from 'react';
import Iconify from '../components/Iconify';

// components
import P from '../components/Page';
import useSummaryData from '../containers/api/useSummaryData';
import { useMetaDate } from '../containers/MetaDataContext';
import useUserPermissions from '../hooks/useUserPermissions';
import {
  AppTasks,
  AppAllSales,
  AppTotalReferrals,
  AppUsers,
  AppCommissions,
  AppBalance,
  AppNewsUpdate,
  AppMonthlySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../sections/@dashboard/app';
import AppTotalBills from '../sections/@dashboard/app/AppTotalBills';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: 'center',
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.error.dark,
    0
  )} 0%, ${alpha(theme.palette.error.dark, 0.24)} 100%)`
}));

// ----------------------------------------------------------------------

const Page: any = P;

export default function DashboardApp() {
  const metaData = useMetaDate();
  const { highlights, fetchHighlights } = useSummaryData();

  const permissions = useUserPermissions();

  useEffect(() => {
    fetchHighlights();
  }, []);

  const tooltip = highlights?.pointsDifference
    ? 'يوجد خطأ ما'
    : 'لا يوجد مشاكل';

  return (
    <Page title='اكسترا كاش'>
      <Container maxWidth='xl'>
        <Box
          sx={{
            pb: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant='h4'>
            مرحبا {metaData?.metaDataState?.currentUser?.fullName}
          </Typography>
          <Tooltip enterDelay={0} title={tooltip} placement='right'>
            <IconWrapperStyle
              style={{
                color: !highlights?.pointsDifference ? '#005249' : undefined,
                background: !highlights?.pointsDifference
                  ? '#C8FACD'
                  : undefined
              }}
            >
              <Iconify
                icon={
                  highlights?.pointsDifference
                    ? 'ant-design:bug-filled'
                    : 'bi:check-lg'
                }
                width={24}
                height={24}
                sx={undefined}
              />
            </IconWrapperStyle>
          </Tooltip>
        </Box>
        <Grid container spacing={3}>
          {permissions.dashboard.totalCommissions.read && (
            <Grid item xs={12} sm={6} md={4}>
              <AppCommissions value={highlights?.totalCommissions ?? 0} />
            </Grid>
          )}
          {permissions.dashboard.monthlyPurchases.read && (
            <Grid item xs={12} sm={6} md={4}>
              <AppMonthlySales value={highlights?.monthlyPurchases ?? 0} />
            </Grid>
          )}
          {permissions.dashboard.totalPurchases.read && (
            <Grid item xs={12} sm={6} md={4}>
              <AppAllSales value={highlights?.totalPurchases ?? 0} />
            </Grid>
          )}
          {permissions.dashboard.userProfilesCount.read && (
            <Grid item xs={12} sm={6} md={4}>
              <AppUsers value={highlights?.userProfilesCount ?? 0} />
            </Grid>
          )}
          {permissions.dashboard.points.read && (
            <Grid item xs={12} sm={6} md={4}>
              <AppBalance value={highlights?.points ?? 0} />
            </Grid>
          )}
          {permissions.dashboard.totalReferrals.read && (
            <Grid item xs={12} sm={6} md={4}>
              <AppTotalReferrals value={highlights?.totalReferrals ?? 0} />
            </Grid>
          )}
          {permissions.dashboard.totalBills.read && (
            <Grid item xs={12} sm={6} md={4}>
              <AppTotalBills value={highlights?.totalBills ?? 0} />
            </Grid>
          )}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}

import React, { createContext, useState } from 'react';
import { AppDialogProps } from '../components/AppDialog/types';

export interface AppDialogState extends AppDialogProps {}

const initialState: AppDialogState = {
  open: false,
  actionButtonLabel: 'نعم',
  cancelButtonLabel: 'لا',
  onClose: () => {},
  onSubmit: () => {},
  text: '',
  title: 'تأكيد'
};

interface IAppDialogContext {
  appDialogState: AppDialogState;
  setAppDialogState: React.Dispatch<React.SetStateAction<AppDialogState>>;
  showAppDialog: (state: Partial<AppDialogState>) => void;
  hideAppDialog: () => void;
}

const AppDialogContext = createContext<IAppDialogContext>({
  appDialogState: initialState,
  setAppDialogState: () => {},
  showAppDialog: (state: Partial<AppDialogState>) => {},
  hideAppDialog: () => {}
});

const { Provider, Consumer } = AppDialogContext;

const AppDialogProvider = ({ children }: any) => {
  const [appDialogState, setAppDialogState] =
    useState<AppDialogState>(initialState);

  const hideAppDialog = () => {
    setAppDialogState(initialState);
  };

  const showAppDialog = (state: Partial<AppDialogState>) => {
    setAppDialogState((oldState) => ({ ...oldState, ...state, open: true }));
  };

  return (
    <Provider
      value={{
        appDialogState,
        showAppDialog,
        setAppDialogState,
        hideAppDialog
      }}
    >
      {children}
    </Provider>
  );
};

export { AppDialogContext, AppDialogProvider, Consumer as AppDialogConsumer };

import { Divider, Grid } from '@mui/material';
import { useFormik } from 'formik';
import TextField from '../components/TextField';
import { SystemUser, AdminEditDTO, AxiosContext } from '../containers/api';
import Select from '../components/Select';
import ChangeUserPasswordDialog from '../components/ChangeUserPasswordDialog';
import React, { useContext, useState } from 'react';
import { useMetaDate } from '../containers/MetaDataContext';
import { AuthContext } from '../containers/AuthContext';
import API_CONSTANTS from '../containers/api/API_CONSTANTS';
import { SnackbarContext } from '../containers/SnackbarContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import useStyles from '../components/AppModal/styles';
import { formatDate } from '../utils';
import * as Yup from 'yup';

const phoneRegExp = /^[0]{1}\d{9}$/g;

export const AdminEditSchema = Yup.object().shape({
  firstName: Yup.string().required('الاسم مطلوب'),
  lastName: Yup.string(),
  cityId: Yup.number(),
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح'),
  middleName: Yup.string(),
  address: Yup.string(),
  town: Yup.string(),
  email: Yup.string().email('يرجى ادخال بريد الكتروني صحيح')
});

// ----------------------------------------------------------------------

const mapFormToEditDTO = (values: any): AdminEditDTO => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    cityId: values.cityId,
    phone: values.phone,
    middleName: values.middleName,
    address: values.address,
    town: values.town,
    email: values.email
  };
};

const mapEntityToForm = (values: SystemUser | undefined) => {
  return {
    address: values?.address,
    cityId: values?.city?.id,
    email: values?.email,
    firstName: values?.firstName,
    lastName: values?.lastName,
    middleName: values?.middleName,
    phone: values?.phone,
    town: values?.town,
    username: values?.username
  };
};

export const CurrentUserForm: React.FC<{
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isModalOpened, setIsModalOpened }) => {
  const [isChangePasswordOpened, setIsChangePasswordOpened] = useState(false);
  const { metaDataState } = useMetaDate();
  const { refetchCurrentUser }: any = useContext(AuthContext);
  const user = metaDataState.currentUser;
  const { publicAxios } = useContext(AxiosContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const classes = useStyles();

  const {
    setValues,
    errors,
    touched,
    values,
    getFieldProps,
    resetForm,
    isValid,
    dirty
  } = useFormik<any>({
    initialValues: mapEntityToForm(user),
    validationSchema: AdminEditSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  const onSubmitForm = () => {
    publicAxios
      .put(
        API_CONSTANTS.EDIT_PROFILE(user?.username ?? ''),
        mapFormToEditDTO(values)
      )
      .then(() => {
        refetchCurrentUser(publicAxios).then((currentUser: SystemUser) => {
          showSnackbar({ message: 'تم تعديل بياناتك بنجاح' });
          setValues(mapEntityToForm(currentUser));
        });
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
      });
  };

  const handleClose = () => {
    resetForm();
    setIsModalOpened(false);
  };

  const handleChangePassword = () => {
    setIsChangePasswordOpened(true);
  };

  return (
    <>
      {isModalOpened && (
        <Dialog
          open={isModalOpened}
          onClose={() => {}}
          scroll='paper'
          fullWidth
          disableEscapeKeyDown
          aria-labelledby='app-modal-title'
          aria-describedby='app-modal-description'
        >
          <DialogTitle id='app-modal-title'>الملف الشخصي</DialogTitle>
          <DialogContent dividers>
            <Divider style={{ marginBottom: 10 }} textAlign='left'>
              معلومات الحساب
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='اسم الحساب'
                  {...getFieldProps('username')}
                  variant='filled'
                  disabled
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='password'
                  disabled
                  label='كلمة السر'
                  {...getFieldProps('password')}
                  variant='filled'
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              المعلومات الاساسية
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الاسم'
                  {...getFieldProps('firstName')}
                  variant='filled'
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الأب'
                  {...getFieldProps('middleName')}
                  variant='filled'
                  error={Boolean(touched.middleName && errors.middleName)}
                  helperText={touched.middleName && errors.middleName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='العائلة'
                  {...getFieldProps('lastName')}
                  variant='filled'
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              العنوان
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الشارع'
                  {...getFieldProps('address')}
                  variant='filled'
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='البلدة'
                  {...getFieldProps('town')}
                  variant='filled'
                  error={Boolean(touched.town && errors.town)}
                  helperText={touched.town && errors.town}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  fullWidth
                  type='text'
                  label='المحافظة'
                  {...getFieldProps('cityId')}
                  variant='filled'
                  options={metaDataState.cities}
                  error={Boolean(touched.cityId && errors.cityId)}
                  helperText={touched.cityId && errors.cityId}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='رقم الهاتف'
                  {...getFieldProps('phone')}
                  variant='filled'
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='البريد الالكتروني'
                  {...getFieldProps('email')}
                  variant='filled'
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              اخرى
            </Divider>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  type='text'
                  disabled
                  value={user?.points}
                  label='الرصيد'
                  variant='filled'
                />
              </Grid>
              <Grid item>
                <TextField
                  type='text'
                  disabled
                  value={formatDate(user?.registeredDate ?? '')}
                  label='تاريخ الاضافة'
                  variant='filled'
                />
              </Grid>
            </Grid>
            <ChangeUserPasswordDialog
              userName={user?.username ?? ''}
              open={isChangePasswordOpened}
              setOpen={setIsChangePasswordOpened}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.actionButton}
              color='warning'
              variant='contained'
              onClick={handleChangePassword}
            >
              تغيير كلمة السر
            </Button>
            <Button
              className={classes.actionButton}
              color='error'
              variant='contained'
              onClick={handleClose}
            >
              الغاء
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={
                !!Object.keys(errors ?? {}).length || !isValid || !dirty
              }
              onClick={() => onSubmitForm()}
            >
              حفظ
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CurrentUserForm;

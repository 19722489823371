import { Divider, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { UserEditSchema, UserAddSchema, initialValues } from './schema';
import TextField from '../../components/TextField';
import DatePicker from '../../components/DatePicker';
import {
  User,
  UserEditDTO,
  UserAddDTO,
  AxiosContext
} from '../../containers/api';
import Select from '../../components/Select';
import React, { useContext, useEffect, useState } from 'react';
import { useMetaDate } from '../../containers/MetaDataContext';
import { SnackbarContext } from '../../containers/SnackbarContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import useStyles from '../../components/AppModal/styles';
import { formatDate } from '../../utils';
import Dropdown from '../../components/Dropdown';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ImportDialog from '../../components/ImportDialog';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import useUserPermissions from '../../hooks/useUserPermissions';

// ----------------------------------------------------------------------

const mapFormToEditDTO = (values: any): UserEditDTO => {
  return {
    phone: values.phone,
    firstName: values.firstName,
    lastName: values.lastName,
    cityId: values.cityId,
    middleName: values.middleName,
    address: values.address,
    town: values.town,
    email: values.email,
    dateOfBirth: values.dateOfBirth,
    gender: values.gender
  };
};

const mapFormToAddDTO = (values: any): UserAddDTO => {
  return {
    phone: values.phone,
    firstName: values.firstName,
    lastName: values.lastName,
    cityId: values.cityId,
    middleName: values.middleName,
    address: values.address,
    town: values.town,
    email: values.email,
    dateOfBirth: values.dateOfBirth,
    gender: values.gender,
    qrCode: values.qrCode
  };
};

const mapEntityToForm = (values: User) => {
  return {
    phone: values.phone,
    firstName: values.firstName,
    lastName: values.lastName,
    cityId: values.city?.id,
    middleName: values.middleName,
    address: values.address,
    town: values.town,
    email: values.email,
    dateOfBirth: values.dateOfBirth,
    gender: values.gender
  };
};

export const UserForm: React.FC<{
  user?: User;
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onRefetch: () => void;
  onAddUser: () => void;
}> = ({ user, isModalOpened, setIsModalOpened, onRefetch, onAddUser }) => {
  const [isImportModalOpened, setIsImportModalOpened] = useState(false);

  const { metaDataState } = useMetaDate();
  const { publicAxios } = useContext(AxiosContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const permissions = useUserPermissions();

  const classes = useStyles();

  const formik = useFormik<any>({
    initialValues: user ? mapEntityToForm(user) : initialValues,
    validationSchema: user ? UserEditSchema : UserAddSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  const {
    setValues,
    errors,
    touched,
    values,
    getFieldProps,
    resetForm,
    isValid,
    dirty
  } = formik;

  useEffect(() => {
    resetForm();
    if (user) {
      setValues(user?.id ? mapEntityToForm(user) : initialValues);
    }
  }, [user]);

  const onSubmitForm = () => {
    if (user?.id) {
      publicAxios
        .put(API_CONSTANTS.EDIT_USER + user?.id, mapFormToEditDTO(values))
        .then(() => {
          showSnackbar({ message: 'تم تعديل الزبون بنجاح' });
          resetForm();
          setIsModalOpened(false);
          onRefetch();
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
          showSnackbar({ message, severity: 'error' });
        });
    } else {
      publicAxios
        .post(API_CONSTANTS.ADD_USER, mapFormToAddDTO(values))
        .then(() => {
          showSnackbar({ message: 'تمت اضافة الزبون بنجاح' });
          resetForm();
          setIsModalOpened(false);
          onRefetch();
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
          showSnackbar({ message, severity: 'error' });
        });
    }
  };

  const handleClose = () => {
    resetForm();
    setIsModalOpened(false);
  };

  const addMenuItems = [
    {
      action: onAddUser,
      icon: <AddIcon />,
      label: 'اضافة زبون'
    },
    {
      action: () => setIsImportModalOpened(true),
      icon: <UploadFileIcon />,
      label: 'استيراد زبائن'
    }
  ];

  const uploadDataAPI = (data: Array<any>) =>
    publicAxios.post(API_CONSTANTS.UPLOAD_USERS, {
      excelUserProfileDtos: data
    });

  return (
    <>
      <ImportDialog
        title='استيراد زبائن'
        isOpen={isImportModalOpened}
        templateUrl='/templates/users.xlsx'
        templateFileName='users'
        uploadDataAPI={uploadDataAPI}
        onRefetch={onRefetch}
        onClose={() => setIsImportModalOpened(false)}
      />
      {permissions.users.write && (
        <Dropdown label='اضافة' items={addMenuItems} />
      )}
      {isModalOpened && (
        <Dialog
          open={isModalOpened}
          onClose={() => {}}
          scroll='paper'
          fullWidth
          disableEscapeKeyDown
          aria-labelledby='app-modal-title'
          aria-describedby='app-modal-description'
        >
          <DialogTitle id='app-modal-title'>
            {user ? 'تعديل بيانات الزبون' : 'اضافة زبون'}
          </DialogTitle>
          <DialogContent dividers>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              المعلومات الاساسية
            </Divider>
            <Grid container spacing={2}>
              {!user?.id && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type='text'
                    label='الكود'
                    {...getFieldProps('qrCode')}
                    variant='filled'
                    error={Boolean(touched.qrCode && errors.qrCode)}
                    helperText={touched.qrCode && errors.qrCode}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الاسم'
                  {...getFieldProps('firstName')}
                  variant='filled'
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الأب'
                  {...getFieldProps('middleName')}
                  variant='filled'
                  error={Boolean(touched.middleName && errors.middleName)}
                  helperText={touched.middleName && errors.middleName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='العائلة'
                  {...getFieldProps('lastName')}
                  variant='filled'
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  fullWidth
                  type='text'
                  label='الجنس'
                  {...getFieldProps('gender')}
                  variant='filled'
                  options={[
                    { id: 'Male', name: 'ذكر' },
                    { id: 'Female', name: 'انثى' }
                  ]}
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  fullWidth
                  type='text'
                  formik={formik}
                  label='تاريخ الميلاد'
                  {...getFieldProps('dateOfBirth')}
                  variant='filled'
                  error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                  helperText={touched.dateOfBirth && errors.dateOfBirth}
                />
              </Grid>
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              العنوان
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الشارع'
                  {...getFieldProps('address')}
                  variant='filled'
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='البلدة'
                  {...getFieldProps('town')}
                  variant='filled'
                  error={Boolean(touched.town && errors.town)}
                  helperText={touched.town && errors.town}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  fullWidth
                  type='text'
                  label='المحافظة'
                  {...getFieldProps('cityId')}
                  variant='filled'
                  options={metaDataState.cities}
                  error={Boolean(touched.cityId && errors.cityId)}
                  helperText={touched.cityId && errors.cityId}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='رقم الهاتف'
                  {...getFieldProps('phone')}
                  variant='filled'
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='البريد الالكتروني'
                  {...getFieldProps('email')}
                  variant='filled'
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
            </Grid>
            {user?.id && (
              <>
                <Divider
                  style={{ marginTop: 20, marginBottom: 10 }}
                  textAlign='left'
                >
                  اخرى
                </Divider>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      type='text'
                      disabled
                      value={user?.points}
                      label='الرصيد'
                      variant='filled'
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      type='text'
                      disabled
                      value={user?.couponsPointsDisplay}
                      label='كوبونات'
                      variant='filled'
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      type='text'
                      disabled
                      value={formatDate(user?.registeredDate ?? '')}
                      label='تاريخ الاضافة'
                      variant='filled'
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      type='text'
                      disabled
                      value={user?.createdBy ?? ''}
                      label='اضيف بواسطة'
                      variant='filled'
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.actionButton}
              color='error'
              variant='contained'
              onClick={handleClose}
            >
              الغاء
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={
                !!Object.keys(errors ?? {}).length || !isValid || !dirty
              }
              onClick={() => onSubmitForm()}
            >
              حفظ
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UserForm;

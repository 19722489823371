import * as Yup from 'yup';
import { UserRegisterDTO } from '../../../containers/api';

const phoneRegExp = /^[0]{1}\d{9}$/g;

export const PhoneSchema = Yup.object().shape({
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح')
});

export const OTPSchema = Yup.object().shape({
  otp: Yup.string().length(4).required('رمز التحقق مطلوب')
});

export const UserRegisterSchema = Yup.object().shape({
  firstName: Yup.string().required('الاسم مطلوب'),
  middleName: Yup.string().required('اسم الاب مطلوب'),
  lastName: Yup.string().required('اسم العائلة مطلوب'),
  cityId: Yup.number().min(1, 'المحافظة مطلوبة').required('المحافظة مطلوبة'),
  dateOfBirth: Yup.string().required('تاريخ الميلاد مطلوب'),
  gender: Yup.string().required('الرجاء اختيار الجنس'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'كلمة السر غير مطابقة')
    .required('الرجاء تأكيد كلمة السر'),
  password: Yup.string()
    .min(8, 'كلمة السر قصيرة')
    .required('الرجاء إدخال كلمة السر')
});

export const initialValues: UserRegisterDTO = {
  phone: '',
  firstName: '',
  lastName: '',
  cityId: 0,
  middleName: undefined,
  dateOfBirth: undefined,
  gender: 'Male',
  referrer: '',
  otp: '',
  password: ''
};

import React, { useEffect, useRef, useState } from 'react';
import p from '../../components/Page';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Button, Typography } from '@mui/material';
import WinnerNumberPicker from '../../components/WinnerNumberPicker';
import { useAxios } from '../../containers/api';
import users from './winning_users.json';
import {
  PickerContainer,
  CurrentNumber
} from '../../components/WinnerNumberPicker/styles';

const RootStyle = styled(p as any)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  zIndex: 10
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2, 0)
}));

const PrizeWheel: React.FC = () => {
  const { publicAxios } = useAxios();
  const [started, setIsStarted] = useState(false);
  const [index, setIndex] = useState(0);
  const [winnerUser, setWinner] = useState<
    { name: string; qr: number } | undefined
  >(undefined);
  const winner = useRef({ qr: '0000000000000', name: '' });
  useEffect(() => {
    if (started) {
      const interval = setInterval(() => {
        setIndex(() => Math.floor(Math.random() * users.users.length));
      }, 100);

      setTimeout(() => {
        setWinner(winner.current as any);
        clearInterval(interval);
      }, 10000);
    }
  }, [started]);

  useEffect(() => {
    publicAxios
      .get('/getWinner?startTime=2022-10-13&paidAtLeast=0.5')
      .then((response) => {
        winner.current = response.data;
      });
  }, []);

  const handleStart = () => {
    setIsStarted(true);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '10%',
          background:
            'linear-gradient(157deg, rgba(35,35,35,1) 11%, rgba(246,184,67,1) 100%)',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          color: 'white',
          fontSize: 19,
          fontWeight: 'bold',
          boxShadow: '0px 10px 29px 4px rgb(0 0 0 / 45%)',
          borderBottomRightRadius: '40%',
          borderBottomLeftRadius: '40%'
        }}
      />
      <RootStyle title='اكسترا كاش'>
        <Container>
          <ContentStyle>
            <Box
              component='img'
              src='/static/logo.png'
              style={{
                width: '30%',
                paddingLeft: 24,
                paddingRight: 24,
                marginTop: 50,
                marginBottom: 40
              }}
            />
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              justifyItems={'center'}
            >
              <Grid
                item
                xs={12}
                justifyContent='center'
                alignItems='center'
                justifyItems={'center'}
              >
                <Typography variant='h4' gutterBottom textAlign='center'>
                  السحب على ايفون ١٤ برو ماكس
                </Typography>
                {!started && (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={handleStart}
                      size='large'
                    >
                      بدء السحب
                    </Button>
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent='center'
                alignItems='center'
                justifyItems={'center'}
              >
                <Grid item>
                  <Typography
                    variant='h4'
                    gutterBottom
                    style={{ paddingLeft: 20 }}
                  >
                    رقم البطاقة الرابحة:
                  </Typography>
                </Grid>
                <Grid item xs>
                  <WinnerNumberPicker
                    value={
                      `${winnerUser?.qr ?? ''}` ||
                      (users.users[index] ?? winner)
                    }
                  />
                </Grid>
              </Grid>
              {winnerUser?.qr ? (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent='center'
                  alignItems='center'
                  justifyItems={'center'}
                >
                  <Grid item>
                    <Typography
                      variant='h4'
                      gutterBottom
                      style={{ paddingLeft: 20 }}
                    >
                      اسم الفائز
                    </Typography>
                  </Grid>
                  <Grid item xs justifyContent={'center'} justifyItems='center'>
                    <PickerContainer style={{ width: '50%', margin: 'auto' }}>
                      <CurrentNumber newY={0} style={{ width: '100%' }}>
                        <Grid
                          container
                          style={{ width: '100%', padding: 0 }}
                          direction='row'
                        >
                          <Grid
                            item
                            xs
                            style={{
                              paddingLeft: 5,
                              paddingRight: 5,
                              fontSize: 30,
                              fontWeight: 500,
                              alignItems: 'center',
                              textAlign: 'center'
                            }}
                          >
                            {winnerUser?.name}
                          </Grid>
                        </Grid>
                      </CurrentNumber>
                    </PickerContainer>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
};
export default PrizeWheel;

const urls = {
  ACCOUNT_LOGIN: 'account/stafflogin',
  MARKET_URL: '/market',
  CITIES_URL: '/city',
  ROLES: '/admin/roles',
  USER_BALANCE: '/userProfile/getBalance?qrCode=',
  ADD_ORDER: 'order/AddOrderByAdmin',
  USER_CURRENT: 'account/current',
  SUMMARY_HIGHLIGHTS: 'summary/highlights',
  ACTIVATION_STATUS: (entity: string, id: number) =>
    `${entity}/${id}/toggleActivationStatus`,
  RESET_PASSWORD_BY_ID: (entity: string, id: number) =>
    `${entity}/${id}/resetPassword`,
  CHANGE_PASSWORD: (entity: string, userName: string) =>
    `${entity}/${userName}/changePassword`,
  RESET_PASSWORD_BY_NAME: (entity: string) => `${entity}/resetPassword`,
  ADD_ADMIN: 'admin/AddAdminUser',
  ADD_SALES_REP: 'salesRep/add',
  EDIT_PROFILE: (username: string) => `account/${username}`,
  EDIT_ADMIN: 'admin/',
  EDIT_MARKET: 'market/',
  EDIT_SALES_REP: 'salesRep/',
  ADD_MARKET: 'admin/AddMarket',
  USER_PROFILE: 'userprofile',
  MARKET_ADMINS: 'market/marketAdmins',
  MARKET_CATEGORY: 'market/marketCategory',
  EDIT_USER: 'userprofile/',
  ADD_USER: 'market/adduser',
  SALES_TRANSFER_POINTS: 'salesRep/transferPoints',
  MARKET_TRANSFER_POINTS: 'market/transferPoints',
  UPLOAD_USERS: 'userprofile/uploadUsers',
  UPLOAD_ORDERS: 'order/uploadOrders',
  ADD_BILL: 'transaction/addBill',
  MARKET_SETTLE_UP: 'market/settleup',
  MARKET_TRANSFER_MAX: (salesRepUsername: string, marketUsername: string) =>
    `market/maxTransferValue?salesRepUsername=${salesRepUsername}&marketUsername=${marketUsername}`,
  SALES_REP_TRANSFER_MAX: (
    salesRepAdminUsername: string,
    salesRepUsername: string
  ) =>
    `salesRep/maxTransferValue?salesRepAdminUsername=${salesRepAdminUsername}&salesRepUsername=${salesRepUsername}`,
  CHECK_REGISTERATION: 'userprofile/isValidReferrer?referrer=',
  REGISTER_OTP: 'userprofile/sendRegistrationOtp',
  REGISTER_OTP_VERIFY: 'userprofile/verifyRegistrationOtp',
  REGISTER_USER: 'userprofile/Register',
  GET_WINNER: `gift/GetGiftWinner`,
  ADD_GIFT: 'gift/AddGift'
};

export default urls;

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  endIcon: {
    marginLeft: theme.spacing(1),
    marginRight: 0
  }
}));

export default useStyles;

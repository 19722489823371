import React, { useContext, useMemo, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileForm from '../UploadFileForm/UploadFileForm';
import { readExcel } from '../../utils/files';
import AppBackdrop from '../AppBackdrop';
import { ImportResponse } from '../../containers/api';
import ErrorsTable from './ErrorsTable';
import { Divider, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { SnackbarContext } from '../../containers/SnackbarContext';

export interface ImportDialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const ImportDialogTitle = (props: ImportDialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface ImportDialogProps {
  title: string;
  templateUrl: string;
  templateFileName: string;
  isOpen: boolean;
  uploadDataAPI: (data: Array<any>) => Promise<any>;
  onRefetch: () => void;
  onClose: () => void;
}

export const ImportDialog: React.FC<ImportDialogProps> = ({
  title,
  isOpen,
  templateUrl,
  templateFileName,
  uploadDataAPI,
  onRefetch,
  onClose
}) => {
  const [filesList, setFilesList] = useState<File[]>([]);
  const [importResult, setImportResult] = useState<ImportResponse>();
  const [isUploading, setIsUploading] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);

  const handleDownloadTemplate = () => {
    var link = document.createElement('a');
    link.download = templateFileName + '.xlsx';
    link.href = templateUrl;
    link.click();
    link.remove();
  };

  const handleUploadFile = () => {
    if (filesList?.length) {
      readExcel(filesList[0], handleUploadEntities);
    }
  };

  const handleFileSelected = (filesList: File[]) => {
    setFilesList(filesList);
    setImportResult(undefined);
  };

  const handleUploadEntities = (data: Array<object>) => {
    setIsUploading(true);
    return uploadDataAPI(data)
      .then((response: AxiosResponse<ImportResponse>) => {
        setImportResult(response.data);
        if (response.data?.result?.added || response.data?.result?.modified) {
          onRefetch();
        }
        setIsUploading(false);
      })
      .catch((error: any) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
        setIsUploading(false);
        throw error;
      });
  };

  const errorRows = useMemo(() => {
    return (
      importResult?.errors?.map((error) => {
        const [rowIndex, message] = error.split('|');
        return {
          message,
          rowIndex
        };
      }) ?? []
    );
  }, [importResult]);

  useEffect(() => {
    setImportResult(undefined);
    setFilesList([]);
  }, [isOpen]);

  return (
    <div>
      <Dialog
        onClose={() => onClose()}
        aria-labelledby='dialog-title'
        maxWidth='xs'
        fullWidth
        open={isOpen}
      >
        <AppBackdrop open={isUploading} />
        <ImportDialogTitle id='dialog-title' onClose={() => onClose()}>
          {title}
        </ImportDialogTitle>
        <DialogContent dividers sx={{ textAlign: 'center' }}>
          <UploadFileForm onFilesSelected={handleFileSelected} />
          {importResult && (
            <>
              <Divider style={{ marginTop: 10 }} textAlign='left'>
                نتيجة الاستيراد
              </Divider>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Typography
                  component='span'
                  sx={{ marginRight: 2 }}
                  variant='subtitle2'
                >
                  تم اضافة: {importResult?.result?.added ?? 0}
                </Typography>
                <Typography
                  component='span'
                  sx={{ marginRight: 2 }}
                  variant='subtitle2'
                >
                  تم تعديل: {importResult?.result?.modified ?? 0}
                </Typography>
                {importResult?.result?.errors ? (
                  <Typography
                    component='span'
                    color='red'
                    sx={{ marginRight: 2 }}
                    variant='subtitle2'
                  >
                    عدد الاخطاء: {importResult?.result?.errors}
                  </Typography>
                ) : null}
              </div>
              {errorRows.length ? <ErrorsTable rows={errorRows} /> : null}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => onClose()}>
            اغلاق
          </Button>
          <Button
            variant='contained'
            color='inherit'
            onClick={handleDownloadTemplate}
            sx={{ marginRight: 1 }}
          >
            تنزيل نموذج
          </Button>
          <Button
            variant='contained'
            onClick={handleUploadFile}
            sx={{ marginRight: 1 }}
            disabled={!filesList?.length}
          >
            استيراد
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImportDialog;

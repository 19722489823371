import React, { createContext, useContext } from 'react';
import axios, { AxiosInstance } from 'axios';
import { AuthContext } from '../AuthContext';

const AxiosContext = createContext<{
  authAxios?: AxiosInstance;
  publicAxios: AxiosInstance;
}>({ publicAxios: {} as AxiosInstance });
const { Provider } = AxiosContext;

const AxiosProvider = ({ children }: any) => {
  const authContext = useContext(AuthContext);

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:3000/api/',
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  });

  const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:3000/api/',
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  });

  authAxios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  publicAxios.interceptors.request.use(
    (config) => {
      if (config.headers && !config.headers?.Authorization) {
        config.headers.Authorization = `Bearer ${authContext?.getAccessToken()}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  publicAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        authContext?.logout();
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
        publicAxios
      }}
    >
      {children}
    </Provider>
  );
};

const useAxios = () => useContext(AxiosContext);

export { AxiosContext, AxiosProvider, useAxios };

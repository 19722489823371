import { Divider, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { UserRegisterSchema, initialValues } from './schema';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';
import { UserRegisterDTO, AxiosContext } from '../../../containers/api';
import Select from '../../../components/Select';
import React, { useContext, useEffect, useState } from 'react';
import { SnackbarContext } from '../../../containers/SnackbarContext';
import useStyles from '../../../components/AppModal/styles';
import API_CONSTANTS from '../../../containers/api/API_CONSTANTS';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCityData from '../../../containers/api/useCityData';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const mapFormToAddDTO = (
  values: any,
  referrer: string,
  otp: string,
  phone: string
): UserRegisterDTO => {
  return {
    phone: phone,
    firstName: values.firstName,
    lastName: values.lastName,
    cityId: values.cityId,
    middleName: values.middleName,
    dateOfBirth: values.dateOfBirth,
    gender: values.gender,
    referrer: referrer,
    otp: otp,
    password: values.password
  };
};

export const RegisterForm: React.FC = ({}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { cities, fetchCities } = useCityData();
  const { publicAxios } = useContext(AxiosContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const classes = useStyles();

  const formik = useFormik<any>({
    initialValues: initialValues,
    validationSchema: UserRegisterSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  const { errors, touched, values, getFieldProps, resetForm, isValid, dirty } =
    formik;

  useEffect(() => {
    fetchCities();
    resetForm();
  }, []);

  const onSubmitForm = () => {
    if (
      searchParams.get('referrer') &&
      searchParams.get('phone') &&
      searchParams.get('otp')
    ) {
      setIsLoading(true);
      publicAxios
        .post(
          API_CONSTANTS.REGISTER_USER,
          mapFormToAddDTO(
            values,
            searchParams.get('referrer') ?? '',
            searchParams.get('otp') ?? '',
            searchParams.get('phone') ?? ''
          )
        )
        .then(() => {
          setIsLoading(false);
          showSnackbar({ message: 'تم التسجيل بنجاح' });
          navigate('/download');
          resetForm();
        })
        .catch((error) => {
          setIsLoading(false);
          const message =
            error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
          showSnackbar({ message, severity: 'error' });
        });
    }
  };

  return (
    <>
      <Divider style={{ marginTop: 20, marginBottom: 10 }} textAlign='left'>
        المعلومات الاساسية
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type='text'
            label='الاسم'
            {...getFieldProps('firstName')}
            variant='filled'
            color='secondary'
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type='text'
            label='الأب'
            {...getFieldProps('middleName')}
            variant='filled'
            color='secondary'
            error={Boolean(touched.middleName && errors.middleName)}
            helperText={touched.middleName && errors.middleName}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type='text'
            label='العائلة'
            {...getFieldProps('lastName')}
            variant='filled'
            color='secondary'
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            fullWidth
            type='text'
            label='الجنس'
            {...getFieldProps('gender')}
            variant='filled'
            color='secondary'
            options={[
              { id: 'Male', name: 'ذكر' },
              { id: 'Female', name: 'انثى' }
            ]}
            error={Boolean(touched.gender && errors.gender)}
            helperText={touched.gender && errors.gender}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            fullWidth
            type='text'
            label='المحافظة'
            color='secondary'
            {...getFieldProps('cityId')}
            variant='filled'
            options={cities}
            error={Boolean(touched.cityId && errors.cityId)}
            helperText={touched.cityId && errors.cityId}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            fullWidth
            type='text'
            formik={formik}
            label='تاريخ الميلاد'
            color='secondary'
            {...getFieldProps('dateOfBirth')}
            variant='filled'
            error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
            helperText={touched.dateOfBirth && errors.dateOfBirth}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 20, marginBottom: 10 }} textAlign='left'>
        كلمة السر
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type='password'
            label='كلمة السر'
            {...getFieldProps('password')}
            variant='filled'
            color='secondary'
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type='password'
            label='تأكيد كلمة السر'
            color='secondary'
            {...getFieldProps('confirmPassword')}
            variant='filled'
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />
        </Grid>
      </Grid>
      <LoadingButton
        style={{ width: '100%', margin: 0, marginTop: 30 }}
        className={classes.actionButton}
        loading={isLoading}
        color='secondary'
        variant='contained'
        disabled={
          !!Object.keys(errors ?? {}).length || !isValid || !dirty || isLoading
        }
        onClick={() => onSubmitForm()}
      >
        التسجيل
      </LoadingButton>
    </>
  );
};

export default RegisterForm;

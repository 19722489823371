import useStyles from './styles';
import { AppDialogProps } from './types';
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AppDialog: React.FC<AppDialogProps> = ({
  open,
  onClose,
  onSubmit,
  title,
  actionButtonLabel,
  cancelButtonLabel,
  text
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth='xs'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            className={classes.closeButton}
            onClick={onClose}
          >
            {cancelButtonLabel}
          </Button>
          <Button variant='contained' onClick={onSubmit} autoFocus>
            {actionButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AppDialog;

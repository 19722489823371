// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Container,
  Typography,
  TextField,
  Stack
} from '@mui/material';
// components
import Page from '../components/Page';
import Grid from '@mui/material/Grid';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import moment from 'moment';
var QRCode = require('qrcode.react');
// ----------------------------------------------------------------------

const RootStyle: any = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function QRGenerator() {
  const [guidCount, setGuidCount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const [guidList, setGuidList] = useState<string[]>([]);

  const handleClick = () => {
    let time = new Date().getTime();
    setGuidList(
      Array.from({ length: +guidCount })
        .fill(0)
        .map(() => {
          time++;
          return '' + time;
        })
    );
    setIsLoading(true);

    setTimeout(() => {
      setIsDownloadDisabled(false);
      setIsLoading(false);
    }, 2000);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = +event.target.value;
    if (!isNaN(newValue) && !event.target.value?.includes('.')) {
      setGuidCount(event.target.value);
      setIsDownloadDisabled(true);
    }
  };

  const downloadQRCodes = () => {
    const zip = new JSZip();
    const fileName = moment().format('DD-MM-YYYY - HH:mm');
    zip.folder(fileName + ' codes');
    guidList.forEach((guid) => {
      const canvas: any = document.getElementById(guid);
      if (canvas) {
        const ctx = canvas.getContext('2d');

        ctx.beginPath();
        ctx.rect(0, 29.5, 100, 100);
        ctx.fillStyle = 'lightgray';
        ctx.fill();
        ctx.fillStyle = 'black';

        ctx.textAlign = 'center';
        ctx.font = 'bold 4px serif';
        ctx.direction = 'ltr';
        ctx.fillText(guid, 16.5, 32.5);

        const pngUrl = canvas
          .toDataURL('image/png')
          .replace(/^data:image\/(png|jpg);base64,/, '');

        const img = zip.folder(fileName + ' codes');
        img?.file(guid + '.png', pngUrl, { base64: true });
      }
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, fileName + '.zip');
    });
  };

  return (
    <RootStyle title='اكسترا كاش'>
      <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Typography variant='h3' sx={{ px: 5, mt: 10, mb: 5 }}>
          انشاء اكواد للطباعة
        </Typography>
        <img
          alt='register'
          src='/static/illustrations/illustration_register.png'
        />
      </SectionStyle>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography sx={{ color: 'text.secondary' }}>
              ادخل عدد الاكواد المراد انشائها
            </Typography>
          </Box>

          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                type='text'
                value={guidCount}
                onChange={handleChange}
              />
            </Stack>
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              onClick={handleClick}
              loading={isLoading}
            >
              انشاء
            </LoadingButton>
            {!isDownloadDisabled && guidList?.length && !!guidCount && (
              <LoadingButton
                loading={isLoading}
                variant='contained'
                fullWidth
                size='large'
                onClick={downloadQRCodes}
              >
                تنزيل
              </LoadingButton>
            )}
          </Stack>
          <Grid item xs={12} container spacing={2} padding={2}>
            {guidList.map((guid) => (
              <Grid
                key={guid}
                item
                xs={3}
                container
                style={{ display: 'none' }}
                justifyContent='center'
                alignItems='center'
                direction='column'
              >
                <QRCode
                  value={guid}
                  size={128}
                  id={guid}
                  bgColor={'#ffffff'}
                  fgColor={'#000000'}
                  level={'H'}
                  includeMargin
                />
              </Grid>
            ))}
          </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

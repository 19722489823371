import { MenuItem, TextFieldProps } from '@mui/material';
import TextField from '../TextField';
import useStyles from './styles';

export const Select: React.FC<
  TextFieldProps & {
    options: {
      id: number | string;
      name: string;
    }[];
  }
> = ({ options, ...props }) => {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      SelectProps={{ classes: { icon: classes.icon } }}
      select
      InputProps={{
        ...(props?.InputProps ?? {}),
        classes: { input: classes.input }
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;

import { Divider, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { OTPSchema } from './schema';
import TextField from '../../../components/TextField';
import { AxiosContext } from '../../../containers/api';
import React, { useContext, useEffect, useState } from 'react';
import { SnackbarContext } from '../../../containers/SnackbarContext';
import useStyles from '../../../components/AppModal/styles';
import OTPInput from '../../../components/OTPInput';
import API_CONSTANTS from '../../../containers/api/API_CONSTANTS';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export const OTPVerification: React.FC = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const { publicAxios } = useContext(AxiosContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const [searchParams] = useSearchParams();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: { otp: '' },
    validationSchema: OTPSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  const {
    errors,
    values,
    setFieldValue,
    getFieldProps,
    resetForm,
    isValid,
    dirty
  } = formik;

  useEffect(() => {
    resetForm();
  }, []);

  const onSubmitForm = () => {
    if (searchParams.get('phone') && values.otp) {
      setIsLoading(true);
      publicAxios
        .post(API_CONSTANTS.REGISTER_OTP_VERIFY, {
          otp: values.otp,
          phone: searchParams.get('phone')
        })
        .then((response) => {
          setIsLoading(false);
          resetForm();
          navigation(
            `/registration/register?referrer=${searchParams.get(
              'referrer'
            )}&phone=${response.data.phone}&otp=${response.data.otp}`
          );
        })
        .catch((error) => {
          setIsLoading(false);
          const message =
            error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
          showSnackbar({ message, severity: 'error' });
        });
    }
  };

  const handleChange = (value: string) => {
    setFieldValue('otp', value);
  };

  return (
    <>
      <Divider style={{ marginTop: 20, marginBottom: 10 }} textAlign='left'>
        رمز التحقق
      </Divider>
      <Grid container spacing={2} style={{ maxWidth: '100%' }}>
        <Grid item xs={12} style={{ paddingLeft: 16, paddingRight: 16 }}>
          <OTPInput value={values.otp} onChange={handleChange} />
        </Grid>
      </Grid>
      <LoadingButton
        style={{ width: '100%', margin: 0, marginTop: 30 }}
        className={classes.actionButton}
        color='secondary'
        variant='contained'
        loading={isLoading}
        disabled={
          !!Object.keys(errors ?? {}).length || !isValid || !dirty || isLoading
        }
        onClick={() => onSubmitForm()}
      >
        التحقق
      </LoadingButton>
    </>
  );
};

export default OTPVerification;

import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align='center' variant='subtitle1'>
        غير موجود
      </Typography>
      {searchQuery && (
        <Typography variant='body2' align='center'>
          لا يوجد نتائج لـ &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>. جرب التأكد من النص
        </Typography>
      )}
    </Paper>
  );
}

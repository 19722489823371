import { TextFieldProps } from '@mui/material';
import { TextField as MUITextField } from '@mui/material';
import useStyles from './styles';

export const TextField: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles();
  return (
    <MUITextField
      {...props}
      classes={{ root: classes.root }}
      FormHelperTextProps={{ classes: { root: classes.helperText } }}
      onWheel={(e) => (e.target as any).blur()}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          focused: classes.focusedInputLabel
        }
      }}
    />
  );
};

export default TextField;

import * as Yup from 'yup';
import { UserAddDTO } from '../../containers/api';

const phoneRegExp = /^[0]{1}\d{9}$/g;

export const UserEditSchema = Yup.object().shape({
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح'),
  firstName: Yup.string().required('الاسم مطلوب'),
  middleName: Yup.string(),
  lastName: Yup.string().required('اسم العائلة مطلوب'),
  cityId: Yup.number().required('المحافظة مطلوبة'),
  address: Yup.string(),
  town: Yup.string(),
  dateOfBirth: Yup.string().nullable(),
  gender: Yup.string().required('الرجاء اختيار الجنس'),
  email: Yup.string().email('يرجى ادخال بريد الكتروني صحيح')
});

export const UserAddSchema = Yup.object().shape({
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح'),
  firstName: Yup.string().required('الاسم مطلوب'),
  middleName: Yup.string(),
  lastName: Yup.string().required('اسم العائلة مطلوب'),
  cityId: Yup.number().required('المحافظة مطلوبة'),
  address: Yup.string(),
  town: Yup.string(),
  dateOfBirth: Yup.string().nullable(),
  gender: Yup.string().required('الرجاء اختيار الجنس'),
  email: Yup.string().email('يرجى ادخال بريد الكتروني صحيح'),
  qrCode: Yup.string()
    .required('الكود مطلوب')
    .length(13, 'يرجى ادخال رقم مكون من 13 خانة')
});

export const initialValues: UserAddDTO = {
  phone: '',
  firstName: '',
  lastName: '',
  cityId: 2,
  middleName: undefined,
  address: undefined,
  town: undefined,
  email: undefined,
  dateOfBirth: undefined,
  gender: 'Male',
  qrCode: ''
};

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import QRPreviewer from '../QRPreviewer';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const QRDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface QRModalProps {
  qrCode: string;
  onClose: () => void;
}

export const QRModal: React.FC<QRModalProps> = ({ qrCode, onClose }) => {
  const downloadQR = () => {
    const canvas: any = document.getElementById(qrCode);
    if (canvas) {
      const image = canvas
        .toDataURL('image/png', 1.0)
        .replace('image/png', 'image/octet-stream');
      var link = document.createElement('a');
      link.download = qrCode + '.png';
      link.href = image;
      link.click();
      link.remove();
    }
  };

  const redrawContent = () => {
    if (qrCode) {
      const canvas: any = document.getElementById(qrCode);
      if (canvas) {
        const ctx = canvas.getContext('2d');
        ctx.beginPath();
        ctx.rect(0, 29.5, 100, 100);
        ctx.fillStyle = 'lightgray';
        ctx.fill();
        ctx.fillStyle = 'black';

        ctx.textAlign = 'center';
        ctx.font = 'bold 4px serif';
        ctx.direction = 'ltr';
        ctx.fillText(qrCode, 16.5, 32.5);
      }
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => onClose()}
        aria-labelledby='dialog-title'
        maxWidth='xs'
        fullWidth
        open={Boolean(qrCode)}
      >
        <QRDialogTitle id='dialog-title' onClose={() => onClose()}>
          كود الزبون
        </QRDialogTitle>
        <DialogContent
          ref={(ref: any) => {
            redrawContent();
          }}
          dividers
          sx={{ textAlign: 'center' }}
        >
          <QRPreviewer qrCode={qrCode} size={256} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => onClose()}>
            اغلاق
          </Button>
          <Button
            variant='contained'
            onClick={downloadQR}
            sx={{ marginRight: 1 }}
          >
            تنزيل
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QRModal;

import moment from 'moment';

export const isBlank = (value: any): boolean => {
  return value === undefined || value === null || value === '';
};

export const formatDecimals = (
  value: number | undefined,
  decimals = 2
): string => {
  return (Math.round((value ?? 0) * 100) / 100).toFixed(decimals);
};

export const combineName = (
  firstName?: string,
  middleName?: string,
  lastName?: string
): string => {
  return [firstName, middleName, lastName].filter(Boolean).join(' ');
};

export const formatDate = (date: string, format = 'YYYY-MM-DD'): string => {
  if (!date) {
    return '';
  }
  return moment.utc(date).local().format(format);
};

export const formatDateTime = (
  date: string,
  format = 'HH:mm YYYY-MM-DD'
): string => {
  return formatDate(date, format);
};

export const buildURLQuery = (
  url: string,
  queryParams?: Record<string, number | string>
) => {
  let result = url;
  if (queryParams) {
    const params = Object.keys(queryParams)
      .map((key) =>
        isBlank(queryParams[key]) ? null : `${key}=${queryParams[key]}`
      )
      .filter(Boolean)
      .join('&');
    result = result + '?' + params;
  }

  return result;
};

export const validateBirthday = (
  year?: number,
  month?: number,
  day?: number
) => {
  if (!year || !month || !day) {
    return false;
  }
  const birthdayDate = moment([year, month - 1, day]);

  return birthdayDate.isValid();
};

export const formatNumber = (
  value: number | string,
  decimalPlaces?: number
): string => {
  return (+(value ?? 0)).toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces ?? 0
  });
};

export const parseSystemListValue = (systemListValue: any) => {
  const stringifiedObject = JSON.stringify(systemListValue)
    .replace(/\\/g, '')
    .replace(/"{/g, '{')
    .replace(/}"/g, '}');
  return JSON.parse(stringifiedObject);
};

export const p2e = (s: any) =>
  s?.replace(/[۰-۹]/g, (d: string) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
export const a2e = (s: any) =>
  s?.replace(/[٠-٩]/g, (d: string) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
export const clearNumber = (s: string) =>
  s
    ?.replace(/،/g, '.')
    .replace(/٫/g, '.')
    .replace(/,/g, '.')
    .replace(/[^\d\.]/g, '');

export const NO_OP = () => {};

import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import App from './layouts/App';
import AppLayout from './layouts/AppLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import get from 'lodash/get';
import { SnackbarProvider } from './containers/SnackbarContext';
import useUserPermissions from './hooks/useUserPermissions';
import Admins from './pages/Admins';
import DashboardApp from './pages/DashboardApp';
import DownloadApp from './pages/Download';
import Login from './pages/Login';
import Markets from './pages/Markets';
import Orders from './pages/Orders';
import NotFound from './pages/Page404';
import PrizeWinner from './pages/PrizeWinner';
import QRGenerator from './pages/QRGenerator';
import Register from './pages/Register';
import SalesRepUsers from './pages/SalesRepUsers';
import Transactions from './pages/Transactions';
import Users from './pages/Users';
import Winner from './pages/Winner';
import { RegisterForm } from './sections/authentication/register';
import OTPVerification from './sections/authentication/register/OTPVerification';
import PhoneForm from './sections/authentication/register/PhoneForm';

const RouteWithPermission: React.FC<{
  element?: any;
  permissionPath: string;
}> = ({ element, permissionPath }) => {
  const permissions = useUserPermissions();
  return get(permissions, permissionPath) ? (
    element
  ) : (
    <Navigate to='/pages/home' />
  );
};

export default function Router() {
  return useRoutes([
    {
      // TODO: remove 'pages' route
      path: '/pages',
      element: <App />,
      children: [
        {
          path: 'home',
          element: (
            <AppLayout>
              <DashboardApp />
            </AppLayout>
          )
        },
        {
          path: 'users',
          element: (
            <RouteWithPermission
              element={
                <AppLayout>
                  <Users />
                </AppLayout>
              }
              permissionPath='users.read'
            />
          )
        },
        {
          path: 'generate',
          element: (
            <RouteWithPermission
              element={
                <AppLayout>
                  <QRGenerator />
                </AppLayout>
              }
              permissionPath='qrCodes.generate'
            />
          )
        },
        {
          path: 'markets',
          element: (
            <RouteWithPermission
              element={
                <AppLayout>
                  <Markets />
                </AppLayout>
              }
              permissionPath='markets.read'
            />
          )
        },
        {
          path: 'orders',
          element: (
            <RouteWithPermission
              element={
                <AppLayout>
                  <Orders />
                </AppLayout>
              }
              permissionPath='orders.read'
            />
          )
        },
        {
          path: 'admins',
          element: (
            <RouteWithPermission
              element={
                <AppLayout>
                  <Admins />
                </AppLayout>
              }
              permissionPath='admins.read'
            />
          )
        },
        {
          path: 'sales',
          element: (
            <RouteWithPermission
              element={
                <AppLayout>
                  <SalesRepUsers />
                </AppLayout>
              }
              permissionPath='sales.read'
            />
          )
        },
        {
          path: 'transactions',
          element: (
            <RouteWithPermission
              element={
                <AppLayout>
                  <Transactions />
                </AppLayout>
              }
              permissionPath='transactions.read'
            />
          )
        },
        {
          path: 'extra-cash-winner',
          element: (
            <RouteWithPermission
              element={<PrizeWinner />}
              permissionPath='prize.read'
            />
          )
        },
        {
          path: 'winner',
          element: (
            <RouteWithPermission
              element={<Winner />}
              permissionPath='prize.read'
            />
          )
        }
      ]
    },
    {
      path: '/download',
      element: <DownloadApp />
    },
    {
      path: '/registration',
      element: (
        <SnackbarProvider>
          <Register />
        </SnackbarProvider>
      ),
      children: [
        {
          path: '',
          element: <PhoneForm />
        },
        {
          path: 'otp',
          element: <OTPVerification />
        },
        {
          path: 'register',
          element: <RegisterForm />
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to='/pages/home' /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' /> }
      ]
    },
    { path: '*', element: <Navigate to='/404' replace /> }
  ]);
}

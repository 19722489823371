import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& legend': {}
  },
  helperText: {
    textAlign: 'left'
  },
  inputLabel: {
    left: 24,
    right: 'unset',
    transformOrigin: 'top left'
  },
  focusedInputLabel: {
    // transform: "translate(14px, -9px) scale(0.75)"
  }
}));

export default useStyles;

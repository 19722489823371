import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import {
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Grid,
  Switch,
  FormControlLabel
} from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 1, 2, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  filterCreatedBy: PropTypes.string,
  onFilterCreatedBy: PropTypes.func,
  filterQrCode: PropTypes.string,
  onFilterQrCode: PropTypes.func,
  filterPhone: PropTypes.string,
  onFilterPhone: PropTypes.func,
  onSearch: PropTypes.func
};

export default function UserListToolbar({
  filterName,
  onFilterName,
  filterPhone,
  filterCreatedBy,
  onFilterCreatedBy,
  onFilterPhone,
  filterQrCode,
  onFilterQrCode,
  onSearch
}) {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch();
  };

  return (
    <form onSubmit={handleSubmit}>
      <RootStyle>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchStyle
              value={filterName}
              onChange={onFilterName}
              placeholder='الاسم'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
              style={{ marginLeft: 20 }}
            />
            <SearchStyle
              value={filterPhone}
              onChange={onFilterPhone}
              placeholder='رقم الهاتف'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
              style={{ marginLeft: 20 }}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchStyle
              value={filterQrCode}
              onChange={onFilterQrCode}
              placeholder='الكود'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
              style={{ marginLeft: 20 }}
            />
            <SearchStyle
              value={filterCreatedBy}
              onChange={onFilterCreatedBy}
              placeholder='اضيف بواسطة'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Button variant='contained' type='submit'>
          بحث
        </Button>
      </RootStyle>
    </form>
  );
}

import React from 'react';
import {
  CardImage,
  Container,
  NameText,
  QrCodeText,
  WinningCardText
} from './styles';

interface ExtraCashCardProps {
  name: string;
  qrCode: string;
}

const ExtraCashCard: React.FC<ExtraCashCardProps> = ({ name, qrCode }) => {
  return (
    <Container>
      <NameText>{name}</NameText>
      <WinningCardText>البطاقة الرابحة</WinningCardText>
      <QrCodeText>{qrCode}</QrCodeText>
      <CardImage />
    </Container>
  );
};

export default ExtraCashCard;

import * as Yup from 'yup';
import { AddBillDTO } from '../../containers/api';

export const BillAddSchema = Yup.object().shape({
  points: Yup.number().min(1, 'تجاوزت الحد الادنى').required('القيمة مطلوبة'),
  password: Yup.string().required('الرقم السري مطلوب'),
  notes: Yup.string().required('الملاحظات مطلوبة')
});

export const initialValues: AddBillDTO = {
  points: 1,
  password: '',
  notes: ''
};

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface ErrorsTableProps {
  rows: Array<{ rowIndex: string; message: string }>;
}

const ErrorsTable: React.FC<ErrorsTableProps> = ({ rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell align='right' width={40}>
              السطر
            </TableCell>
            <TableCell align='right'>الخطأ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.rowIndex}>
              <TableCell align='right'>{row.rowIndex}</TableCell>
              <TableCell align='right'>{row.message}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ErrorsTable;

import React from 'react';
import styled from 'styled-components';

export const PickerContainer = styled.div`
  display: flex;
  border: 2px solid #dc9c24;
  padding: 16px;
  border-radius: 30px;
  margin-top: 20px;
  margin-bottom: 24px;
  padding-right: 20px;
  height: 92px;
`;

export const CurrentNumber = styled.div<{ newY: number }>`
  display: flex;
  gap: 20px;
  align-items: center;
  direction: ltr;
  padding: 16px;
  padding-right: 50px;
  transition-duration: 100ms;
  transition-property: transform;
  transform: translate(0px, ${(props: any) => props.newY ?? 0}px);
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-width: 20px 38px 20px 0px;
  border-color: transparent #dc9c24 transparent transparent;
  border-style: solid;
  position: absolute;
  right: 1px;
  top: 26px;
`;

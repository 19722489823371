import { Divider, FormControlLabel, Grid, Switch } from '@mui/material';
import { useFormik } from 'formik';
import { MarketAddSchema, MarketEditSchema, initialValues } from './schema';
import TextField from '../../components/TextField';
import {
  Market,
  MarketAddDTO,
  MarketEditDTO,
  AxiosContext
} from '../../containers/api';
import Select from '../../components/Select';
import React, { useContext, useEffect } from 'react';
import { useMetaDate } from '../../containers/MetaDataContext';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import { SnackbarContext } from '../../containers/SnackbarContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import useStyles from '../../components/AppModal/styles';
import { formatDate } from '../../utils';
import useUserPermissions from '../../hooks/useUserPermissions';

// ----------------------------------------------------------------------

const mapFormToEditDTO = (values: any): MarketEditDTO => {
  return {
    address: values.address,
    town: values.town,
    phone: values.phone,
    percent: values.percent,
    minOrderAmount: values.minOrderAmount,
    cityId: values.cityId,
    email: values.email,
    marketCategoryId: values.marketCategoryId,
    displayName: values.displayName,
    commissionPercent: values.commissionPercent,
    onlineStoreUrl: values.onlineStoreUrl,
    isOnlineStore: values.isOnlineStore
  };
};

const mapFormToAddDTO = (values: any): MarketAddDTO => {
  return {
    adminUserProfileId: values.adminUserProfileId,
    username: values.username,
    password: values.password,
    address: values.address,
    town: values.town,
    phone: values.phone,
    percent: values.percent,
    minOrderAmount: values.minOrderAmount,
    cityId: values.cityId,
    email: values.email,
    marketCategoryId: values.marketCategoryId,
    displayName: values.displayName,
    commissionPercent: values.commissionPercent,
    onlineStoreUrl: values.onlineStoreUrl,
    isOnlineStore: values.isOnlineStore
  };
};

const mapEntityToForm = (values: Market) => {
  return {
    adminUserProfileId: values.adminUserProfile?.id,
    username: values.username,
    address: values.address,
    town: values.town,
    phone: values.phone,
    percent: values.percent,
    minOrderAmount: values.minOrderAmount,
    cityId: values.city?.id,
    email: values.email,
    marketCategoryId: values.marketCategory?.id,
    displayName: values.displayName,
    commissionPercent: values.commissionPercent,
    commissionPoints: values.commissionPoints,
    onlineStoreUrl: values.onlineStoreUrl,
    isOnlineStore: values.isOnlineStore
  };
};

export const MarketForm: React.FC<{
  market?: Market;
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onRefetch: () => void;
  onAddMarket: () => void;
}> = ({ market, isModalOpened, setIsModalOpened, onRefetch, onAddMarket }) => {
  const { metaDataState } = useMetaDate();
  const { publicAxios } = useContext(AxiosContext);
  const permissions = useUserPermissions();
  const { showSnackbar } = useContext(SnackbarContext);
  const classes = useStyles();

  const {
    setValues,
    setFieldValue,
    errors,
    touched,
    values,
    getFieldProps,
    resetForm,
    isValid,
    dirty
  } = useFormik<any>({
    initialValues: market ? mapEntityToForm(market) : initialValues,
    validationSchema: market?.id ? MarketEditSchema : MarketAddSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  useEffect(() => {
    resetForm();
    if (market) {
      setValues(market?.id ? mapEntityToForm(market) : initialValues);
    }
  }, [market]);

  const onSubmitForm = () => {
    if (market?.id) {
      publicAxios
        .put(API_CONSTANTS.EDIT_MARKET + market?.id, mapFormToEditDTO(values))
        .then(() => {
          showSnackbar({ message: 'تم تعديل المتجر بنجاح' });
          resetForm();
          setIsModalOpened(false);
          onRefetch();
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
          showSnackbar({ message, severity: 'error' });
        });
    } else {
      publicAxios
        .post(API_CONSTANTS.ADD_MARKET, mapFormToAddDTO(values))
        .then(() => {
          showSnackbar({ message: 'تمت اضافة المتجر بنجاح' });
          resetForm();
          setIsModalOpened(false);
          onRefetch();
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
          showSnackbar({ message, severity: 'error' });
        });
    }
  };

  const handleClose = () => {
    resetForm();
    setIsModalOpened(false);
  };

  const handleChangeOnlineStore = (value: boolean) => {
    if (!value) {
      setFieldValue('onlineStoreUrl', '', true);
    }
    setFieldValue('isOnlineStore', value, true);
  };

  return (
    <>
      {permissions.markets.write && (
        <Button variant='contained' onClick={onAddMarket}>
          + اضافة متجر
        </Button>
      )}
      {isModalOpened && (
        <Dialog
          open={isModalOpened}
          onClose={() => {}}
          scroll='paper'
          fullWidth
          disableEscapeKeyDown
          aria-labelledby='app-modal-title'
          aria-describedby='app-modal-description'
        >
          <DialogTitle id='app-modal-title'>
            {market?.id ? 'تعديل متجر' : 'اضافة متجر'}
          </DialogTitle>
          <DialogContent dividers>
            <Divider style={{ marginBottom: 10 }} textAlign='left'>
              معلومات الحساب
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='اسم الحساب'
                  {...getFieldProps('username')}
                  variant='filled'
                  disabled={Boolean(market?.id)}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='الاسم للعرض'
                  {...getFieldProps('displayName')}
                  variant='filled'
                  error={Boolean(touched.displayName && errors.displayName)}
                  helperText={touched.displayName && errors.displayName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='password'
                  disabled={Boolean(market?.id)}
                  label='كلمة السر'
                  {...getFieldProps('password')}
                  variant='filled'
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  label='المشرف'
                  {...getFieldProps('adminUserProfileId')}
                  variant='filled'
                  fullWidth
                  disabled={Boolean(market?.id)}
                  error={Boolean(
                    touched.adminUserProfileId && errors.adminUserProfileId
                  )}
                  helperText={
                    touched.adminUserProfileId && errors.adminUserProfileId
                  }
                  options={
                    metaDataState?.adminUserProfiles?.map((profile) => ({
                      id: profile.id,
                      name: profile.fullName ?? ''
                    })) ?? []
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  label='الفئة'
                  {...getFieldProps('marketCategoryId')}
                  variant='filled'
                  fullWidth
                  error={Boolean(
                    touched.marketCategoryId && errors.marketCategoryId
                  )}
                  helperText={
                    touched.marketCategoryId && errors.marketCategoryId
                  }
                  options={metaDataState?.marketCategories ?? []}
                />
              </Grid>
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              العنوان
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الشارع'
                  {...getFieldProps('address')}
                  variant='filled'
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='البلدة'
                  {...getFieldProps('town')}
                  variant='filled'
                  error={Boolean(touched.town && errors.town)}
                  helperText={touched.town && errors.town}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  fullWidth
                  type='text'
                  label='المحافظة'
                  {...getFieldProps('cityId')}
                  variant='filled'
                  options={metaDataState.cities}
                  error={Boolean(touched.cityId && errors.cityId)}
                  helperText={touched.cityId && errors.cityId}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='رقم الهاتف'
                  {...getFieldProps('phone')}
                  variant='filled'
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='البريد الالكتروني'
                  {...getFieldProps('email')}
                  variant='filled'
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              اخرى
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='number'
                  inputProps={{ min: 0, max: 100 }}
                  label='نسبة الخصم'
                  {...getFieldProps('percent')}
                  variant='filled'
                  error={Boolean(touched.percent && errors.percent)}
                  helperText={touched.percent && errors.percent}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='number'
                  inputProps={{ min: 0, max: 100 }}
                  label='نسبة العمولة'
                  {...getFieldProps('commissionPercent')}
                  variant='filled'
                  error={Boolean(
                    touched.commissionPercent && errors.commissionPercent
                  )}
                  helperText={
                    touched.commissionPercent && errors.commissionPercent
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='number'
                  label='الحد الادني للطلب'
                  {...getFieldProps('minOrderAmount')}
                  variant='filled'
                  error={Boolean(
                    touched.minOrderAmount && errors.minOrderAmount
                  )}
                  helperText={touched.minOrderAmount && errors.minOrderAmount}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  style={{ margin: 0, direction: 'ltr' }}
                  control={
                    <Switch
                      {...getFieldProps('isOnlineStore')}
                      checked={values.isOnlineStore}
                      onChange={(e, val) => handleChangeOnlineStore(val)}
                    />
                  }
                  label='متجر الكتروني'
                />
              </Grid>
              {values.isOnlineStore && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type='text'
                    label='رابط المتجر'
                    {...getFieldProps('onlineStoreUrl')}
                    variant='filled'
                    style={{ direction: 'ltr' }}
                    error={Boolean(
                      touched.onlineStoreUrl && errors.onlineStoreUrl
                    )}
                    helperText={touched.onlineStoreUrl && errors.onlineStoreUrl}
                  />
                </Grid>
              )}
              {market?.id && (
                <>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type='text'
                      disabled
                      value={market?.points}
                      label='الرصيد'
                      variant='filled'
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type='number'
                      disabled
                      label='العمولة'
                      variant='filled'
                      value={market?.commissionPoints}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type='text'
                      disabled
                      value={formatDate(market?.registeredDate)}
                      label='تاريخ الاضافة'
                      variant='filled'
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.actionButton}
              color='error'
              variant='contained'
              onClick={handleClose}
            >
              الغاء
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={
                !!Object.keys(errors ?? {}).length || !isValid || !dirty
              }
              onClick={() => onSubmitForm()}
            >
              حفظ
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MarketForm;

import PropTypes from 'prop-types';
// material
import { CssBaseline } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider
} from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';

import { StyleSheetManager } from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';

function RTLStyled(props: any) {
  return (
    <StyleSheetManager stylisPlugins={[rtlPlugin]}>
      {props.children}
    </StyleSheetManager>
  );
}

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()]
});

function RTL(props: any) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
}

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export default function ThemeConfig({ children }: any) {
  const theme = createTheme({
    palette,
    direction: 'rtl',
    shape: { borderRadius: 8 },
    typography,
    shadows,
    customShadows
  } as any);
  theme.components = componentsOverride(theme);

  return (
    <RTL>
      <RTLStyled>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </StyledEngineProvider>
      </RTLStyled>
    </RTL>
  );
}

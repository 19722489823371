import { BasicEntity } from '../../containers/api';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import useEntityData from '../../containers/api/useEntityData';

const useCityData = () => {
  const { entities, fetchEntities, isLoading } = useEntityData<BasicEntity>({
    url: API_CONSTANTS.CITIES_URL
  });

  return {
    isLoading,
    cities: entities,
    fetchCities: fetchEntities
  };
};

export default useCityData;

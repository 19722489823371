import { useEffect, useState, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import p from '../../components/Page';
import Label from '../../components/Label';
import Scroll from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  AdminListHead,
  AdminListToolbar
} from '../../sections/@dashboard/admin';
import useEntityData from '../../containers/api/useEntityData';
import useResetPassword from '../../containers/api/useResetPassword';
import { SystemUser } from '../../containers/api/dto';
import { formatDecimals } from '../../utils';
import AppBackdrop from '../../components/AppBackdrop';
import ItemMoreMenu from '../../components/ItemMoreMenu';
import AdminForm from './AdminForm';
import { AppDialogContext } from '../../containers/AppDialogContext';
import useUserPermissions from '../../hooks/useUserPermissions';

const Scrollbar: any = Scroll;
const Page: any = p;
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'الاسم', alignRight: false },
  { id: 'phone', label: 'رقم الهاتف', alignRight: false },
  { id: 'city', label: 'المحافظة', alignRight: false },
  { id: 'points', label: 'الرصيد', alignRight: false },
  { id: 'commissionPoints', label: 'العمولة', alignRight: false },
  { id: 'role', label: 'الوظيفة', alignRight: false },
  { id: 'status', label: 'الحالة', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function Admins() {
  const [page, setPage] = useState(0);
  const [filterUserName, setFilterUserName] = useState('');
  const [selectedAdmin, setSelectedAdmin] = useState<SystemUser>();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [filterPhone, setFilterPhone] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const appDialogService = useContext(AppDialogContext);
  const resetPasswordService = useResetPassword();
  const permissions = useUserPermissions();

  const { fetchEntities, toggleActivation, entities, totalCount, isLoading } =
    useEntityData<SystemUser>({ url: `admin`, entityKey: 'adminUserProfiles' });

  useEffect(() => {
    handleFetchData();
  }, [page, rowsPerPage]);

  const handleFetchData = (pageNumber?: number) => {
    fetchEntities({
      queryParams: {
        Name: filterUserName,
        Phone: filterPhone,
        Page: (pageNumber ?? page) + 1,
        PageSize: rowsPerPage
      }
    });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByUserName = (event: any) => {
    setFilterUserName(event.target.value);
  };

  const handleFilterByPhone = (event: any) => {
    setFilterPhone(event.target.value);
  };

  const handleToggleDeactivation = (id: number) => {
    appDialogService.showAppDialog({
      onSubmit: () => {
        toggleActivation(id);
        appDialogService.hideAppDialog();
      },
      title: 'تأكيد العملية',
      text: 'هل انت متأكد من تعطيل/تفعيل الحساب؟'
    });
  };

  const handleSearch = () => {
    setPage(0);
    handleFetchData();
  };

  const handleEdit = (admin: SystemUser) => {
    setSelectedAdmin({ ...admin });
    setIsModalOpened(true);
  };

  const handleRefetch = () => {
    handleFetchData();
  };

  const handleAddAdmin = () => {
    setSelectedAdmin(undefined);
    setIsModalOpened(true);
  };

  const proceedResetPassword = (admin: SystemUser) => {
    resetPasswordService.resetPasswordById('admin', admin.id);
    appDialogService.hideAppDialog();
  };

  const resetPassword = (admin: SystemUser) => {
    appDialogService.showAppDialog({
      onSubmit: () => proceedResetPassword(admin),
      title: 'تأكيد العملية',
      text: 'هل انت متأكد من اعادة تعيين كلمة السر؟'
    });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCount) : 0;

  const isEmptyList = entities.length === 0;

  const getMenuOptions = (row: SystemUser) => {
    if (row?.id === 1) return [];

    const options = [];

    if (permissions.admins.update) {
      options.push({
        label: 'تعديل',
        icon: 'eva:edit-fill',
        action: () => handleEdit(row)
      });
    }

    if (permissions.admins.deactivate) {
      options.push({
        label: row.isDeactivated ? 'تفعيل' : 'تعطيل',
        icon: row.isDeactivated ? 'mdi:account-reactivate' : 'bx:block',
        action: () => handleToggleDeactivation(row.id)
      });
    }

    if (permissions.admins.resetPassword) {
      options.push({
        label: 'اعادة تعيين كلمة السر',
        action: () => resetPassword(row),
        icon: 'fluent:key-32-filled'
      });
    }

    return options;
  };

  return (
    <Page title='اكسترا كاش'>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            المشرفين
          </Typography>
          <AdminForm
            admin={selectedAdmin}
            isModalOpened={isModalOpened}
            setIsModalOpened={setIsModalOpened}
            onRefetch={handleRefetch}
            onAddAdmin={handleAddAdmin}
          />
        </Stack>

        <Card>
          <AppBackdrop open={isLoading} />
          <AdminListToolbar
            filterUserName={filterUserName}
            filterPhone={filterPhone}
            onFilterByUserName={handleFilterByUserName}
            onFilterByPhone={handleFilterByPhone}
            onSearch={handleSearch}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <AdminListHead headLabel={TABLE_HEAD} rowCount={totalCount} />
                <TableBody>
                  {entities.map((row: SystemUser) => {
                    const {
                      id,
                      phone,
                      role,
                      points,
                      city,
                      fullName,
                      isDeactivated,
                      commissionPoints
                    } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role='checkbox'>
                        <TableCell component='th' scope='row' align='right'>
                          <Stack
                            direction='row'
                            alignItems='center'
                            spacing={2}
                          >
                            <Typography variant='subtitle2' noWrap>
                              {fullName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align='right'>{phone}</TableCell>
                        <TableCell align='right'>{city?.name}</TableCell>
                        <TableCell align='right'>
                          {formatDecimals(points)}
                        </TableCell>
                        <TableCell align='right'>
                          {formatDecimals(commissionPoints)}
                        </TableCell>
                        <TableCell align='right'>{role}</TableCell>
                        <TableCell align='right'>
                          <Label
                            variant='ghost'
                            color={isDeactivated ? 'error' : 'success'}
                          >
                            {isDeactivated ? 'معطل' : 'فعال'}
                          </Label>
                        </TableCell>
                        <TableCell align='center'>
                          <ItemMoreMenu options={getMenuOptions(row)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isEmptyList && (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterUserName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[8, 16, 32]}
            component='div'
            count={totalCount}
            labelRowsPerPage='العدد لكل صفحة'
            labelDisplayedRows={({ from, to, page, count }) => {
              return `${from} - ${to} (العدد الكلي ${count})`;
            }}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

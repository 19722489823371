import { useContext, useState } from 'react';
import { SnackbarContext } from '../SnackbarContext';
import API_CONSTANTS from './API_CONSTANTS';
import { AxiosContext } from './Axios';

const useResetPassword = () => {
  const { publicAxios } = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useContext(SnackbarContext);

  const resetPasswordByName = (entity: string, username: string) => {
    setIsLoading(true);
    return publicAxios
      .post(API_CONSTANTS.RESET_PASSWORD_BY_NAME(entity), { username })
      .then(() => {
        showSnackbar({ message: 'تم اعادة تعيين كلمة السر بنجاح' });
        setIsLoading(false);
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
        setIsLoading(false);
        throw error;
      });
  };

  const resetPasswordById = (entity: string, id: number, password?: string) => {
    setIsLoading(true);
    return publicAxios
      .post(API_CONSTANTS.RESET_PASSWORD_BY_ID(entity, id), { password })
      .then(() => {
        showSnackbar({ message: 'تم اعادة تعيين كلمة السر بنجاح' });
        setIsLoading(false);
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
        setIsLoading(false);
        throw error;
      });
  };

  const changePassword = (
    entity: string,
    userName: string,
    oldPassword: string,
    newPassword: string
  ) => {
    setIsLoading(true);
    return publicAxios
      .post(API_CONSTANTS.CHANGE_PASSWORD(entity, userName), {
        oldPassword,
        newPassword
      })
      .then(() => {
        showSnackbar({ message: 'تم تغيير كلمة السر بنجاح' });
        setIsLoading(false);
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
        setIsLoading(false);
        throw error;
      });
  };

  return {
    isLoading,
    resetPasswordById,
    resetPasswordByName,
    changePassword,
    publicAxios
  };
};

export default useResetPassword;

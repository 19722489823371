import { Alert, Snackbar, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ReactElement } from 'react';
import useStyles from './styles';
import { AppSnackbarProps } from './types';

function TransitionUp({ children, ...props }: TransitionProps) {
  return (
    <Slide
      children={children as ReactElement<any, any>}
      {...props}
      direction='up'
    />
  );
}

export const AppSnackbar: React.FC<AppSnackbarProps> = ({
  open,
  handleClose,
  message,
  autoHideDuration = 5000,
  severity = 'success'
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%' }}
        classes={{ message: classes.message }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackbar;

import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import useStyles from './styles';

interface UploadFileFormProps {
  onFilesSelected: (files: File[]) => void;
}

const UploadFileForm: React.FC<UploadFileFormProps> = ({ onFilesSelected }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: '.xlsx'
  });
  const classes = useStyles();
  const files = useMemo(
    () =>
      acceptedFiles?.map((file: any) => <li key={file.path}>{file.path}</li>) ??
      [],
    [acceptedFiles]
  );

  useEffect(() => {
    if (acceptedFiles?.length) {
      onFilesSelected(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <section className='container'>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p className={classes.uploadText}>
          اسحب الملف الى هنا او اضغط هنا لاختيار الملف
        </p>
      </div>
      <aside>
        {files?.length ? (
          <h4 className={classes.filesListTitle}>الملف</h4>
        ) : null}
        <ul className={classes.filesList}>{files}</ul>
      </aside>
    </section>
  );
};

export default UploadFileForm;

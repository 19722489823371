import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { Toolbar, OutlinedInput, InputAdornment } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

AdminListToolbar.propTypes = {
  filterUserName: PropTypes.string,
  filterPhone: PropTypes.string,
  onFilterByUserName: PropTypes.func,
  onFilterByPhone: PropTypes.func,
  onSearch: PropTypes.func
};

export default function AdminListToolbar({
  filterUserName,
  filterPhone,
  onFilterByUserName,
  onFilterByPhone,
  onSearch
}) {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch();
  };

  return (
    <form onSubmit={handleSubmit}>
      <RootStyle>
        <div>
          <SearchStyle
            value={filterUserName}
            onChange={onFilterByUserName}
            placeholder='الاسم'
            startAdornment={
              <InputAdornment position='start'>
                <Iconify
                  icon='eva:search-fill'
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            }
            style={{ marginLeft: 20 }}
          />
          <SearchStyle
            value={filterPhone}
            onChange={onFilterByPhone}
            placeholder='رقم الهاتف'
            startAdornment={
              <InputAdornment position='start'>
                <Iconify
                  icon='eva:search-fill'
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            }
          />
        </div>
        <Button variant='contained' type='submit'>
          بحث
        </Button>
      </RootStyle>
    </form>
  );
}

import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
import { Toolbar, OutlinedInput, InputAdornment } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// component
import Iconify from '../../../components/Iconify';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 1, 2, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

OrderListToolbar.propTypes = {
  filterMarketName: PropTypes.string,
  onFilterMarketName: PropTypes.func,
  onlyWithDiscount: PropTypes.bool,
  onOnlyWithDiscount: PropTypes.func,
  onlyCreatedByAdmin: PropTypes.bool,
  onOnlyCreatedByAdmin: PropTypes.func,
  onlyArchivedOrders: PropTypes.bool,
  onOnlyArchivedOrders: PropTypes.func,
  onlyOnlineOrders: PropTypes.bool,
  onOnlyOnlineOrders: PropTypes.func,
  filterAdminName: PropTypes.string,
  onFilterAdminName: PropTypes.func,
  filterOnlineOrderId: PropTypes.string,
  onFilterOnlineOrderId: PropTypes.func,
  filterUserName: PropTypes.string,
  onFilterUserName: PropTypes.func,
  filterUserPhone: PropTypes.string,
  onFilterUserPhone: PropTypes.func,
  onReset: PropTypes.func
};

export default function OrderListToolbar({
  filterMarketName,
  onFilterMarketName,
  onlyWithDiscount,
  onOnlyWithDiscount,
  onlyCreatedByAdmin,
  onOnlyCreatedByAdmin,
  onlyArchivedOrders,
  onOnlyArchivedOrders,
  onlyOnlineOrders,
  onOnlyOnlineOrders,
  filterAdminName,
  onFilterAdminName,
  filterOnlineOrderId,
  onFilterOnlineOrderId,
  filterUserName,
  onFilterUserName,
  filterUserPhone,
  onFilterUserPhone,
  filterStartDate,
  onFilterStartDate,
  filterEndDate,
  onFilterEndDate,
  onSearch,
  onReset
}) {
  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch();
  };

  const handleReset = (event) => {
    event.preventDefault();
    onReset();
  };

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <RootStyle>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SearchStyle
              value={filterUserName}
              onChange={onFilterUserName}
              placeholder='اسم الزبون'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
              style={{ marginLeft: 20 }}
            />
            <SearchStyle
              value={filterUserPhone}
              onChange={onFilterUserPhone}
              placeholder='هاتف الزبون'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
              style={{ marginLeft: 20 }}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchStyle
              value={filterMarketName}
              onChange={onFilterMarketName}
              placeholder='اسم المتجر'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
              style={{ marginLeft: 20 }}
            />
            <SearchStyle
              value={filterAdminName}
              onChange={onFilterAdminName}
              placeholder='مشرف المتجر'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SearchStyle
              value={filterOnlineOrderId}
              onChange={onFilterOnlineOrderId}
              placeholder='رقم الطلب'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
              style={{ marginLeft: 20 }}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              type='text'
              label='من تاريخ'
              value={filterStartDate}
              Component={(props) => <TextField {...props} variant='filled' />}
              onDateChange={onFilterStartDate}
              style={{ marginLeft: 20, width: 240 }}
            />
            <DatePicker
              type='text'
              label='الى تاريخ'
              value={filterEndDate}
              Component={(props) => <TextField {...props} variant='filled' />}
              onDateChange={onFilterEndDate}
              style={{ marginLeft: 20, width: 240 }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Switch
                  value={onlyWithDiscount}
                  onChange={onOnlyWithDiscount}
                />
              }
              label='مع خصم فقط'
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  value={onlyCreatedByAdmin}
                  onChange={onOnlyCreatedByAdmin}
                />
              }
              label='اضيفت بواسطة المدير فقط'
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Switch
                  value={onlyArchivedOrders}
                  onChange={onOnlyArchivedOrders}
                />
              }
              label='المشتريات المؤرشفة فقط'
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  value={onlyOnlineOrders}
                  onChange={onOnlyOnlineOrders}
                />
              }
              label='مشتريات الكترونية فقط'
            />
          </Grid>
        </Grid>
        <Button variant='outlined' type='reset' sx={{ marginLeft: 2 }}>
          استعادة
        </Button>
        <Button variant='contained' type='submit'>
          بحث
        </Button>
      </RootStyle>
    </form>
  );
}

import { Backdrop, CircularProgress } from '@mui/material';

export const AppBackdrop: React.FC<{ open: boolean }> = ({ open }) => {
  return (
    <Backdrop
      component='div'
      sx={{
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.5)',
        color: '#000',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={open}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default AppBackdrop;

import { WinnerConfigs } from '@/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from '../../components/AppModal/styles';
import Autocomplete from '../../components/Autocomplete';
import DatePicker from '../../components/DatePicker';
import Iconify from '../../components/Iconify';
import MultiSelectGroup from '../../components/MultiSelectGroup';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import { Market as MarketDTO, User as UserDTO } from '../../containers/api/dto';
import useEntityData from '../../containers/api/useEntityData';
import useLocalStorage, {
  DEFAULT_SYSTEM_LOCAL_STORAGE
} from '../../hooks/useLocalStorage';
import { AddGiftSchema } from './schema';

export const WinnerConfigModal: React.FC<{
  onConfigsSaved: (values: WinnerConfigs) => void;
  open: boolean;
  setOpen: (val: boolean) => void;
}> = ({ open, setOpen, onConfigsSaved }) => {
  const navigate = useNavigate();
  const { storage } = useLocalStorage();

  const {
    entities: markets,
    fetchEntities: fetchMarkets,
    isLoading: isLoadingMarkets
  } = useEntityData<MarketDTO>({
    url: `market`,
    entityKey: 'markets',
    entityName: 'المتجر'
  });

  const {
    entities: users,
    fetchEntities: fetchUsers,
    isLoading: isLoadingUsers
  } = useEntityData<UserDTO>({
    url: API_CONSTANTS.USER_PROFILE,
    entityKey: 'userProfiles'
  });

  const classes = useStyles();

  const formik = useFormik<WinnerConfigs>({
    initialValues: storage.winnerPageConfigs,
    validationSchema: AddGiftSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isValid,
    dirty,
    setValues,
    setFieldValue,
    setTouched
  } = formik;

  const onSubmitForm = () => {
    onConfigsSaved(values);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFetchMarkets = () => {
    fetchMarkets({
      queryParams: { Page: 1, PageSize: 300 }
    });
  };

  const handleFetchUsers = (searchText: string) => {
    const userSearchType = values.winnerUserConfigs?.winnerSearchType;
    if (userSearchType) {
      fetchUsers({
        queryParams: { [userSearchType]: searchText, Page: 1, PageSize: 10 }
      });
    }
  };

  const handleUserChange = (value: UserDTO) => {
    setTouched({ ...touched, winnerUserConfigs: true }, true);
    setFieldValue('winnerUserConfigs.winnerUser', value, true);
  };

  const handleUserSearchTypeChanged = (e: any) => {
    setFieldValue('winnerUserConfigs.winnerUser', undefined, false);
    setFieldValue('winnerUserConfigs.winnerSearchType', e.target.value, false);
  };

  const handleWinnerTypeChanged = (e: any) => {
    const value = e.target.value;
    if (value === 'MARKET') {
      setValues(
        {
          ...values,
          marketWinnerConfigs:
            DEFAULT_SYSTEM_LOCAL_STORAGE.winnerPageConfigs.marketWinnerConfigs,
          winnerUserConfigs: undefined,
          winnerType: value
        },
        true
      );
    } else {
      setValues(
        {
          ...values,
          marketWinnerConfigs: undefined,
          winnerUserConfigs:
            DEFAULT_SYSTEM_LOCAL_STORAGE.winnerPageConfigs.winnerUserConfigs,
          winnerType: value
        },
        true
      );
    }
  };

  const handleSelectMarkets = (markets: MarketDTO[]) => {
    setFieldValue(
      'marketWinnerConfigs.orderFromMarket',
      markets.map((market) => market.id),
      true
    );
  };

  const handleOrderStartDateChange = (event: Date | null) => {
    setFieldValue('marketWinnerConfigs.orderStartDate', event, true);
  };

  const handleOrderEndDateChange = (event: Date | null) => {
    setFieldValue('marketWinnerConfigs.orderEndDate', event, true);
  };

  const handleGiftValidUntilChange = (event: Date | null) => {
    setFieldValue('giftValidUntil', event, true);
  };

  const handleNavigate = () => {
    navigate('/pages/home');
  };

  useEffect(() => {
    const winnerType = values.winnerType;
    if (winnerType === 'MARKET') {
      handleFetchMarkets();
    } else {
      handleFetchUsers('');
    }
  }, [values.winnerType]);

  const defaultSelectedUser = useMemo(
    () => storage.winnerPageConfigs.winnerUserConfigs?.winnerUser,
    []
  );

  const defaultSelectedMarkets = useMemo(
    () => storage.winnerPageConfigs.marketWinnerConfigs?.orderFromMarket ?? [],
    []
  );

  useEffect(() => {
    if (values.winnerType === 'MARKET') {
      setFieldValue('winnerUserConfigs', undefined, false);
    } else {
      setFieldValue('marketWinnerConfigs', undefined, false);
    }
  }, [values.winnerType]);

  return open ? (
    <Dialog
      open={open}
      onClose={() => {}}
      scroll='paper'
      fullWidth
      disableEscapeKeyDown
      aria-labelledby='app-modal-title'
      aria-describedby='app-modal-description'
    >
      <DialogTitle id='app-modal-title'>اعدادات السحب</DialogTitle>
      <DialogContent dividers sx={{ position: 'relative' }}>
        <Divider style={{ marginTop: 20, marginBottom: 10 }} textAlign='left'>
          اختيار الرابحين
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Select
              label='البحث حسب'
              variant='filled'
              fullWidth
              {...getFieldProps('winnerType')}
              onChange={handleWinnerTypeChanged}
              error={Boolean(touched.winnerType && errors.winnerType)}
              helperText={touched.winnerType && errors.winnerType}
              options={[
                { id: 'USER', name: 'زبون' },
                { id: 'MARKET', name: 'متجر' }
              ]}
            />
          </Grid>
        </Grid>
        {values.winnerType === 'USER' ? (
          <>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              اختيار الزبون
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Select
                  label='البحث حسب'
                  variant='filled'
                  fullWidth
                  {...getFieldProps('winnerUserConfigs.winnerSearchType')}
                  onChange={handleUserSearchTypeChanged}
                  options={[
                    { id: 'Name', name: 'الاسم' },
                    { id: 'QrCode', name: 'الكود' },
                    { id: 'Phone', name: 'رقم الهاتف' }
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  inputLabel='الزبون'
                  optionLabelAccessor='fullName'
                  options={users}
                  isLoading={isLoadingUsers}
                  {...getFieldProps('winnerUserConfigs.winnerUser')}
                  defaultSelections={defaultSelectedUser}
                  error={Boolean(
                    touched.winnerUserConfigs &&
                      (errors.winnerUserConfigs as any)?.winnerUser
                  )}
                  helperText={
                    touched.winnerUserConfigs
                      ? (errors.winnerUserConfigs as any)?.winnerUser
                      : ''
                  }
                  fetchOptions={handleFetchUsers}
                  onSelectionChange={handleUserChange}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              اختيار المتاجر
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MultiSelectGroup
                  defaultSelectedKeys={defaultSelectedMarkets}
                  allItems={markets}
                  labelAccessor='displayName'
                  itemKeyAccessor='id'
                  groupingKeyAccessor={(market) => market?.adminUserProfile?.id}
                  groupingLabelAccessor={(market) =>
                    market.adminUserProfile.fullName!
                  }
                  onSelect={handleSelectMarkets}
                />
              </Grid>
            </Grid>
          </>
        )}
        {values.winnerType === 'MARKET' ? (
          <>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              المشتريات
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePicker
                  type='text'
                  label='من تاريخ'
                  Component={(props) => (
                    <TextField {...props} variant='filled' />
                  )}
                  onDateChange={handleOrderStartDateChange}
                  {...getFieldProps('marketWinnerConfigs.orderStartDate')}
                  error={Boolean(
                    touched?.marketWinnerConfigs &&
                      (errors.marketWinnerConfigs as any)?.orderStartDate
                  )}
                  helperText={
                    touched?.marketWinnerConfigs &&
                    (errors.marketWinnerConfigs as any)?.orderStartDate
                  }
                  style={{ width: '100%' }}
                  datePickerProps={{ maxDate: new Date() }}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  type='text'
                  label='الى تاريخ'
                  Component={(props) => (
                    <TextField {...props} variant='filled' />
                  )}
                  onDateChange={handleOrderEndDateChange}
                  {...getFieldProps('marketWinnerConfigs.orderEndDate')}
                  error={Boolean(
                    touched?.marketWinnerConfigs &&
                      (errors.marketWinnerConfigs as any)?.orderEndDate
                  )}
                  helperText={
                    touched?.marketWinnerConfigs &&
                    (errors.marketWinnerConfigs as any)?.orderEndDate
                  }
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='قيمة المشتريات'
                  type='number'
                  fullWidth
                  {...getFieldProps('marketWinnerConfigs.paidAtLeast')}
                  variant='filled'
                  error={Boolean(
                    touched.marketWinnerConfigs &&
                      (errors.marketWinnerConfigs as any)?.paidAtLeast
                  )}
                  helperText={
                    touched.marketWinnerConfigs &&
                    (errors.marketWinnerConfigs as any)?.paidAtLeast
                  }
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        <Divider style={{ marginTop: 20, marginBottom: 10 }} textAlign='left'>
          الهدية
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label='قيمة الهدية'
              type='number'
              fullWidth
              {...getFieldProps('giftAmount')}
              variant='filled'
              error={Boolean(touched.giftAmount && errors.giftAmount)}
              helperText={touched.giftAmount && errors.giftAmount}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              type='text'
              label='صالح لغاية'
              Component={(props) => <TextField {...props} variant='filled' />}
              onDateChange={handleGiftValidUntilChange}
              {...getFieldProps('giftValidUntil')}
              error={Boolean(touched.giftValidUntil && errors.giftValidUntil)}
              helperText={touched.giftValidUntil && errors.giftValidUntil}
              style={{ width: '100%' }}
              datePickerProps={{ minDate: new Date() }}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              {...getFieldProps('giftSms')}
              multiline
              minRows={5}
              placeholder='محتوى رسالة الهدية'
              fullWidth
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify icon='bx:message' sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20, marginBottom: 10 }} textAlign='left'>
          محتويات الصفحة
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='عنوان الصفحة'
              {...getFieldProps('title')}
              variant='filled'
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='العنوان الثانوي'
              fullWidth
              {...getFieldProps('subTitle')}
              variant='filled'
              error={Boolean(touched.subTitle && errors.subTitle)}
              helperText={touched.subTitle && errors.subTitle}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='رابط الصورة'
              fullWidth
              {...getFieldProps('imgSrc')}
              variant='filled'
              error={Boolean(touched.imgSrc && errors.imgSrc)}
              helperText={touched.imgSrc && errors.imgSrc}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.actionButton}
          style={{ marginLeft: 'auto' }}
          color='primary'
          variant='text'
          onClick={handleNavigate}
        >
          الصفحة الرئيسية
        </Button>
        <Button
          className={classes.actionButton}
          color='error'
          variant='contained'
          onClick={handleClose}
        >
          الغاء
        </Button>
        <Button
          className={classes.actionButton}
          color='primary'
          variant='contained'
          disabled={
            !!Object.keys(errors ?? {}).length ||
            !isValid ||
            !dirty ||
            !values.giftAmount
          }
          onClick={() => onSubmitForm()}
        >
          حفظ
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default WinnerConfigModal;

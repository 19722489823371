import styled from 'styled-components';

export const CardImage = styled.img.attrs({
  src: '/static/card.jpg'
})`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  width: 500px;
  height: 320px;
  border-radius: 16px;
  overflow: hidden;
  position: absolute;
  box-shadow: -4px 7px 27px 5px rgba(0, 0, 0, 0.73);
  -webkit-box-shadow: -4px 7px 27px 5px rgba(0, 0, 0, 0.73);
  -moz-box-shadow: -4px 7px 27px 5px rgba(0, 0, 0, 0.73);
`;

export const NameText = styled.span`
  z-index: 1;
  position: absolute;
  left: 15px;
  top: 20px;
  width: 70%;
  text-align: left;
  font-size: 33px;
  color: #f7ac08;
`;

export const QrCodeText = styled.span`
  z-index: 1;
  color: #f7ac08;
  position: absolute;
  bottom: 9px;
  width: 100%;
  text-align: center;
  -webkit-letter-spacing: 8px;
  -moz-letter-spacing: 8px;
  -ms-letter-spacing: 8px;
  letter-spacing: 8px;
  font-size: 40px;
`;

export const WinningCardText = styled.span`
  z-index: 1;
  color: white;
  position: absolute;
  bottom: 67px;
  width: 100%;
  text-align: center;
  font-size: 30px;
`;

import { Divider, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { changePasswordInitialValues, ChangePasswordSchema } from './schema';
import TextField from '../../components/TextField';
import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import useStyles from '../../components/AppModal/styles';

export const ChangePasswordForm: React.FC<{
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (value: string) => void;
}> = ({ isModalOpened, setIsModalOpened, onSubmit }) => {
  const classes = useStyles();

  const { errors, touched, values, getFieldProps, resetForm, isValid, dirty } =
    useFormik<any>({
      initialValues: changePasswordInitialValues,
      validationSchema: ChangePasswordSchema,
      onSubmit: () => {
        onSubmitForm();
      }
    });

  useEffect(() => {
    resetForm();
  }, [isModalOpened]);

  const onSubmitForm = () => {
    onSubmit(values.password);
  };

  const handleClose = () => {
    resetForm();
    setIsModalOpened(false);
  };

  return (
    <>
      {isModalOpened && (
        <Dialog
          open={isModalOpened}
          onClose={() => {}}
          scroll='paper'
          fullWidth
          disableEscapeKeyDown
          aria-labelledby='app-modal-title'
          aria-describedby='app-modal-description'
        >
          <DialogTitle id='app-modal-title'>تغيير كلمة السر</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='password'
                  label='كلمة السر الجديدة'
                  {...getFieldProps('password')}
                  variant='filled'
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='password'
                  disabled={!values?.password}
                  label='تأكيد كلمة السر'
                  {...getFieldProps('confirmPassword')}
                  variant='filled'
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.actionButton}
              color='error'
              variant='contained'
              onClick={handleClose}
            >
              الغاء
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={
                !!Object.keys(errors ?? {}).length || !isValid || !dirty
              }
              onClick={() => onSubmitForm()}
            >
              حفظ
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ChangePasswordForm;

import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { CurrentNumber, PickerContainer, Triangle } from './styles';

const WinnerNumberPicker = ({ value }: { value: string }) => {
  const [numberPosition, setNumberPosition] = useState(0);
  const containerRef = React.useRef(null);
  return (
    <div style={{ position: 'relative' }} ref={containerRef}>
      <PickerContainer>
        {numberPosition <= 184 && (
          <CurrentNumber newY={numberPosition} style={{ width: '100%' }}>
            <Grid container style={{ width: '100%' }} direction='row'>
              {value.split('').map((num, index) => (
                <Grid
                  item
                  xs
                  key={num + index}
                  style={{
                    paddingLeft: 5,
                    paddingRight: 5,
                    fontSize: 30,
                    fontWeight: 500
                  }}
                >
                  {num}
                </Grid>
              ))}
            </Grid>
          </CurrentNumber>
        )}
        <Triangle />
      </PickerContainer>
    </div>
  );
};
export default WinnerNumberPicker;

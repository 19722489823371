import React from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';

const OTPInput = ({ value, onChange, ...props }: any) => {
  const [otp, setOtp] = React.useState(value);

  const handleChange = (newValue: string) => {
    if (!isNaN(+newValue)) {
      setOtp(newValue);
      onChange?.(newValue);
    }
  };

  return (
    <div style={{ direction: 'ltr' }}>
      <MuiOtpInput
        {...props}
        value={otp}
        onChange={handleChange}
        inputMode='numeric'
        TextFieldsProps={{ color: 'secondary' }}
      />
    </div>
  );
};
export default OTPInput;

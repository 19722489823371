import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { MarketTransferSchema, marketTransferInitialValues } from './schema';
import TextField from '../../components/TextField';
import {
  Market,
  MarketPointsTransferDTO,
  useAxios
} from '../../containers/api';
import Select from '../../components/Select';
import React, { useContext, useEffect, useState } from 'react';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import { SnackbarContext } from '../../containers/SnackbarContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import useStyles from '../../components/AppModal/styles';
import { useMetaDate } from '../../containers/MetaDataContext';
import { useAuth } from '../../containers/AuthContext';

export const MarketPointsTransferModal: React.FC<{
  market?: Market;
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}> = ({ market, isOpen, onSubmit, onClose }) => {
  const { publicAxios } = useAxios();
  const { showSnackbar } = useContext(SnackbarContext);
  const { metaDataState } = useMetaDate();
  const auth = useAuth();
  const [maxDepositAmount, setMaxDepositAmount] = useState(0);
  const classes = useStyles();

  const {
    errors,
    touched,
    values,
    getFieldProps,
    resetForm,
    isValid,
    setFieldValue
  } = useFormik<any>({
    initialValues: marketTransferInitialValues,
    validationSchema: MarketTransferSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  useEffect(() => {
    resetForm();
    if (isOpen) {
      setFieldValue('salesRepUsername', metaDataState.currentUser?.username);
      setFieldValue('marketUsername', market?.username);
      publicAxios
        .get(
          API_CONSTANTS.MARKET_TRANSFER_MAX(
            metaDataState.currentUser?.username ?? '',
            market?.username ?? ''
          )
        )
        .then((response) => {
          setMaxDepositAmount(response?.data?.depositAmount);
        });
    }
  }, [isOpen]);

  const validateForm = (values: MarketPointsTransferDTO) => {
    if (values?.type === 'Deposit') {
      if ((metaDataState.currentUser?.points ?? 0) === 0) {
        showSnackbar({ message: 'لا يوجد لديك رصيد', severity: 'error' });
        return false;
      }
      if ((maxDepositAmount ?? 0) < values?.points) {
        showSnackbar({
          message: 'الحد الاقصى المسموح به هو: ' + maxDepositAmount,
          severity: 'error'
        });
        return false;
      }
    }
    return true;
  };

  const onSubmitForm = () => {
    const validForm = validateForm(values);
    if (!validForm) {
      return;
    }
    publicAxios
      .post(API_CONSTANTS.MARKET_TRANSFER_POINTS, values)
      .then(() => {
        showSnackbar({ message: 'تم تسوية الحساب بنجاح' });
        resetForm();
        onSubmit();
        // refresh points
        auth?.refetchCurrentUser(publicAxios);
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
      });
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const getPointsLimits = () => {
    const max = values?.type === 'Withdrawal' ? market?.points : null;
    return { min: 1, max };
  };

  return (
    <>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={() => {}}
          scroll='paper'
          fullWidth
          disableEscapeKeyDown
          aria-labelledby='app-modal-title'
          aria-describedby='app-modal-description'
        >
          <DialogTitle id='app-modal-title'>تسوية حساب</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  label='نوع الحركة'
                  {...getFieldProps('type')}
                  variant='filled'
                  fullWidth
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  options={[
                    { id: 'Deposit', name: 'دفع' },
                    { id: 'Withdrawal', name: 'استلام' }
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='number'
                  label='القيمة'
                  inputProps={getPointsLimits()}
                  {...getFieldProps('points')}
                  variant='filled'
                  error={Boolean(touched.points && errors.points)}
                  helperText={touched.points && errors.points}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='password'
                  label='كلمة السر'
                  {...getFieldProps('password')}
                  variant='filled'
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
                <TextField
                  fullWidth
                  type='hidden'
                  {...getFieldProps('marketUsername')}
                  style={{ display: 'none' }}
                />
                <TextField
                  fullWidth
                  type='hidden'
                  {...getFieldProps('salesRepUsername')}
                  style={{ display: 'none' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  type='text'
                  label='ملاحظات'
                  {...getFieldProps('notes')}
                  variant='filled'
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {values?.type === 'Deposit' && (
              <Typography
                variant='subtitle2'
                style={{ flex: 1 }}
                paddingRight={2}
                color='orange'
              >
                الحد الاقصى للدفع: {maxDepositAmount}
              </Typography>
            )}
            <Button
              className={classes.actionButton}
              color='error'
              variant='contained'
              onClick={handleClose}
            >
              الغاء
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={!isValid}
              onClick={() => onSubmitForm()}
            >
              {values?.type === 'Withdrawal' ? 'استلام' : 'دفع'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MarketPointsTransferModal;

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing()
  }
}));

export default useStyles;

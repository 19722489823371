import React from 'react';
var QRCode = require('qrcode.react');

const QRPreviewer: React.FC<{ qrCode: string; size?: number }> = ({
  qrCode,
  size = 128
}) => {
  return (
    <QRCode
      value={qrCode}
      size={size}
      id={qrCode}
      bgColor={'#ffffff'}
      fgColor={'#000000'}
      level={'H'}
      includeMargin
    />
  );
};

export default QRPreviewer;

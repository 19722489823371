import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import useEntityData from '../../containers/api/useEntityData';
import { BasicEntity } from './dto';

const useMarketCategoriesData = () => {
  const { entities, fetchEntities, isLoading } = useEntityData<BasicEntity>({
    url: API_CONSTANTS.MARKET_CATEGORY
  });

  return {
    isLoading,
    profiles: entities,
    fetchMarketCategories: fetchEntities
  };
};

export default useMarketCategoriesData;

// material
import { styled } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
import { Toolbar, OutlinedInput, InputAdornment } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// component
import Iconify from '../../../components/Iconify';
import TextField from '../../../components/TextField';
import DatePicker from '../../../components/DatePicker';
import Select from '../../../components/Select';
import { useEffect, useMemo, useState } from 'react';
import { useMetaDate } from '../../../containers/MetaDataContext';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 1, 2, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: (theme as any).customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${(theme as any).palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

const TransactionsListToolbar: React.FC<any> = ({
  filterCategory,
  onFilterCategory,
  filterType,
  onFilterType,
  filterMarketName,
  onFilterMarketName,
  isArchived,
  onIsArchived,
  filterSalesRepName,
  onFilterSalesRepName,
  filterSalesRepAdminName,
  onFilterSalesRepAdminName,
  filterStartDate,
  onFilterStartDate,
  filterEndDate,
  onFilterEndDate,
  onSearch,
  onReset
}) => {
  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSearch();
  };

  const handleResetForm = (event: any) => {
    event.preventDefault();
    onReset();
  };

  const { metaDataState } = useMetaDate();
  const [isFilterTypeShown, setIsFilterTypeShown] = useState(true);

  const categoryFilterOptions = useMemo(() => {
    const options = [
      {
        id: 'SalesRep_Market',
        name: 'حركات مندوبي المبيعات - المتاجر'
      },
      {
        id: 'SalesRepAdmin_SalesRep',
        name: 'حركات المشرفين - مندوبي المبيعات'
      }
    ];
    if (
      metaDataState?.currentUser?.role === 'Admin' ||
      metaDataState?.currentUser?.role === 'SalesRepAdmin'
    ) {
      options.push(
        ...[
          {
            id: 'SalesRepAdmin_Market',
            name: 'إعفاء عمولات'
          },
          {
            id: 'Bill',
            name: 'سندات الصرف'
          }
        ]
      );
    }

    return options;
  }, [metaDataState?.currentUser?.role]);

  const typeFilterOptions = [
    {
      id: 'Withdrawal',
      name: 'استلام'
    },
    {
      id: 'Deposit',
      name: 'دفع'
    }
  ];

  useEffect(() => {
    if (
      filterCategory === 'Bill' ||
      filterCategory === 'SalesRepAdmin_Market'
    ) {
      onFilterType(''); // empty or null
      setIsFilterTypeShown(false);
    } else {
      setIsFilterTypeShown(true);
    }
  }, [filterCategory]);

  const shouldShowSalesAdminNameFilter = useMemo(() => {
    const result =
      filterCategory === 'SalesRepAdmin_SalesRep' ||
      filterCategory === 'SalesRepAdmin_Market' ||
      filterCategory === 'Bill';
    if (!result) {
      onFilterSalesRepAdminName('');
    }
    return result;
  }, [filterCategory]);

  const shouldShowSalesRepNameFilter = useMemo(() => {
    const result =
      filterCategory === 'SalesRep_Market' ||
      filterCategory === 'SalesRepAdmin_SalesRep';
    if (!result) {
      onFilterSalesRepName('');
    }
    return result;
  }, [filterCategory]);

  const shouldShowMarketNameFilter = useMemo(() => {
    const result =
      filterCategory === 'SalesRep_Market' ||
      filterCategory === 'SalesRepAdmin_Market';
    if (!result) {
      onFilterMarketName('');
    }
    return result;
  }, [filterCategory]);

  return (
    <form onSubmit={handleSubmit} onReset={handleResetForm}>
      <RootStyle>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              type='text'
              label='الفئة'
              variant='filled'
              value={filterCategory}
              onChange={onFilterCategory}
              style={{ width: 240, marginLeft: 20 }}
              options={categoryFilterOptions}
            />
            {isFilterTypeShown && (
              <Select
                type='text'
                label='نوع الحركة'
                variant='filled'
                value={filterType}
                onChange={onFilterType}
                style={{ width: 240 }}
                options={typeFilterOptions}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {shouldShowSalesAdminNameFilter && (
              <SearchStyle
                value={filterSalesRepAdminName}
                onChange={onFilterSalesRepAdminName}
                placeholder='اسم المشرف'
                startAdornment={
                  <InputAdornment position='start'>
                    <Iconify
                      icon='eva:search-fill'
                      sx={{ color: 'text.disabled' }}
                    />
                  </InputAdornment>
                }
                style={{ marginLeft: 20 }}
              />
            )}
            {shouldShowSalesRepNameFilter && (
              <SearchStyle
                value={filterSalesRepName}
                onChange={onFilterSalesRepName}
                placeholder='اسم مندوب المبيعات'
                style={{ marginLeft: 20 }}
                startAdornment={
                  <InputAdornment position='start'>
                    <Iconify
                      icon='eva:search-fill'
                      sx={{ color: 'text.disabled' }}
                    />
                  </InputAdornment>
                }
              />
            )}
            {shouldShowMarketNameFilter && (
              <SearchStyle
                value={filterMarketName}
                onChange={onFilterMarketName}
                placeholder='اسم المتجر'
                startAdornment={
                  <InputAdornment position='start'>
                    <Iconify
                      icon='eva:search-fill'
                      sx={{ color: 'text.disabled' }}
                    />
                  </InputAdornment>
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              type='text'
              label='من تاريخ'
              value={filterStartDate}
              Component={(props) => <TextField {...props} variant='filled' />}
              onDateChange={onFilterStartDate}
              style={{ marginLeft: 20, width: 240 }}
            />
            <DatePicker
              type='text'
              label='الى تاريخ'
              value={filterEndDate}
              Component={(props) => <TextField {...props} variant='filled' />}
              onDateChange={onFilterEndDate}
              style={{ marginLeft: 20, width: 240 }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Switch value={isArchived} onChange={onIsArchived} />}
              label='الحركات المؤرشفة فقط'
            />
          </Grid>
        </Grid>
        <Button variant='outlined' type='reset' sx={{ marginLeft: 2 }}>
          استعادة
        </Button>
        <Button variant='contained' type='submit'>
          بحث
        </Button>
      </RootStyle>
    </form>
  );
};

export default TransactionsListToolbar;

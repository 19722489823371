import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  width: PropTypes.number
};

export default function Logo({ sx, width = 150 }) {
  return (
    <RouterLink to='/' style={{ margin: 'auto' }}>
      <Box
        component='img'
        src='/static/logo.png'
        sx={{ width, height: 150, ...sx }}
      />
    </RouterLink>
  );
}

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '8px !important'
  },
  icon: {
    right: 8,
    left: 'unset !important'
  },
  input: {
    paddingRight: '60px !important'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}));

export default useStyles;

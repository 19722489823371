import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel
} from '@mui/material';

// ----------------------------------------------------------------------

OrderListHead.propTypes = {
  rowCount: PropTypes.number,
  headLabel: PropTypes.array
};

export default function OrderListHead({ rowCount, headLabel }: any) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            style={{ textAlign: 'right' }}
          >
            <TableSortLabel hideSortIcon active={false}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

import {
  Cancel,
  CheckBox,
  CheckBoxOutlineBlank,
  ExpandLess,
  IndeterminateCheckBox,
  ExpandMore
} from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import TextField from '../TextField';
import useStyles from './styles';
import React from 'react';

type Accessor<T> = string | ((item: T) => string | number);

const getItem = <T extends Record<string, any>>(
  accessor: Accessor<T>,
  item: T
) => {
  if (typeof accessor === 'function') {
    return accessor(item);
  }
  return item[accessor];
};

interface MultiSelectGroupProps<T> {
  allItems: T[];
  itemKeyAccessor: Accessor<T>;
  groupingKeyAccessor: Accessor<T>;
  labelAccessor: Accessor<T>;
  groupingLabelAccessor: Accessor<T>;
  defaultSelectedKeys: Array<string | number>;
  onSelect: (items: T[]) => void;
}

const getDefaultSelectedItems = <T extends Record<string, any>>(
  defaultSelectedKeys: Array<string | number>,
  allItems: Array<T>,
  itemKeyAccessor: Accessor<T>
) => {
  return allItems.filter((item) =>
    defaultSelectedKeys.includes(getItem(itemKeyAccessor, item))
  );
};

const MultiSelectGroup = <T extends Record<string, any>>({
  allItems,
  itemKeyAccessor,
  groupingKeyAccessor,
  labelAccessor,
  groupingLabelAccessor,
  defaultSelectedKeys,
  onSelect
}: MultiSelectGroupProps<T>) => {
  const allItemsSorted = useMemo(
    () =>
      allItems?.sort((a, b) => a.adminUserProfile.id - b.adminUserProfile.id) ??
      [],
    [allItems]
  );

  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState<T[]>(
    getDefaultSelectedItems(
      defaultSelectedKeys,
      allItemsSorted,
      itemKeyAccessor
    ) ?? []
  );
  const [expandedGroup, setExpandedGroup] = useState();

  const checkOption = (option: T) => {
    const check = selectedItems.some(
      (g) => getItem(itemKeyAccessor, g) === getItem(itemKeyAccessor, option)
    );
    return check;
  };

  const getGroupLabel = (groupKey: string | number) => {
    const group = allItemsSorted.find(
      (item) => getItem(groupingKeyAccessor, item) === groupKey
    );
    return getItem(groupingLabelAccessor, group!);
  };

  const checkGroup = (group: string) => {
    const groupLength = allItemsSorted.filter(
      (g) => getItem(groupingKeyAccessor, g) === group
    ).length;
    const selectedGroupLength = selectedItems.filter(
      (g) => getItem(groupingKeyAccessor, g) === group
    ).length;
    return groupLength === selectedGroupLength;
  };

  const indeterminateGroup = (group: string) => {
    const selectedGroupLength = selectedItems.filter(
      (g) => getItem(groupingKeyAccessor, g) === group
    ).length;
    return !!selectedGroupLength && !checkGroup(group);
  };

  const selectGroup = (_: any, group: string) => {
    const groupedItems = allItemsSorted.filter(
      (g) => getItem(groupingKeyAccessor, g) === group
    );
    const selectedGroupItems = selectedItems.filter(
      (g) => getItem(groupingKeyAccessor, g) === group
    );

    if (selectedGroupItems.length > 0) {
      const newState = [
        ...selectedItems.filter(
          (g) => getItem(groupingKeyAccessor, g) !== group
        )
      ];
      setSelectedItems(newState);
      onSelect(newState);
    } else {
      const newState = [...selectedItems, ...groupedItems];
      setSelectedItems(newState);
      onSelect(newState);
    }
  };

  const handleToggleExpandGroup = (_: any, group: any) => {
    if (group === expandedGroup) {
      setExpandedGroup(undefined);
    } else {
      setExpandedGroup(group);
    }
  };

  const unselectOption = (option: T) => {
    const newState = selectedItems.filter(
      (g) => getItem(itemKeyAccessor, g) !== getItem(itemKeyAccessor, option)
    );

    setSelectedItems(newState);
    onSelect(newState);
  };

  const handleOptionClicked = (selection: any) => {
    const isOptionSelected = selectedItems.some(
      (option) =>
        getItem(itemKeyAccessor, option) === getItem(itemKeyAccessor, selection)
    );
    const newState = isOptionSelected
      ? selectedItems.filter(
          (g) =>
            getItem(itemKeyAccessor, g) !== getItem(itemKeyAccessor, selection)
        )
      : [...selectedItems, selection];

    setSelectedItems(newState);
    onSelect(newState);
  };

  const handleSelectionChange = (_: any, options: T[]) => {
    const newState = [...options];
    setSelectedItems(newState);
    onSelect(newState);
  };

  useEffect(() => {
    setSelectedItems(
      getDefaultSelectedItems(
        defaultSelectedKeys,
        allItemsSorted,
        itemKeyAccessor
      ) ?? []
    );
  }, [defaultSelectedKeys, allItemsSorted, itemKeyAccessor]);

  return (
    <Autocomplete
      id='items-group-select'
      options={allItemsSorted}
      classes={{
        option: classes.option,
        inputRoot: classes.input,
        endAdornment: classes.icon
      }}
      onChange={handleSelectionChange}
      value={selectedItems}
      inputValue={''}
      autoHighlight
      multiple
      disableCloseOnSelect
      fullWidth
      getOptionLabel={(option) => getItem(labelAccessor, option)}
      groupBy={(option) => getItem(groupingKeyAccessor, option)}
      renderOption={(_: any, option) => (
        <ListItemButton sx={{ pr: 4 }} key={option?.id}>
          <ListItemIcon style={{ margin: 0 }}>
            <Checkbox
              key={getItem(itemKeyAccessor, option)}
              icon={<CheckBoxOutlineBlank fontSize='small' />}
              checkedIcon={<CheckBox fontSize='small' />}
              checked={checkOption(option)}
              onClick={() => handleOptionClicked(option)}
            />
          </ListItemIcon>
          <ListItemText
            primary={<>{getItem(labelAccessor, option)}</>}
            style={{ textAlign: 'right' }}
          />
        </ListItemButton>
      )}
      renderGroup={(params) => (
        <React.Fragment key={params?.group}>
          <ListItemButton
            onClick={(event) => handleToggleExpandGroup(event, params.group)}
          >
            <ListItemIcon style={{ margin: 0 }}>
              <Checkbox
                key={params.group}
                icon={<CheckBoxOutlineBlank fontSize='small' />}
                checkedIcon={<CheckBox fontSize='small' />}
                indeterminateIcon={<IndeterminateCheckBox fontSize='small' />}
                checked={checkGroup(params.group)}
                indeterminate={indeterminateGroup(params.group)}
                onChange={(e) => selectGroup(e, params.group)}
              />
            </ListItemIcon>
            <ListItemText
              primary={getGroupLabel(params.group)}
              style={{ textAlign: 'right' }}
            />
            {expandedGroup === params.group ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={expandedGroup === params.group}
            timeout='auto'
            unmountOnExit
          >
            <List component='div' disablePadding>
              {params.children}
            </List>
          </Collapse>
        </React.Fragment>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            placeholder={!selectedItems.length ? 'اختر المتجر' : ''}
            variant='outlined'
            InputProps={{
              classes: {
                root: classes.root
              },
              ...params.InputProps
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password' // disable autocomplete and autofill
            }}
          />
        );
      }}
      renderTags={() => {
        return selectedItems.map((tag) => (
          <Button
            size='small'
            variant='outlined'
            style={{ direction: 'ltr', marginLeft: 8 }}
            key={getItem(itemKeyAccessor, tag)}
            endIcon={<Cancel />}
            onClick={() => unselectOption(tag)}
          >
            {getItem(labelAccessor, tag)}
          </Button>
        ));
      }}
    />
  );
};

export default MultiSelectGroup;

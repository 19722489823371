import * as Yup from 'yup';
import { AddOrderDTO } from '../../containers/api';

export const OrderAddSchema = Yup.object().shape({
  marketId: Yup.number().required('الرجاء اختيار المتجر'),
  userId: Yup.number().required('الرجاء اختيار زبون'),
  orderTotal: Yup.number()
    .min(1, 'الرجاء اضافة قيمة اكبر من 0')
    .required('قيمة المشتريات مطلوبة'),
  discountAmount: Yup.number()
    .min(0, 'قيمة الخصم غير صحيحة')
    .max(Yup.ref('orderTotal'), 'قيمة الخصم تجاوزت قيمة المشتريات')
});

export const initialValues: AddOrderDTO = {
  marketId: 0,
  userId: 0,
  orderTotal: 0,
  discountAmount: 0
};

import React, { createContext, useState } from 'react';
import { AppSnackbarProps } from '../components/Snackbar/types';

export interface SnackbarState extends AppSnackbarProps {}

const initialState: SnackbarState = {
  open: false,
  message: '',
  handleClose: () => {}
};

interface ISnackbarContext {
  snackBarState: SnackbarState;
  setSnackbarState: React.Dispatch<React.SetStateAction<SnackbarState>>;
  showSnackbar: (state: Partial<SnackbarState>) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = createContext<ISnackbarContext>({
  snackBarState: initialState,
  setSnackbarState: () => {},
  showSnackbar: (state: Partial<SnackbarState>) => {},
  hideSnackbar: () => {}
});

const { Provider, Consumer } = SnackbarContext;

const SnackbarProvider = ({ children }: any) => {
  const [snackBarState, setSnackbarState] =
    useState<SnackbarState>(initialState);

  const hideSnackbar = () => {
    setSnackbarState(initialState);
  };

  const showSnackbar = (state: Partial<SnackbarState>) => {
    setSnackbarState((oldState) => ({ ...oldState, ...state, open: true }));
  };

  return (
    <Provider
      value={{
        snackBarState,
        showSnackbar,
        setSnackbarState,
        hideSnackbar
      }}
    >
      {children}
    </Provider>
  );
};

export { SnackbarContext, SnackbarProvider, Consumer as SnackbarConsumer };

import React, { useEffect, useState, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import p from '../../components/Page';
import Label from '../../components/Label';
import MarketForm from './MarketForm';
import Scroll from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  MarketListHead,
  MarketListToolbar
} from '../../sections/@dashboard/market';
import useResetPassword from '../../containers/api/useResetPassword';
import useEntityData from '../../containers/api/useEntityData';
import { Market as MarketDTO } from '../../containers/api/dto';
import AppBackdrop from '../../components/AppBackdrop';
import ItemMoreMenu from '../../components/ItemMoreMenu';
import { AppDialogContext } from '../../containers/AppDialogContext';
import { formatDecimals } from '../../utils';
import MarketPointsTransferModal from './MarketPointsTransferModal';
import useAdminProfilesData from '../../containers/api/useAdminProfilesData';
import { useMetaDate } from '../../containers/MetaDataContext';
import useUserPermissions from '../../hooks/useUserPermissions';
import MarketPointsSettleUpModal from './MarketPointsSettleUpModal';
import ChangePasswordForm from './ChangePasswordForm';

const Scrollbar: any = Scroll;
const Page: any = p;
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'الاسم', alignRight: false },
  { id: 'admin', label: 'المشرف', alignRight: false },
  { id: 'city', label: 'المحافظة', alignRight: false },
  { id: 'minOrderAmount', label: 'الحد الادنى للطلب', alignRight: false },
  { id: 'points', label: 'الرصيد', alignRight: false },
  { id: 'commissionPercent', label: 'العمولة', alignRight: false },
  { id: 'numberOfCards', label: 'عدد البطاقات', alignRight: false },
  { id: 'status', label: 'الحالة', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function Markets() {
  const [page, setPage] = useState(0);
  // TODO: merge 2 market objects into 1 generic
  const [marketForTransferPoints, setMarketForTransferPoints] =
    useState<MarketDTO>();
  const [marketForSettleUp, setMarketForSettleUp] = useState<MarketDTO>();
  const [filterName, setFilterName] = useState('');
  const [filterAdminName, setFilterAdminName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isChangePasswordDialogOpened, setIsChangePasswordDialogOpened] =
    useState(false);
  const appDialogService = useContext(AppDialogContext);
  const [selectedMarket, setSelectedMarket] = useState<MarketDTO>();
  const resetPasswordService = useResetPassword();
  const { fetchAdminProfiles } = useAdminProfilesData();
  const metaData = useMetaDate();
  const permissions = useUserPermissions();

  const { fetchEntities, entities, totalCount, isLoading, toggleActivation } =
    useEntityData<MarketDTO>({
      url: `market`,
      entityKey: 'markets',
      entityName: 'المتجر'
    });

  useEffect(() => {
    handleFetchData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (permissions.admins.read) {
      fetchAdminProfiles().then((adminUserProfiles) => {
        metaData.setMetaDataState({ adminUserProfiles });
      });
    }
  }, []);

  const handleFetchData = () => {
    fetchEntities({
      queryParams: {
        displayName: filterName,
        MarketAdminName: filterAdminName,
        Page: page + 1,
        PageSize: rowsPerPage
      }
    });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: any) => {
    setFilterName(event.target.value);
  };

  const handleFilterByAdminName = (event: any) => {
    setFilterAdminName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCount) : 0;

  const isEmptyList = entities.length === 0;

  const handleRefetch = () => {
    handleFetchData();
  };
  const handleEdit = (market: MarketDTO) => {
    setSelectedMarket({ ...market });
    setIsModalOpened(true);
  };

  const proceedChangePassword = (password: string) => {
    resetPasswordService.resetPasswordById(
      'market',
      selectedMarket?.id ?? 0,
      password
    );
    setSelectedMarket(undefined);
    setIsChangePasswordDialogOpened(false);
  };

  const handleToggleDeactivation = (id: number) => {
    appDialogService.showAppDialog({
      onSubmit: () => {
        toggleActivation(id);
        appDialogService.hideAppDialog();
      },
      title: 'تأكيد العملية',
      text: 'هل انت متأكد من تعطيل/تفعيل الحساب؟'
    });
  };

  const handleTransferPoints = (market: MarketDTO) => {
    setMarketForTransferPoints(market);
  };

  const handleSettleUp = (market: MarketDTO) => {
    setMarketForSettleUp(market);
  };

  const changePassword = (market: MarketDTO) => {
    setIsChangePasswordDialogOpened(true);
    setSelectedMarket({ ...market });
  };

  const handleSearch = () => {
    setPage(0);
    handleFetchData();
  };
  const handleAddMarket = () => {
    setSelectedMarket(undefined);
    setIsModalOpened(true);
  };

  const getMenuOptions = (row: MarketDTO) => {
    const options = [];

    if (permissions.markets.update) {
      options.push({
        label: 'تعديل',
        icon: 'eva:edit-fill',
        action: () => handleEdit(row)
      });
    }

    if (permissions.markets.deactivate) {
      options.push({
        label: row.isDeactivated ? 'تفعيل' : 'تعطيل',
        icon: row.isDeactivated ? 'mdi:account-reactivate' : 'bx:block',
        action: () => handleToggleDeactivation(row.id)
      });
    }

    if (permissions.markets.resetPassword) {
      options.push({
        label: 'اعادة تعيين كلمة السر',
        action: () => resetPassword(row),
        icon: 'fluent:key-32-filled'
      });
    }

    if (permissions.markets.changePassword) {
      options.push({
        label: 'تغيير كلمة السر',
        action: () => changePassword(row),
        icon: 'material-symbols:key-outline-rounded'
      });
    }

    if (permissions.markets.transferPoints) {
      options.push({
        label: 'تسوية حساب',
        action: () => handleTransferPoints(row),
        icon: 'bx:transfer-alt'
      });
    }

    if (permissions.markets.settleUp) {
      options.push({
        label: 'اعفاء',
        action: () => handleSettleUp(row),
        icon: 'akar-icons:double-check'
      });
    }

    return options;
  };

  const handleSubmitPointsTransfer = () => {
    handleRefetch();
    setMarketForTransferPoints(undefined);
  };

  const handleSubmitSettleUp = () => {
    handleRefetch();
    setMarketForSettleUp(undefined);
  };

  const proceedResetPassword = (market: MarketDTO) => {
    resetPasswordService.resetPasswordById('market', market.id);
    appDialogService.hideAppDialog();
  };

  const resetPassword = (market: MarketDTO) => {
    appDialogService.showAppDialog({
      onSubmit: () => proceedResetPassword(market),
      title: 'تأكيد العملية',
      text: 'هل انت متأكد من اعادة تعيين كلمة السر؟'
    });
  };

  const canShowMarketForm =
    permissions.markets.write || permissions.markets.update;

  return (
    <Page title='اكسترا كاش'>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            المتاجر
          </Typography>
          {canShowMarketForm && (
            <MarketForm
              market={selectedMarket}
              isModalOpened={isModalOpened}
              setIsModalOpened={setIsModalOpened}
              onRefetch={handleRefetch}
              onAddMarket={handleAddMarket}
            />
          )}
          {isChangePasswordDialogOpened && (
            <ChangePasswordForm
              isModalOpened={isChangePasswordDialogOpened}
              setIsModalOpened={setIsChangePasswordDialogOpened}
              onSubmit={proceedChangePassword}
            />
          )}
        </Stack>

        <Card>
          <AppBackdrop open={isLoading} />
          <MarketListToolbar
            filterName={filterName}
            filterAdminName={filterAdminName}
            onFilterName={handleFilterByName}
            onFilterAdminName={handleFilterByAdminName}
            onSearch={handleSearch}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MarketListHead headLabel={TABLE_HEAD} rowCount={totalCount} />
                <TableBody>
                  {entities.map((row: MarketDTO) => {
                    const {
                      id,
                      points,
                      city,
                      displayName,
                      isDeactivated,
                      minOrderAmount,
                      commissionPoints,
                      adminUserProfile,
                      numberOfCards
                    } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role='checkbox'>
                        <TableCell component='th' scope='row' align='right'>
                          <Stack
                            direction='row'
                            alignItems='center'
                            spacing={2}
                          >
                            <Typography variant='subtitle2' noWrap>
                              {displayName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align='right'>
                          {adminUserProfile?.fullName}
                        </TableCell>
                        <TableCell align='right'>{city?.name}</TableCell>
                        <TableCell align='right'>
                          {formatDecimals(minOrderAmount)}
                        </TableCell>
                        <TableCell align='right'>
                          {formatDecimals(points)}
                        </TableCell>
                        <TableCell align='right'>
                          {formatDecimals(commissionPoints)}
                        </TableCell>
                        <TableCell align='right'>
                          {formatDecimals(numberOfCards, 0)}
                        </TableCell>
                        <TableCell align='right'>
                          <Label
                            variant='ghost'
                            color={isDeactivated ? 'error' : 'success'}
                          >
                            {isDeactivated ? 'معطل' : 'فعال'}
                          </Label>
                        </TableCell>

                        <TableCell align='right'>
                          <ItemMoreMenu options={getMenuOptions(row)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isEmptyList && (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[8, 16, 32]}
            component='div'
            count={totalCount}
            labelRowsPerPage='العدد لكل صفحة'
            labelDisplayedRows={({ from, to, page, count }) => {
              return `${from} - ${to} (العدد الكلي ${count})`;
            }}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <MarketPointsTransferModal
        isOpen={!!marketForTransferPoints}
        market={marketForTransferPoints}
        onSubmit={handleSubmitPointsTransfer}
        onClose={() => setMarketForTransferPoints(undefined)}
      />
      <MarketPointsSettleUpModal
        isOpen={!!marketForSettleUp}
        market={marketForSettleUp}
        onSubmit={handleSubmitSettleUp}
        onClose={() => setMarketForSettleUp(undefined)}
      />
    </Page>
  );
}

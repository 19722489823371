// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { AxiosProvider } from './containers/api/Axios';

// THEME: https://minimal-kit-react.vercel.app/dashboard/products
// GITHUB https://github.com/minimal-ui-kit/material-kit-react
function App() {
  return (
    <AxiosProvider>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </ThemeConfig>
    </AxiosProvider>
  );
}

export default App;

import { useContext, useState } from 'react';
import { SnackbarContext } from '../SnackbarContext';
import API_CONSTANTS from './API_CONSTANTS';
import { AxiosContext } from './Axios';
import { BaseEntity } from './dto';

export interface dataHookParams {
  url: string;
  entityName?: string;
}

export interface EntityCrudActionOptions {
  message: string;
}

const useEntityCrud = <Entity extends BaseEntity>({
  url,
  entityName
}: dataHookParams) => {
  const { publicAxios } = useContext(AxiosContext);
  const [entity, setEntity] = useState<Entity>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useContext(SnackbarContext);

  const toggleActivation = (id: number) => {
    return publicAxios
      .post(API_CONSTANTS.ACTIVATION_STATUS(url, id))
      .then(() => {
        return id;
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
      });
  };

  const fetchEntity = (id?: number): Promise<Entity | undefined> => {
    setIsLoading(true);
    return publicAxios
      .get<Entity>(`${url}` + (id ? `/${id}` : ''))
      .then((response) => {
        setIsLoading(false);
        setEntity(response.data);
        return response.data;
      })
      .catch((error) => {
        setIsLoading(false);
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
        return undefined;
      });
  };

  const addEntity = (entity: Entity): Promise<Entity | undefined> => {
    const response = publicAxios.post<Entity>(url, entity);
    setIsLoading(true);
    return response
      .then((result) => {
        setIsLoading(false);
        const data = result.data;
        const message = entityName
          ? `تمت اضافة ${entityName} بنجاح`
          : 'تمت الاضافة بنجاح';
        showSnackbar({ message });
        return data;
      })
      .catch((error) => {
        setIsLoading(false);
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
        return undefined;
      });
  };

  const updateEntity = (entity: Entity): Promise<Entity | undefined> => {
    const response = publicAxios.put<Entity>(url, entity);
    setIsLoading(true);
    return response
      .then((result) => {
        setIsLoading(false);
        const data = result.data;
        const message = entityName
          ? `تم تعديل ${entityName} بنجاح`
          : 'تم التعديل بنجاح';
        showSnackbar({ message });
        return data;
      })
      .catch((error) => {
        setIsLoading(false);
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
        return undefined;
      });
  };

  const deleteEntity = (id: number, options?: EntityCrudActionOptions) => {
    setIsLoading(true);
    return publicAxios
      .delete(`${url}/${id}`)
      .then((result) => {
        setIsLoading(false);
        const message = !options?.message
          ? entityName
            ? `تم حذف ${entityName} بنجاح`
            : 'تم الحذف بنجاح'
          : options?.message;
        showSnackbar({ message });
      })
      .catch((error) => {
        setIsLoading(false);
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
      });
  };

  return {
    isLoading,
    toggleActivation,
    entity,
    fetchEntity,
    addEntity,
    updateEntity,
    deleteEntity,
    publicAxios,
    setEntity
  };
};

export default useEntityCrud;

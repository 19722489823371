import { AxiosInstance } from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { SystemUser } from './api';
import API_CONSTANTS from './api/API_CONSTANTS';
import { useMetaDate } from './MetaDataContext';

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  authenticated: boolean;
}

export interface Credentials {
  username: string;
  password: string;
}

interface IAuthContext {
  authState: AuthState;
  getAccessToken: () => AuthState['accessToken'];
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  checkAuthentication: (axios: AxiosInstance) => Promise<boolean>;
  refetchCurrentUser: (axios: AxiosInstance) => Promise<SystemUser>;
  logout: () => void;
}

const AuthContext = createContext<IAuthContext | null>(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const metaData = useMetaDate();

  const [authState, setAuthState] = useState<AuthState>({
    accessToken: null,
    refreshToken: null,
    authenticated: false
  });

  const logout = () => {
    window.localStorage.removeItem('token');
    setAuthState({
      accessToken: null,
      refreshToken: null,
      authenticated: false
    });
    navigate('/login');
  };

  const getAccessToken = () => {
    const storedToken = window.localStorage.getItem('token');
    if (authState?.accessToken !== storedToken) {
      setAuthState((state) => ({
        ...state,
        accessToken: storedToken,
        authenticated: true
      }));
    }
    return storedToken ? storedToken : authState.accessToken;
  };

  const checkAuthentication = (
    publicAxios: AxiosInstance
  ): Promise<boolean> => {
    const token = getAccessToken();
    if (!token) {
      return Promise.resolve(false);
    }
    if (metaData.metaDataState?.currentUser) {
      return Promise.resolve(true);
    } else {
      return refetchCurrentUser(publicAxios).then(() => true);
    }
  };

  const refetchCurrentUser = (
    publicAxios: AxiosInstance
  ): Promise<SystemUser> => {
    return publicAxios
      .get<SystemUser>(API_CONSTANTS.USER_CURRENT)
      .then((response) => {
        metaData.setMetaDataState({ currentUser: response.data });
        return response.data;
      });
  };

  return (
    <Provider
      value={{
        authState,
        getAccessToken,
        setAuthState,
        refetchCurrentUser,
        checkAuthentication,
        logout
      }}
    >
      {children}
    </Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth };

import * as Yup from 'yup';

export const AddGiftSchema = Yup.object().shape({
  giftAmount: Yup.number()
    .required('قيمة الهدية مطلوبة')
    .min(1, 'الرجاء اضافة قيمة اكبر من 0'),
  giftSms: Yup.string(),
  giftValidUntil: Yup.date().typeError('يرجى اختيار تاريخ صحيح').nullable(),
  imgSrc: Yup.string()
    .url('يرجى ادخال رابط صحيح')
    .required('يرجى ادخال رابط للصورة'),
  title: Yup.string().required('عنوان الصفحة مطلوب'),
  subTitle: Yup.string(),
  winnerType: Yup.string()
    .oneOf(['MARKET', 'USER'], 'الرجاء اختيار نوع صحيح')
    .required('الرجاء اختيار النوع'),
  winnerUserConfigs: Yup.object()
    .shape({
      winnerSearchType: Yup.string()
        .oneOf(['Name', 'QrCode', 'Phone'], 'الرجاء اختيار نوع صحيح')
        .when('marketWinnerConfigs', {
          is: (v: any) => !v,
          then: Yup.string().required('الرجاء اختيار النوع')
        }),
      winnerUser: Yup.object().nullable()
    })
    .nullable()
    .default(null),
  marketWinnerConfigs: Yup.object()
    .shape({
      orderFromMarket: Yup.array(Yup.number()).nullable(),
      paidAtLeast: Yup.number()
        .min(0, 'الرجاء اضافة قيمة اكبر من 0')
        .nullable(),
      orderStartDate: Yup.date().typeError('يرجى اختيار تاريخ صحيح').nullable(),
      orderEndDate: Yup.date().typeError('يرجى اختيار تاريخ صحيح').nullable()
    })
    .nullable()
    .default(null)
});

import * as Yup from 'yup';
import {
  MarketPointsSettleUpDTO,
  MarketAddDTO,
  MarketPointsTransferDTO
} from '../../containers/api';

const phoneRegExp = /^[0]{1}\d{9}$/g;

export const MarketAddSchema = Yup.object().shape({
  adminUserProfileId: Yup.number().required('المشرف مطلوب'),
  username: Yup.string().required('اسم الحساب مطلوب'),
  password: Yup.string().min(4, 'كلمة السر قصيرة').required('كلمة السر مطلوبة'),
  address: Yup.string().required('العنوان مطلوب'),
  town: Yup.string().required('البلدة مطلوبة'),
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح'),
  percent: Yup.number()
    .min(0, 'تجاوزت الحد الادنى')
    .max(100, 'تجاوزت الحد الاقصى')
    .required('نسبة الخصم مطلوبة'),
  minOrderAmount: Yup.number().required('الحد الادني للطلب مطلوب'),
  cityId: Yup.number().required('المحافظة مطلوبة'),
  email: Yup.string().email('يرجى ادخال بريد الكتروني صحيح'),
  marketCategoryId: Yup.number().required('فئة المتجر مطلوبة'),
  displayName: Yup.string().required('اسم العرض مطلوب'),
  commissionPercent: Yup.number()
    .min(0, 'تجاوزت الحد الادنى')
    .max(100, 'تجاوزت الحد الاقصى')
    .required('نسبة العمولة مطلوبة'),
  isOnlineStore: Yup.boolean(),
  onlineStoreUrl: Yup.string().when('isOnlineStore', {
    is: true,
    then: Yup.string()
      .url('الرجاء استخدام رابط صحيح')
      .required('رابط المتجر مطلوب')
  })
});

export const MarketEditSchema = Yup.object().shape({
  address: Yup.string().required('العنوان مطلوب'),
  town: Yup.string().required('البلدة مطلوبة'),
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح'),
  percent: Yup.number()
    .min(0, 'تجاوزت الحد الادنى')
    .max(100, 'تجاوزت الحد الاقصى')
    .required('نسبة الخصم مطلوبة'),
  minOrderAmount: Yup.number().required('الحد الادني للطلب مطلوب'),
  cityId: Yup.number().required('المحافظة مطلوبة'),
  email: Yup.string().email('يرجى ادخال بريد الكتروني صحيح'),
  marketCategoryId: Yup.number().required('فئة المتجر مطلوبة'),
  displayName: Yup.string().required('اسم العرض مطلوب'),
  commissionPercent: Yup.number()
    .min(0, 'تجاوزت الحد الادنى')
    .max(100, 'تجاوزت الحد الاقصى')
    .required('نسبة العمولة مطلوبة'),
  isOnlineStore: Yup.boolean(),
  onlineStoreUrl: Yup.string().when('isOnlineStore', {
    is: true,
    then: Yup.string()
      .url('الرجاء استخدام رابط صحيح')
      .required('رابط المتجر مطلوب')
  })
});

export const MarketTransferSchema = Yup.object().shape({
  marketUsername: Yup.string().required('المتجر مطلوب'),
  salesRepUsername: Yup.string().required('مندوب المبيعات مطلوب'),
  points: Yup.number().min(1, 'تجاوزت الحد الادنى').required('القيمة مطلوبة'),
  password: Yup.string().required('الرقم السري مطلوب'),
  type: Yup.string().required('نوع الحوالة مطلوب'),
  notes: Yup.string()
});

export const initialValues: MarketAddDTO = {
  adminUserProfileId: undefined,
  username: '',
  password: '',
  address: '',
  town: '',
  phone: '',
  percent: 0,
  minOrderAmount: 0,
  cityId: undefined,
  email: undefined,
  marketCategoryId: undefined,
  displayName: '',
  commissionPercent: 0,
  isOnlineStore: false,
  onlineStoreUrl: ''
};

export const marketTransferInitialValues: MarketPointsTransferDTO = {
  salesRepUsername: '',
  marketUsername: '',
  points: 1,
  type: 'Deposit',
  password: '',
  notes: ''
};

export const MarketSettleUpSchema = Yup.object().shape({
  salesRepAdminUsername: Yup.string().required('المشرف مطلوب'),
  marketUsername: Yup.string().required('المتجر مطلوب'),
  points: Yup.number().min(1, 'تجاوزت الحد الادنى').required('القيمة مطلوبة'),
  password: Yup.string().required('الرقم السري مطلوب'),
  notes: Yup.string().nullable()
});

export const marketSettleUpInitialValues: MarketPointsSettleUpDTO = {
  salesRepAdminUsername: '',
  marketUsername: '',
  points: 1,
  password: '',
  notes: ''
};

export const ChangePasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'كلمة السر غير مطابقة')
    .required('الرجاء تأكيد كلمة السر'),
  password: Yup.string()
    .min(4, 'كلمة السر قصيرة')
    .required('الرجاء إدخال كلمة السر')
});

export const changePasswordInitialValues = {
  confirmPassword: '',
  password: ''
};

import * as Yup from 'yup';
import React, { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import API_CONSTANTS from '../../../containers/api/API_CONSTANTS';

// material
import { Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import TextField from '../../../components/TextField';
import { AuthContext } from '../../../containers/AuthContext';
import { AxiosContext } from '../../../containers/api/Axios';
import useAdminData from '../../../containers/api/useAdminData';
import { useMetaDate } from '../../../containers/MetaDataContext';

// ----------------------------------------------------------------------

export const LoginForm: React.FC<{
  isForgetPasswordForm: boolean;
  onForgetPassword: (val: boolean) => void;
}> = ({ isForgetPasswordForm, onForgetPassword }) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const authContext = useContext(AuthContext);
  const metadata = useMetaDate();
  const { authAxios } = useContext(AxiosContext);
  const adminData = useAdminData();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('اسم المستخدم مطلوب'),
    password: Yup.string().required('كلمة السر مطلوبة')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  const onSubmitForm = () => {
    setIsLoading(true);
    setError('');

    authAxios
      ?.post(API_CONSTANTS.ACCOUNT_LOGIN, {
        username: values.username,
        password: values.password
      })
      .then((response: any) => {
        const { token: accessToken, refreshToken } = response?.data;
        window.localStorage.setItem('token', accessToken);
        authContext?.setAuthState({
          accessToken,
          refreshToken,
          authenticated: true
        });

        adminData
          .fetchAdmin()
          .then((admin) => {
            metadata.setMetaDataState({ currentUser: admin });
            navigate('/pages/home');
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error?.response?.data?.message ?? 'عذرا، حدث خطأ ما');
          });
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error?.response?.data?.message ?? 'عذرا، حدث خطأ ما');
      });
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleRestorePassword = () => {
    authAxios
      ?.post(API_CONSTANTS.RESET_PASSWORD_BY_NAME('account'), { username })
      .then(() => {
        onForgetPassword(false);
        setError('');
      })
      .catch((error) => {
        setError(error?.response?.data?.message ?? 'عذرا، حدث خطأ ما');
      });
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} style={{ direction: 'rtl' }}>
          {isForgetPasswordForm && (
            <TextField
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type='text'
              variant='filled'
              label='اسم المستخدم'
            />
          )}
          {!isForgetPasswordForm && (
            <>
              <TextField
                fullWidth
                autoComplete='username'
                type='text'
                variant='filled'
                label='اسم المستخدم'
                {...getFieldProps('username')}
                InputProps={{ dir: 'rtl' }}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
              <TextField
                fullWidth
                autoComplete='current-password'
                type={showPassword ? 'text' : 'password'}
                label='كلمة السر'
                {...getFieldProps('password')}
                variant='filled'
                InputProps={{
                  dir: 'rtl',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleShowPassword} edge='end'>
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                          sx={undefined}
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </>
          )}
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ my: 2 }}
        >
          <Typography
            variant='subtitle2'
            color='primary'
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setUsername('');
              onForgetPassword(true);
            }}
          >
            {isForgetPasswordForm ? '' : 'نسيت كلمة السر؟'}
          </Typography>
        </Stack>
        {isForgetPasswordForm ? (
          <>
            <LoadingButton
              fullWidth
              size='large'
              onClick={handleRestorePassword}
              variant='contained'
              loading={isLoading}
            >
              إعادة التعيين
            </LoadingButton>
            <LoadingButton
              fullWidth
              size='large'
              onClick={() => {
                setUsername('');
                onForgetPassword(false);
              }}
              variant='text'
              sx={{ marginTop: 2 }}
              loading={isLoading}
            >
              رجوع
            </LoadingButton>
          </>
        ) : (
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isLoading}
          >
            تسجيل الدخول
          </LoadingButton>
        )}
        {!!error && (
          <Typography
            align='center'
            variant='subtitle2'
            color='red'
            marginTop={2}
          >
            {error}
          </Typography>
        )}
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;

// material
import { styled } from '@mui/material/styles';
import { Box, Card, Container } from '@mui/material';
// components
import DownloadApp from './DownloadApp';
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page as any)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  zIndex: 10
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2, 0)
}));

// ----------------------------------------------------------------------

export default function DownloadAppContainer() {
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '10%',
          background:
            'linear-gradient(157deg, rgba(35,35,35,1) 11%, rgba(246,184,67,1) 100%)',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          color: 'white',
          fontSize: 19,
          fontWeight: 'bold',
          boxShadow: '0px 10px 29px 4px rgb(0 0 0 / 45%)',
          borderBottomRightRadius: '40%',
          borderBottomLeftRadius: '40%'
        }}
      />
      <RootStyle title='اكسترا كاش'>
        <Container>
          <ContentStyle>
            <Box
              component='img'
              src='/static/logo.png'
              style={{
                width: '100%',
                paddingLeft: 24,
                paddingRight: 24,
                marginTop: 50,
                marginBottom: 40
              }}
            />
            <div>
              <DownloadApp />
            </div>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}

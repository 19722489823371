import { useContext, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton
} from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';
import { useMetaDate } from '../../containers/MetaDataContext';
import { AuthContext } from '../../containers/AuthContext';
import CurrentUserForm from '../../pages/CurrentUserForm';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'الصفحة الرئيسية',
    icon: 'eva:home-fill',
    linkTo: '/'
  },
  {
    label: 'الملف الشخصي',
    icon: 'eva:person-fill',
    linkTo: '#',
    isProfileItem: true
  }
  // {
  //   label: 'الاعدادات',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#'
  // }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isProfileOpened, setIsProfileOpened] = useState(false);
  const metaData = useMetaDate();
  const authContext = useContext(AuthContext);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    authContext?.logout();
  };

  const handleMenuItemClicked = (item) => {
    if (item.isProfileItem) {
      setIsProfileOpened(true);
    }
    handleClose();
  };

  return (
    <>
      {isProfileOpened && (
        <CurrentUserForm
          isModalOpened={isProfileOpened}
          setIsModalOpened={setIsProfileOpened}
        />
      )}
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt='photoURL' />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle1' noWrap>
            {metaData?.metaDataState?.currentUser?.username}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {metaData?.metaDataState?.currentUser?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={() => handleMenuItemClicked(option)}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Iconify
              icon={option.icon}
              sx={{
                ml: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color='inherit'
            variant='outlined'
            onClick={handleLogout}
          >
            تسجيل الخروج
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { PhoneSchema } from './schema';
import TextField from '../../../components/TextField';
import { AxiosContext } from '../../../containers/api';
import React, { useContext, useEffect, useState } from 'react';
import { SnackbarContext } from '../../../containers/SnackbarContext';
import useStyles from '../../../components/AppModal/styles';
import API_CONSTANTS from '../../../containers/api/API_CONSTANTS';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export const PhoneForm: React.FC = ({}) => {
  const [isValidReferrer, setIsValidReferrer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { publicAxios } = useContext(AxiosContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const [searchParams] = useSearchParams();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: { phone: '' },
    validationSchema: PhoneSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  const { errors, values, touched, getFieldProps, resetForm, isValid, dirty } =
    formik;

  useEffect(() => {
    setIsLoading(true);
    publicAxios
      .get(API_CONSTANTS.CHECK_REGISTERATION + searchParams.get('referrer'))
      .then(() => {
        setIsValidReferrer(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error', autoHideDuration: 200000 });
        setIsValidReferrer(false);
      });
    resetForm();
  }, []);

  const onSubmitForm = () => {
    if (isValidReferrer) {
      publicAxios
        .post(API_CONSTANTS.REGISTER_OTP, { phone: values.phone })
        .then(() => {
          showSnackbar({ message: 'تم ارسال رمز التحقق...' });
          navigate(
            `otp?referrer=${searchParams.get('referrer')}&phone=${values.phone}`
          );
          resetForm();
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
          showSnackbar({ message, severity: 'error' });
        });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type='text'
            label='رقم الهاتف'
            {...getFieldProps('phone')}
            variant='filled'
            color='secondary'
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
        </Grid>
      </Grid>
      <LoadingButton
        style={{
          width: '100%',
          paddingLeft: 24,
          paddingRight: 24,
          margin: 0,
          marginTop: 30,
          marginBottom: 40
        }}
        className={classes.actionButton}
        color='secondary'
        variant='contained'
        loading={isLoading}
        disabled={
          !!Object.keys(errors ?? {}).length ||
          !isValid ||
          !dirty ||
          isLoading ||
          !isValidReferrer
        }
        onClick={() => onSubmitForm()}
      >
        ارسال رمز التحقق
      </LoadingButton>
    </>
  );
};

export default PhoneForm;

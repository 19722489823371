import { SystemUser } from '../../containers/api';
import { useMetaDate } from '../../containers/MetaDataContext';
import ADMIN_PERMISSIONS from './constants/ADMIN_PERMISSIONS';
import MARKET_ADMIN_PERMISSIONS from './constants/MARKET_ADMIN_PERMISSIONS';
import MARKET_PERMISSIONS from './constants/MARKET_PERMISSIONS';
import SALES_ADMIN_PERMISSIONS from './constants/SALES_ADMIN_PERMISSIONS';
import SALES_PERMISSIONS from './constants/SALES_PERMISSIONS';
import USER_PERMISSIONS from './constants/USER_PERMISSIONS';
import { Permissions } from './types';

const permissionsMap: Record<SystemUser['role'], Permissions> = {
  Admin: ADMIN_PERMISSIONS,
  Market: MARKET_PERMISSIONS,
  MarketAdmin: MARKET_ADMIN_PERMISSIONS,
  SalesRep: SALES_PERMISSIONS,
  SalesRepAdmin: SALES_ADMIN_PERMISSIONS,
  User: USER_PERMISSIONS
};

export const useUserPermissions = (): Permissions => {
  const {
    metaDataState: { currentUser }
  } = useMetaDate();

  // TODO: DEFAULT SHOULD BE NORMAL USER
  return permissionsMap[currentUser?.role ?? 'User'];
};

export default useUserPermissions;

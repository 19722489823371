import { Divider, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { AdminAddSchema, AdminEditSchema, initialValues } from './schema';
import TextField from '../../components/TextField';
import {
  SystemUser,
  AdminAddDTO,
  AdminEditDTO,
  AxiosContext
} from '../../containers/api';
import Select from '../../components/Select';
import React, { useContext, useEffect } from 'react';
import { useMetaDate } from '../../containers/MetaDataContext';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import { SnackbarContext } from '../../containers/SnackbarContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import useStyles from '../../components/AppModal/styles';
import { formatDate } from '../../utils';
import useUserPermissions from '../../hooks/useUserPermissions';

// ----------------------------------------------------------------------

const mapFormToEditDTO = (values: any): AdminEditDTO => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    cityId: values.cityId,
    phone: values.phone,
    middleName: values.middleName,
    address: values.address,
    town: values.town,
    email: values.email
  };
};

const mapFormToAddDTO = (values: any): AdminAddDTO => {
  return {
    role: values.role,
    username: values.username,
    password: values.password,
    address: values.address,
    cityId: values.cityId,
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    middleName: values.middleName,
    phone: values.phone,
    town: values.town
  };
};

const mapEntityToForm = (values: SystemUser) => {
  return {
    address: values.address,
    cityId: values.city?.id,
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    middleName: values.middleName,
    phone: values.phone,
    town: values.town,
    role: values.role,
    username: values.username
  };
};

export const AdminForm: React.FC<{
  admin?: SystemUser;
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onRefetch: () => void;
  onAddAdmin: () => void;
}> = ({ admin, isModalOpened, setIsModalOpened, onRefetch, onAddAdmin }) => {
  const { metaDataState } = useMetaDate();
  const { publicAxios } = useContext(AxiosContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const permissions = useUserPermissions();

  const classes = useStyles();

  const {
    setValues,
    errors,
    touched,
    values,
    getFieldProps,
    resetForm,
    isValid,
    dirty
  } = useFormik<any>({
    initialValues: admin ? mapEntityToForm(admin) : initialValues,
    validationSchema: admin?.id ? AdminEditSchema : AdminAddSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  useEffect(() => {
    resetForm();
    if (admin) {
      setValues(admin?.id ? mapEntityToForm(admin) : initialValues);
    }
  }, [admin]);

  const onSubmitForm = () => {
    if (admin?.id) {
      publicAxios
        .put(API_CONSTANTS.EDIT_ADMIN + admin?.id, mapFormToEditDTO(values))
        .then(() => {
          showSnackbar({ message: 'تم تعديل المستخدم بنجاح' });
          resetForm();
          setIsModalOpened(false);
          onRefetch();
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
          showSnackbar({ message, severity: 'error' });
        });
    } else {
      publicAxios
        .post(API_CONSTANTS.ADD_ADMIN, mapFormToAddDTO(values))
        .then(() => {
          showSnackbar({ message: 'تمت اضافة المستخدم بنجاح' });
          resetForm();
          setIsModalOpened(false);
          onRefetch();
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
          showSnackbar({ message, severity: 'error' });
        });
    }
  };

  const handleClose = () => {
    resetForm();
    setIsModalOpened(false);
  };

  return (
    <>
      {permissions.admins.write && (
        <Button variant='contained' onClick={onAddAdmin}>
          + اضافة مشرف
        </Button>
      )}
      {isModalOpened && (
        <Dialog
          open={isModalOpened}
          onClose={() => {}}
          scroll='paper'
          fullWidth
          disableEscapeKeyDown
          aria-labelledby='app-modal-title'
          aria-describedby='app-modal-description'
        >
          <DialogTitle id='app-modal-title'>
            {admin?.id ? 'تعديل مشرف' : 'اضافة مشرف'}
          </DialogTitle>
          <DialogContent dividers>
            <Divider style={{ marginBottom: 10 }} textAlign='left'>
              معلومات الحساب
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='اسم الحساب'
                  {...getFieldProps('username')}
                  variant='filled'
                  disabled={Boolean(admin?.id)}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='password'
                  disabled={Boolean(admin?.id)}
                  label='كلمة السر'
                  {...getFieldProps('password')}
                  variant='filled'
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label='الوظيفة'
                  {...getFieldProps('role')}
                  variant='filled'
                  fullWidth
                  disabled={Boolean(admin?.id)}
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                  options={metaDataState?.roles?.map((role) => ({
                    id: role,
                    name: role
                  }))}
                />
              </Grid>
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              المعلومات الاساسية
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الاسم'
                  {...getFieldProps('firstName')}
                  variant='filled'
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الأب'
                  {...getFieldProps('middleName')}
                  variant='filled'
                  error={Boolean(touched.middleName && errors.middleName)}
                  helperText={touched.middleName && errors.middleName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='العائلة'
                  {...getFieldProps('lastName')}
                  variant='filled'
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              العنوان
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='الشارع'
                  {...getFieldProps('address')}
                  variant='filled'
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type='text'
                  label='البلدة'
                  {...getFieldProps('town')}
                  variant='filled'
                  error={Boolean(touched.town && errors.town)}
                  helperText={touched.town && errors.town}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  fullWidth
                  type='text'
                  label='المحافظة'
                  {...getFieldProps('cityId')}
                  variant='filled'
                  options={metaDataState.cities}
                  error={Boolean(touched.cityId && errors.cityId)}
                  helperText={touched.cityId && errors.cityId}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='رقم الهاتف'
                  {...getFieldProps('phone')}
                  variant='filled'
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='text'
                  label='البريد الالكتروني'
                  {...getFieldProps('email')}
                  variant='filled'
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
            </Grid>
            {admin?.id && (
              <>
                <Divider
                  style={{ marginTop: 20, marginBottom: 10 }}
                  textAlign='left'
                >
                  اخرى
                </Divider>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      type='text'
                      disabled
                      value={admin?.points}
                      label='الرصيد'
                      variant='filled'
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      type='text'
                      disabled
                      value={formatDate(admin?.registeredDate)}
                      label='تاريخ الاضافة'
                      variant='filled'
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.actionButton}
              color='error'
              variant='contained'
              onClick={handleClose}
            >
              الغاء
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={
                !!Object.keys(errors ?? {}).length || !isValid || !dirty
              }
              onClick={() => onSubmitForm()}
            >
              حفظ
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AdminForm;

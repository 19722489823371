import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  SnackbarConsumer,
  SnackbarProvider
} from '../containers/SnackbarContext';
import {
  AppDialogConsumer,
  AppDialogProvider
} from '../containers/AppDialogContext';
import AppSnackbar from '../components/Snackbar';
import AppDialog from '../components/AppDialog';
import { AuthContext } from '../containers/AuthContext';
import { AxiosContext } from '../containers/api';
import useCityData from '../containers/api/useCityData';
import { useMetaDate } from '../containers/MetaDataContext';
import useRolesData from '../containers/api/useRolesData';
import useMarketCategoriesData from '../containers/api/useMarketCategoriesData';
import useUserPermissions from '../hooks/useUserPermissions';

const App: React.FC = () => {
  const authContext = useContext(AuthContext);
  const { metaDataState, setMetaDataState } = useMetaDate();
  const { fetchCities } = useCityData();
  const { fetchRoles } = useRolesData();
  const { fetchMarketCategories } = useMarketCategoriesData();
  const permissions = useUserPermissions();
  const { publicAxios } = useContext(AxiosContext);
  const navigate = useNavigate();

  useEffect(() => {
    authContext?.checkAuthentication(publicAxios).then((loggedIn) => {
      if (!loggedIn) {
        navigate('/login');
        return;
      }
      fetchCities().then((cities) => {
        setMetaDataState({ cities });
      });

      if (permissions.admins.read) {
        fetchRoles().then((roles) => {
          setMetaDataState({ roles });
        });
      }

      fetchMarketCategories().then((marketCategories) => {
        setMetaDataState({ marketCategories });
      });
    });
  }, []);

  if (!authContext?.authState?.authenticated || !metaDataState.currentUser) {
    // TODO: show loader
    return <div></div>;
  }
  return (
    <SnackbarProvider>
      <AppDialogProvider>
        <Outlet />
        <SnackbarConsumer>
          {({ snackBarState, hideSnackbar }) => (
            <AppSnackbar {...snackBarState} handleClose={hideSnackbar} />
          )}
        </SnackbarConsumer>
        <AppDialogConsumer>
          {({ appDialogState, hideAppDialog }) => (
            <AppDialog
              {...appDialogState}
              onClose={hideAppDialog}
              onSubmit={appDialogState.onSubmit}
            />
          )}
        </AppDialogConsumer>
      </AppDialogProvider>
    </SnackbarProvider>
  );
};

export default App;

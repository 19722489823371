var XLSX = require('xlsx');

export const readExcel = async (
  file: File,
  uploadCallback: (data: Array<object>) => void
) => {
  const fileReader = await new FileReader();
  fileReader.readAsArrayBuffer(file);

  fileReader.onload = (e: any) => {
    const bufferArray = e?.target?.result;
    const wb = XLSX.read(bufferArray, { type: 'buffer' });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];

    const data = XLSX.utils.sheet_to_json(ws);
    uploadCallback(data);
  };
};

import { Permissions } from '../types';

const ADMIN_PERMISSIONS: Permissions = {
  dashboard: {
    monthlyPurchases: {
      read: true
    },
    totalPurchases: {
      read: true
    },
    totalReferrals: {
      read: true
    },
    points: {
      read: true
    },
    userProfilesCount: {
      read: true
    },
    totalCommissions: {
      read: true
    },
    totalBills: {
      read: true
    }
  },
  users: {
    read: true,
    write: true,
    update: true,
    deactivate: true,
    resetPassword: true,
    generateCode: true
  },
  markets: {
    read: true,
    write: true,
    update: true,
    deactivate: true,
    resetPassword: true,
    changePassword: true,
    transferPoints: false,
    settleUp: false
  },
  orders: {
    read: true,
    write: true,
    archive: true
  },
  admins: {
    read: true,
    write: true,
    update: true,
    deactivate: true,
    resetPassword: true
  },
  transactions: {
    read: true,
    archive: true,
    addBill: false
  },
  sales: {
    read: true,
    write: true,
    update: true,
    deactivate: true,
    resetPassword: true,
    transferPoints: false
  },
  qrCodes: {
    generate: true
  },
  prize: {
    read: true
  }
};

export default ADMIN_PERMISSIONS;

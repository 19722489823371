import { WinnerConfigs } from '@/types';
import { useState } from 'react';

const SYSTEM_LOCAL_STORAGE_KEY = 'ex_cash';

export interface SystemLocalStorage {
  winnerPageConfigs: WinnerConfigs;
}

export const DEFAULT_SYSTEM_LOCAL_STORAGE: SystemLocalStorage = {
  winnerPageConfigs: {
    title: 'السحب على ايفون 13 مقدم من',
    subTitle: 'معطرات ايفل',
    imgSrc: 'https://i.ibb.co/9gkVN4B/eyfel.png',
    winnerType: 'MARKET',
    marketWinnerConfigs: {},
    winnerUserConfigs: {
      winnerSearchType: 'Name'
    },
    giftAmount: 0
  }
};

export const useLocalStorage = () => {
  const getSystemLocalStorage = () => {
    const storage = localStorage.getItem(SYSTEM_LOCAL_STORAGE_KEY);
    if (!storage) {
      return DEFAULT_SYSTEM_LOCAL_STORAGE;
    }
    return JSON.parse(storage);
  };

  const [systemLocalStorage, setSystemLocalStorage] =
    useState<SystemLocalStorage>(getSystemLocalStorage());

  const getItem = <T extends keyof SystemLocalStorage>(
    itemKey: T
  ): SystemLocalStorage[T] => {
    return systemLocalStorage[itemKey];
  };

  const setItem = <T extends keyof SystemLocalStorage>(
    itemKey: T,
    item: any
  ) => {
    const newStorage = { ...systemLocalStorage, [itemKey]: item };
    setSystemLocalStorage(newStorage);
    flushStorage(newStorage);
  };

  const flushStorage = (newStorage = systemLocalStorage) => {
    localStorage.setItem(SYSTEM_LOCAL_STORAGE_KEY, JSON.stringify(newStorage));
  };

  return {
    storage: systemLocalStorage,
    setItem,
    getItem,
    flushStorage
  };
};

export default useLocalStorage;

import * as Yup from 'yup';
import { AdminAddDTO } from '../../containers/api';

const phoneRegExp = /^[0]{1}\d{9}$/g;

export const AdminAddSchema = Yup.object().shape({
  username: Yup.string().required('اسم الحساب مطلوب'),
  password: Yup.string().min(4, 'كلمة السر قصيرة').required('كلمة السر مطلوبة'),
  firstName: Yup.string().required('الاسم مطلوب'),
  lastName: Yup.string(),
  role: Yup.string().required('الوظيفة مطلوبة'),
  cityId: Yup.number(),
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح'),
  middleName: Yup.string(),
  address: Yup.string(),
  town: Yup.string(),
  email: Yup.string().email('يرجى ادخال بريد الكتروني صحيح')
});

export const AdminEditSchema = Yup.object().shape({
  firstName: Yup.string().required('الاسم مطلوب'),
  lastName: Yup.string(),
  cityId: Yup.number(),
  phone: Yup.string()
    .required('رقم الهاتف مطلوب')
    .matches(phoneRegExp, 'رقم الهاتف غير صحيح'),
  middleName: Yup.string(),
  address: Yup.string(),
  town: Yup.string(),
  email: Yup.string().email('يرجى ادخال بريد الكتروني صحيح')
});

export const initialValues: AdminAddDTO = {
  username: '',
  password: '',
  firstName: '',
  role: '',
  phone: '',
  lastName: undefined,
  cityId: undefined,
  middleName: undefined,
  address: undefined,
  town: undefined,
  email: undefined
};

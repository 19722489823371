import React, { useEffect, useState, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Popover,
  TableHead,
  TableSortLabel,
  Divider
} from '@mui/material';
import p from '../../components/Page';
import Scroll from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  OrderListHead,
  OrderListToolbar
} from '../../sections/@dashboard/orders';
import ItemMoreMenu from '../../components/ItemMoreMenu';
import useEntityData from '../../containers/api/useEntityData';
import { Order as OrderDTO, OrderAction } from '../../containers/api/dto';
import { formatDecimals, formatDateTime } from '../../utils';
import AppBackdrop from '../../components/AppBackdrop';
import Iconify from '../../components/Iconify';
import { AppDialogContext } from '../../containers/AppDialogContext';
import moment from 'moment';
import OrderForm from './OrderForm';
import useUserPermissions from '../../hooks/useUserPermissions';
import { DEFAULT_FILTERS_STATE } from './Constants';

const Scrollbar: any = Scroll;
const Page: any = p;
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'اسم الزبون', alignRight: false },
  { id: 'phone', label: 'هاتف الزبون', alignRight: false },
  { id: 'market', label: 'اسم المتجر', alignRight: false },
  { id: 'admin', label: 'مشرف المتجر', alignRight: false },
  { id: 'amount', label: 'قيمة المشتريات (₪)', alignRight: false },
  { id: 'orderDate', label: 'تاريخ المشتريات', alignRight: false },
  { id: 'userAddedBy', label: 'اضيف الزبون بواسطة', alignRight: false },
  { id: 'extraData', label: '', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function Orders() {
  const [page, setPage] = useState(0);
  const [filterMarketName, setFilterMarketName] = useState(
    DEFAULT_FILTERS_STATE.filterMarketName
  );
  const [filterAdminName, setFilterAdminName] = useState(
    DEFAULT_FILTERS_STATE.filterAdminName
  );

  const [filterOnlineOrderId, setFilterOnlineOrderId] = useState(
    DEFAULT_FILTERS_STATE.filterOnlineOrderId
  );

  const [onlyWithDiscount, setOnlyWithDiscount] = useState(
    DEFAULT_FILTERS_STATE.onlyWithDiscount
  );
  const [onlyCreatedByAdmin, setOnlyCreatedByAdmin] = useState(
    DEFAULT_FILTERS_STATE.onlyCreatedByAdmin
  );
  const [onlyArchivedOrders, setOnlyArchivedOrders] = useState(
    DEFAULT_FILTERS_STATE.onlyArchivedOrders
  );

  const [onlyOnlineOrders, setOnlyOnlineOrders] = useState(
    DEFAULT_FILTERS_STATE.onlyOnlineOrders
  );

  const [filterUserName, setFilterUserName] = useState(
    DEFAULT_FILTERS_STATE.filterUserName
  );
  const [filterUserPhone, setFilterUserPhone] = useState(
    DEFAULT_FILTERS_STATE.filterUserPhone
  );
  const [filterStartDate, setFilterStartDate] = useState(
    DEFAULT_FILTERS_STATE.filterStartDate
  );
  const [filterEndDate, setFilterEndDate] = useState(
    DEFAULT_FILTERS_STATE.filterEndDate
  );
  const [totalDiscounts, setTotalDiscounts] = useState(0);
  const [totalCommissions, setTotalCommissions] = useState(0);
  const [totalGifts, setTotalGifts] = useState(0);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [hoveredOrder, setHoveredOrder] = useState<OrderDTO | undefined>();
  const appDialogService = useContext(AppDialogContext);
  const permissions = useUserPermissions();

  const [rowsPerPage, setRowsPerPage] = useState(8);

  const { fetchEntities, entities, totalCount, isLoading, deleteEntity } =
    useEntityData<OrderDTO>({
      url: `order`,
      entityKey: 'orders',
      entityName: 'المشتريات'
    });

  useEffect(() => {
    handleFetchData();
  }, [page, rowsPerPage]);

  const handleFetchData = (eagerFilters: any = {}) => {
    fetchEntities({
      queryParams: {
        UserPhone: eagerFilters?.filterUserPhone ?? filterUserPhone,
        UserName: eagerFilters?.filterUserName ?? filterUserName,
        MarketName: eagerFilters?.filterMarketName ?? filterMarketName,
        MarketAdminName: eagerFilters?.filterAdminName ?? filterAdminName,
        OnlineOrderId: eagerFilters?.filterOnlineOrderId ?? filterOnlineOrderId,
        onlyWithDiscount: `${
          eagerFilters.onlyWithDiscount ?? onlyWithDiscount
        }`,
        onlyCreatedByAdmin: `${
          eagerFilters.onlyCreatedByAdmin ?? onlyCreatedByAdmin
        }`,
        isArchived: `${eagerFilters.onlyArchivedOrders ?? onlyArchivedOrders}`,
        onlyOnlineOrders: `${
          eagerFilters.onlyOnlineOrders ?? onlyOnlineOrders
        }`,
        startDate: eagerFilters?.filterStartDate ?? filterStartDate,
        endDate: eagerFilters?.filterEndDate ?? filterEndDate,
        Page: page + 1,
        PageSize: rowsPerPage
      }
    }).then((response: any) => {
      setTotalDiscounts(response.totalDiscounts);
      setTotalGifts(response.totalGifts);
      setTotalPurchases(response.totalPurchases);
      setTotalCommissions(response.totalCommissions);
    });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterMarketName = (event: any) => {
    setFilterMarketName(event?.target.value);
  };

  const handleFilterAdminName = (event: any) => {
    setFilterAdminName(event?.target.value);
  };

  const handleFilterOnlineOrderId = (event: any) => {
    setFilterOnlineOrderId(event?.target.value);
  };

  const handleOnlyWithDiscount = (event: any) => {
    setOnlyWithDiscount(event?.target.checked);
    handleSearch({
      onlyCreatedByAdmin,
      onlyArchivedOrders,
      onlyOnlineOrders,
      onlyWithDiscount: event?.target.checked
    });
  };
  const handleOnlyCreatedByAdmin = (event: any) => {
    setOnlyCreatedByAdmin(event?.target.checked);
    handleSearch({
      onlyWithDiscount,
      onlyArchivedOrders,
      onlyOnlineOrders,
      onlyCreatedByAdmin: event?.target.checked
    });
  };
  const handleOnlyArchivedOrders = (event: any) => {
    setOnlyArchivedOrders(event?.target.checked);
    handleSearch({
      onlyWithDiscount,
      onlyCreatedByAdmin,
      onlyOnlineOrders,
      onlyArchivedOrders: event?.target.checked
    });
  };

  const handleOnlyOnlineOrders = (event: any) => {
    setOnlyOnlineOrders(event?.target.checked);
    handleSearch({
      onlyWithDiscount,
      onlyCreatedByAdmin,
      onlyArchivedOrders,
      onlyOnlineOrders: event?.target.checked
    });
  };

  const handleFilterUserName = (event: any) => {
    setFilterUserName(event?.target.value);
  };
  const handleFilterUserPhone = (event: any) => {
    setFilterUserPhone(event?.target.value);
  };
  const handleFilterStartDate = (event: Date | null) => {
    setFilterStartDate(
      event ? moment(event).startOf('day').utc().format() : ''
    );
  };
  const handleFilterEndDate = (event: Date | null) => {
    setFilterEndDate(event ? moment(event).endOf('day').utc().format() : '');
  };

  const handleSearch = (eagerFilters: any) => {
    setPage(0);
    handleFetchData(eagerFilters);
  };

  const handleReset = () => {
    setPage(0);
    setFilterMarketName(DEFAULT_FILTERS_STATE.filterMarketName);
    setFilterAdminName(DEFAULT_FILTERS_STATE.filterAdminName);
    setFilterOnlineOrderId(DEFAULT_FILTERS_STATE.filterOnlineOrderId);
    setFilterUserName(DEFAULT_FILTERS_STATE.filterUserName);
    setFilterUserPhone(DEFAULT_FILTERS_STATE.filterUserPhone);
    setFilterStartDate(DEFAULT_FILTERS_STATE.filterStartDate);
    setFilterEndDate(DEFAULT_FILTERS_STATE.filterEndDate);
    setOnlyWithDiscount(DEFAULT_FILTERS_STATE.onlyWithDiscount);
    setOnlyCreatedByAdmin(DEFAULT_FILTERS_STATE.onlyCreatedByAdmin);
    setOnlyArchivedOrders(DEFAULT_FILTERS_STATE.onlyArchivedOrders);
    setOnlyOnlineOrders(DEFAULT_FILTERS_STATE.onlyOnlineOrders);
    handleFetchData({ ...DEFAULT_FILTERS_STATE });
  };

  const handleArchiveOrder = (order: OrderDTO) => {
    appDialogService.showAppDialog({
      onSubmit: () => {
        deleteEntity(order.id, { message: 'تم أرشفة المشتريات بنجاح' });
        appDialogService.hideAppDialog();
      },
      title: 'تأكيد العملية',
      text: 'هل انت متأكد من أرشفة المشتريات؟'
    });
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCount) : 0;

  const isEmptyList = entities.length === 0;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (order: OrderDTO, event: any) => {
    setHoveredOrder(order);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setHoveredOrder(undefined);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleAddOrder = () => {
    setIsModalOpened(true);
  };

  const handleRefetch = () => {
    handleFetchData();
  };

  return (
    <Page title='اكسترا كاش'>
      <Container>
        <Popover
          id='mouse-over-popover'
          sx={{
            pointerEvents: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='left' style={{ textAlign: 'right' }}>
                    <TableSortLabel hideSortIcon active={false}>
                      نوع الحركة
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' style={{ textAlign: 'right' }}>
                    <TableSortLabel hideSortIcon active={false}>
                      القيمة (₪)
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hoveredOrder?.actions?.map((row: OrderAction) => {
                  const { id, points, typeDisplay } = row;
                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell align='right'>{typeDisplay}</TableCell>
                      <TableCell align='right'>
                        {formatDecimals(points)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {hoveredOrder?.onlineOrderId ? (
            <>
              <Divider />
              <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                  <TableBody>
                    <TableRow hover tabIndex={-1}>
                      <TableCell align='right'>رقم الطلب</TableCell>
                      <TableCell align='right'>
                        {hoveredOrder?.onlineOrderId}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
        </Popover>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            المشتريات
          </Typography>
          <OrderForm
            isModalOpened={isModalOpened}
            setIsModalOpened={setIsModalOpened}
            onRefetch={handleRefetch}
            onAddOrder={handleAddOrder}
          />
        </Stack>

        <Card>
          <AppBackdrop open={isLoading} />
          <OrderListToolbar
            filterMarketName={filterMarketName}
            onFilterMarketName={handleFilterMarketName}
            onlyWithDiscount={onlyWithDiscount}
            onOnlyWithDiscount={handleOnlyWithDiscount}
            onlyCreatedByAdmin={onlyCreatedByAdmin}
            onOnlyCreatedByAdmin={handleOnlyCreatedByAdmin}
            onlyArchivedOrders={onlyArchivedOrders}
            onOnlyArchivedOrders={handleOnlyArchivedOrders}
            onlyOnlineOrders={onlyOnlineOrders}
            onOnlyOnlineOrders={handleOnlyOnlineOrders}
            filterAdminName={filterAdminName}
            onFilterAdminName={handleFilterAdminName}
            filterOnlineOrderId={filterOnlineOrderId}
            onFilterOnlineOrderId={handleFilterOnlineOrderId}
            filterUserName={filterUserName}
            onFilterUserName={handleFilterUserName}
            filterUserPhone={filterUserPhone}
            onFilterUserPhone={handleFilterUserPhone}
            filterStartDate={filterStartDate}
            onFilterStartDate={handleFilterStartDate}
            filterEndDate={filterEndDate}
            onFilterEndDate={handleFilterEndDate}
            onSearch={handleSearch}
            onReset={handleReset}
          />
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Typography
              component='span'
              sx={{ marginRight: 2 }}
              variant='subtitle2'
            >
              مجموع الخصم: {totalDiscounts}
            </Typography>
            <Typography
              component='span'
              sx={{ marginRight: 2 }}
              variant='subtitle2'
            >
              مجموع الهدايا: {totalGifts}
            </Typography>
            <Typography
              component='span'
              sx={{ marginRight: 2 }}
              variant='subtitle2'
            >
              مجموع المشتريات: {totalPurchases}
            </Typography>
            <Typography
              component='span'
              sx={{ marginRight: 2 }}
              variant='subtitle2'
            >
              مجموع العمولات: {totalCommissions}
            </Typography>
          </div>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderListHead headLabel={TABLE_HEAD} rowCount={totalCount} />
                <TableBody>
                  {entities.map((row: OrderDTO) => {
                    const {
                      id,
                      userProfile,
                      market,
                      orderDate,
                      orderTotal,
                      actions
                    } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role='checkbox'>
                        <TableCell component='th' scope='row' align='right'>
                          <Stack
                            direction='row'
                            alignItems='center'
                            spacing={2}
                          >
                            <Typography variant='subtitle2' noWrap>
                              {userProfile?.fullName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align='right'>
                          {userProfile?.phone}
                        </TableCell>
                        <TableCell align='right'>
                          {market?.displayName}
                        </TableCell>
                        <TableCell align='right'>
                          {market?.adminUserProfile?.fullName}
                        </TableCell>
                        <TableCell align='right'>
                          {formatDecimals(orderTotal)}
                        </TableCell>
                        <TableCell align='right'>
                          {formatDateTime(orderDate)}
                        </TableCell>
                        <TableCell align='right'>
                          {userProfile?.createdBy ?? ''}
                        </TableCell>
                        <TableCell align='right'>
                          {!!actions?.length && (
                            <>
                              <IconButton
                                color='primary'
                                aria-owns={
                                  open ? 'mouse-over-popover' : undefined
                                }
                                aria-haspopup='true'
                                onMouseEnter={(e) => handlePopoverOpen(row, e)}
                                onMouseLeave={handlePopoverClose}
                              >
                                <Iconify
                                  icon='ci:info-circle'
                                  width={20}
                                  height={20}
                                  sx={undefined}
                                />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          {permissions.orders.archive && !row.isArchived ? (
                            <ItemMoreMenu
                              options={[
                                {
                                  label: 'أرشفة',
                                  action: () => handleArchiveOrder(row),
                                  icon: 'gridicons:trash'
                                }
                              ]}
                            />
                          ) : (
                            <span />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isEmptyList && (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery='' />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[8, 16, 32]}
            component='div'
            count={totalCount}
            labelRowsPerPage='العدد لكل صفحة'
            labelDisplayedRows={({ from, to, page, count }) => {
              return `${from} - ${to} (العدد الكلي ${count})`;
            }}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    right: 7,
    left: 'unset'
  },
  input: {
    paddingLeft: '12px !important'
  }
}));

export default useStyles;

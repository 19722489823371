import moment from 'moment';
import { useContext, useState } from 'react';
import { SnackbarContext } from '../SnackbarContext';
import API_CONSTANTS from './API_CONSTANTS';
import { AxiosContext } from './Axios';

export interface Highlights {
  marketsCount: number;
  monthlyPurchases: number;
  points: number;
  pointsDifference: number;
  totalPurchases: number;
  totalReferrals: number;
  userProfilesCount: number;
  totalCommissions: number;
  totalBills: number;
}

const useSummaryData = () => {
  const { publicAxios } = useContext(AxiosContext);
  const [highlights, setHighlights] = useState<Highlights>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useContext(SnackbarContext);

  const fetchHighlights = (): Promise<Highlights> => {
    const monthStartDate = moment().startOf('month').utc().format();
    const monthEndDate = moment().endOf('month').utc().format();
    const response = publicAxios.get(API_CONSTANTS.SUMMARY_HIGHLIGHTS, {
      params: { monthStartDate, monthEndDate }
    });
    setIsLoading(true);
    return response
      .then((result) => {
        setIsLoading(false);
        const data = result.data;
        setHighlights(data);
        return data;
      })
      .catch((error) => {
        setIsLoading(false);
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
        return highlights;
      });
  };

  return { isLoading, highlights, fetchHighlights, publicAxios };
};

export default useSummaryData;

import { useContext, useState } from 'react';
import { buildURLQuery } from '../../utils';
import { SnackbarContext } from '../SnackbarContext';
import { AxiosContext } from './Axios';
import { BaseEntity } from './dto';
import useEntityCrud, { EntityCrudActionOptions } from './useEntityCrud';

export interface dataHookParams {
  url: string;
  entityKey?: string;
  entityName?: string;
}

export interface FetchOptions {
  queryParams?: Record<string, number | string>;
}

export interface FetchEntitiesResponse {
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

const useEntityData = <Entity extends BaseEntity>({
  url,
  entityKey,
  entityName
}: dataHookParams) => {
  const { publicAxios } = useContext(AxiosContext);
  const entityCrud = useEntityCrud<Entity>({ url, entityName });
  const [entities, setEntities] = useState<Entity[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useContext(SnackbarContext);

  const toggleActivation = (id: number) => {
    return entityCrud.toggleActivation(id).then(() => {
      const entityIndex = entities.findIndex((entity) => entity.id === id);
      if (entityIndex > -1) {
        const updatedEntities = entities.slice();
        updatedEntities[entityIndex] = {
          ...entities[entityIndex],
          isDeactivated: !entities[entityIndex].isDeactivated
        };
        setEntities(updatedEntities);
      }
    });
  };

  const fetchEntities = (options: FetchOptions = {}): Promise<Entity[]> => {
    const response = publicAxios.get<any>(
      buildURLQuery(url, options.queryParams)
    );
    setIsLoading(true);
    return response
      .then((result) => {
        setIsLoading(false);
        const data = result.data ?? [];
        setEntities(entityKey ? data[entityKey] : data);
        setTotalCount(data.totalCount);
        setTotalPages(data.totalPages);
        return data;
      })
      .catch((error) => {
        setIsLoading(false);
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
        return entities;
      });
  };

  const addEntity = (entity: Entity): Promise<Entity | undefined> => {
    return entityCrud.addEntity(entity).then((addedEntity) => {
      addedEntity && setEntities((entities) => [addedEntity, ...entities]);
      return addedEntity;
    });
  };

  const updateEntity = (entity: Entity): Promise<Entity | undefined> => {
    return entityCrud.updateEntity(entity).then((updatedEntity) => {
      if (updatedEntity) {
        const entityIndex = entities.findIndex(
          ({ id }) => id === updatedEntity.id
        );
        const newEntities = entities.slice();
        if (entityIndex > -1) {
          newEntities[entityIndex] = updatedEntity;
        } else {
          newEntities.push(updatedEntity);
        }
        setEntities(newEntities);
      }
      return updatedEntity;
    });
  };

  const deleteEntity = (id: number, options?: EntityCrudActionOptions) => {
    return entityCrud.deleteEntity(id, options).then(() => {
      setEntities((entities) => entities.filter((e) => e.id !== id));
    });
  };

  return {
    isLoading: isLoading || entityCrud.isLoading,
    toggleActivation,
    totalCount,
    totalPages,
    entity: entityCrud.entity,
    entities,
    fetchEntities,
    fetchEntity: entityCrud.fetchEntity,
    addEntity,
    updateEntity,
    deleteEntity,
    publicAxios
  };
};

export default useEntityData;

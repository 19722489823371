import { Divider, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { OrderAddSchema, initialValues } from './schema';
import TextField from '../../components/TextField';
import { AddOrderDTO, AxiosContext } from '../../containers/api';
import React, { useContext, useEffect, useState } from 'react';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import { SnackbarContext } from '../../containers/SnackbarContext';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import useStyles from '../../components/AppModal/styles';
import Dropdown from '../../components/Dropdown';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ImportDialog from '../../components/ImportDialog';
import Autocomplete from '../../components/Autocomplete';
import Select from '../../components/Select';
import useEntityData from '../../containers/api/useEntityData';
import { User as UserDTO, Market as MarketDTO } from '../../containers/api/dto';
import useUserPermissions from '../../hooks/useUserPermissions';

// ----------------------------------------------------------------------

const mapFormToAddDTO = (values: any): AddOrderDTO => {
  return {
    marketId: values.marketId,
    orderTotal: values.orderTotal,
    userId: values.userId,
    discountAmount: values.discountAmount
  };
};

export const OrderForm: React.FC<{
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onRefetch: () => void;
  onAddOrder: () => void;
}> = ({ isModalOpened, setIsModalOpened, onRefetch, onAddOrder }) => {
  const [isImportModalOpened, setIsImportModalOpened] = useState(false);
  const permissions = useUserPermissions();

  const [userSearchType, setUserSearchType] = useState('');
  const [marketSearchType, setMarketSearchType] = useState('');

  const { publicAxios } = useContext(AxiosContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const {
    entities: markets,
    fetchEntities: fetchMarkets,
    isLoading: isLoadingMarkets
  } = useEntityData<MarketDTO>({
    url: `market`,
    entityKey: 'markets',
    entityName: 'المتجر'
  });
  const {
    entities: users,
    fetchEntities: fetchUsers,
    isLoading: isLoadingUsers
  } = useEntityData<UserDTO>({
    url: API_CONSTANTS.USER_PROFILE,
    entityKey: 'userProfiles'
  });

  const classes = useStyles();

  const formik = useFormik<any>({
    initialValues: initialValues,
    validationSchema: OrderAddSchema,
    onSubmit: () => {
      onSubmitForm();
    }
  });

  const {
    errors,
    touched,
    values,
    getFieldProps,
    resetForm,
    isValid,
    dirty,
    setFieldValue,
    setTouched
  } = formik;

  useEffect(() => {
    resetForm();
  }, [isModalOpened]);

  const onSubmitForm = () => {
    publicAxios
      .post(API_CONSTANTS.ADD_ORDER, mapFormToAddDTO(values))
      .then(() => {
        showSnackbar({ message: 'تمت اضافة المشتريات بنجاح' });
        resetForm();
        setIsModalOpened(false);
        onRefetch();
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
      });
  };

  const handleClose = () => {
    resetForm();
    setIsModalOpened(false);
  };

  const addMenuItems = [
    {
      action: onAddOrder,
      icon: <AddIcon />,
      label: 'اضافة مشتريات'
    },
    {
      action: () => setIsImportModalOpened(true),
      icon: <UploadFileIcon />,
      label: 'استيراد مشتريات'
    }
  ];

  const handleFetchMarkets = (searchText: string) => {
    fetchMarkets({
      queryParams: { [marketSearchType]: searchText, Page: 1, PageSize: 10 }
    });
  };

  const handleFetchUsers = (searchText: string) => {
    fetchUsers({
      queryParams: { [userSearchType]: searchText, Page: 1, PageSize: 10 }
    });
  };

  const handleMarketChange = (value: MarketDTO) => {
    setTouched({ ...touched, marketId: true }, true);
    setFieldValue('marketId', value?.id, true);
  };

  const handleUserChange = (value: UserDTO) => {
    setTouched({ ...touched, userId: true }, true);
    setFieldValue('userId', value?.id, true);
  };

  const handleUserSearchTypeChanged = (e: any) => {
    setUserSearchType(e.target.value);
    setFieldValue('useId', null, false);
  };

  const handleMarketSearchTypeChanged = (e: any) => {
    setMarketSearchType(e.target.value);
    setFieldValue('marketId', null, false);
  };

  const uploadDataAPI = (data: Array<object>) =>
    publicAxios.post(API_CONSTANTS.UPLOAD_ORDERS, { excelOrdersDtos: data });

  return (
    <>
      <ImportDialog
        title='استيراد مشتريات'
        isOpen={isImportModalOpened}
        templateUrl='/templates/orders.xlsx'
        templateFileName='orders'
        uploadDataAPI={uploadDataAPI}
        onRefetch={onRefetch}
        onClose={() => setIsImportModalOpened(false)}
      />
      {permissions.orders.write && (
        <Dropdown label='اضافة' items={addMenuItems} />
      )}
      {isModalOpened && (
        <Dialog
          open={isModalOpened}
          onClose={() => {}}
          scroll='paper'
          fullWidth
          disableEscapeKeyDown
          aria-labelledby='app-modal-title'
          aria-describedby='app-modal-description'
        >
          <DialogTitle id='app-modal-title'>اضافة مشتريات</DialogTitle>
          <DialogContent dividers>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              اختيار المتجر
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Select
                  label='البحث حسب'
                  variant='filled'
                  fullWidth
                  onChange={handleMarketSearchTypeChanged}
                  options={[
                    { id: 'displayName', name: 'الاسم' },
                    { id: 'MarketAdminName', name: 'اسم المشرف' }
                  ]}
                />
              </Grid>
              {marketSearchType && (
                <Grid item xs={6}>
                  <Autocomplete
                    inputLabel='المتجر'
                    options={markets}
                    optionLabelAccessor='displayName'
                    isLoading={isLoadingMarkets}
                    error={Boolean(touched.marketId && errors.marketId)}
                    helperText={
                      touched.marketId ? errors.marketId?.toString() : ''
                    }
                    fetchOptions={handleFetchMarkets}
                    onSelectionChange={handleMarketChange}
                  />
                </Grid>
              )}
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              اختيار الزبون
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Select
                  label='البحث حسب'
                  variant='filled'
                  fullWidth
                  onChange={handleUserSearchTypeChanged}
                  options={[
                    { id: 'Name', name: 'الاسم' },
                    { id: 'QrCode', name: 'الكود' },
                    { id: 'Phone', name: 'رقم الهاتف' }
                  ]}
                />
              </Grid>
              {userSearchType && (
                <Grid item xs={6}>
                  <Autocomplete
                    inputLabel='الزبون'
                    optionLabelAccessor='fullName'
                    options={users}
                    isLoading={isLoadingUsers}
                    error={Boolean(touched.userId && errors.userId)}
                    helperText={
                      touched.userId ? errors?.userId?.toString() : ''
                    }
                    fetchOptions={handleFetchUsers}
                    onSelectionChange={handleUserChange}
                  />
                </Grid>
              )}
            </Grid>
            <Divider
              style={{ marginTop: 20, marginBottom: 10 }}
              textAlign='left'
            >
              المشتريات
            </Divider>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='number'
                  label='قيمة المشتريات'
                  {...getFieldProps('orderTotal')}
                  variant='filled'
                  error={Boolean(touched.orderTotal && errors.orderTotal)}
                  helperText={touched.orderTotal && errors.orderTotal}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type='number'
                  label='قيمة الخصم'
                  {...getFieldProps('discountAmount')}
                  variant='filled'
                  error={Boolean(
                    touched.discountAmount && errors.discountAmount
                  )}
                  helperText={touched.discountAmount && errors.discountAmount}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.actionButton}
              color='error'
              variant='contained'
              onClick={handleClose}
            >
              الغاء
            </Button>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={
                !!Object.keys(errors ?? {}).length ||
                !isValid ||
                !dirty ||
                !values.marketId ||
                !values.userId ||
                !values.orderTotal
              }
              onClick={() => onSubmitForm()}
            >
              حفظ
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default OrderForm;

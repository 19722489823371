import { WinnerConfigs } from '@/types';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import ExtraCashCard from '../../components/ExtraCashCard';
import Iconify from '../../components/Iconify';
import { SnackbarContext } from '../../containers/SnackbarContext';
import { AddGiftDTO, GetWinnerDTO, User, useAxios } from '../../containers/api';
import API_CONSTANTS from '../../containers/api/API_CONSTANTS';
import useLocalStorage from '../../hooks/useLocalStorage';
import useUserPermissions from '../../hooks/useUserPermissions';
import WinnerConfigModal from './WinnerConfigModal';
import { CardDivider, ContentStyle, RootStyle } from './styles';
import users from './winning_users.json';
import { useDebounce } from '../../hooks/useDebounce';

interface WinnerUser {
  qr: string;
  name: string;
  phone: string;
}

const NO_WINNER: WinnerUser = { qr: '0000000000000', name: '', phone: '' };

const Winner: React.FC = () => {
  const { publicAxios } = useAxios();
  const [winnerModalOpen, setWinnerModalOpen] = useState(false);
  const permissions = useUserPermissions();
  const { storage, setItem } = useLocalStorage();
  const [isStarted, setIsStarted] = useState(false);

  const [index, setIndex] = useState(0);
  const [winnerUser, setWinner] = useState<WinnerUser | undefined>(undefined);
  const [shouldShowSave, setShouldShowSave] = useState(false);
  const winner = useRef<WinnerUser>(NO_WINNER);
  const interval = useRef<any>();
  const saveButtonTimeout = useRef<any>();
  const { showSnackbar } = useContext(SnackbarContext);

  const getFullName = (user: User) => {
    return [user.firstName, user.middleName, user.lastName]
      .filter(Boolean)
      .join(' ');
  };

  const handleGenerateWinner = ({
    orderFromMarket,
    paidAtLeast,
    orderStartDate,
    orderEndDate
  }: GetWinnerDTO) => {
    publicAxios
      .post(API_CONSTANTS.GET_WINNER, {
        orderFromMarket: orderFromMarket?.length ? orderFromMarket : undefined,
        paidAtLeast: paidAtLeast || undefined,
        orderStartDate: orderStartDate
          ? moment(orderStartDate).zone(-3).startOf('day').utc().format()
          : undefined,
        orderEndDate: orderEndDate
          ? moment(orderEndDate).zone(-3).endOf('day').utc().format()
          : undefined
      })
      .then((response) => {
        const winnerData = {
          qr: response.data?.qrCode ?? '',
          name: getFullName(response.data) ?? '',
          phone: response.data?.phone ?? ''
        };
        winner.current = winnerData;
        setWinner(winnerData);
        stopGenerator();
        showSaveButton();
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
      });
  };

  const handleSaveWinner = (values: AddGiftDTO) => {
    publicAxios
      .post(API_CONSTANTS.ADD_GIFT, values)
      .then(() => {
        showSnackbar({ message: 'تمت العملية بنجاح', severity: 'success' });
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ?? 'عذرا، حدث خطأ غير متوقع';
        showSnackbar({ message, severity: 'error' });
      });
  };

  const handleStart = () => {
    resetPage(true);
    interval.current = setInterval(() => {
      setIndex(() => Math.floor(Math.random() * users.users.length));
    }, 100);
  };

  const handleStop = () => {
    if (!storage.winnerPageConfigs.winnerUserConfigs?.winnerUser) {
      handleGenerateWinner({
        orderFromMarket:
          storage.winnerPageConfigs.marketWinnerConfigs?.orderFromMarket,
        paidAtLeast: storage.winnerPageConfigs.marketWinnerConfigs?.paidAtLeast,
        orderStartDate:
          storage.winnerPageConfigs.marketWinnerConfigs?.orderStartDate,
        orderEndDate:
          storage.winnerPageConfigs.marketWinnerConfigs?.orderEndDate
      });
    } else {
      const winnerData = {
        qr:
          storage.winnerPageConfigs.winnerUserConfigs?.winnerUser?.qrCode ?? '',
        name:
          storage.winnerPageConfigs.winnerUserConfigs?.winnerUser?.fullName ??
          '',
        phone:
          storage.winnerPageConfigs.winnerUserConfigs?.winnerUser?.phone ?? ''
      };
      winner.current = winnerData;
      setWinner(winnerData);
      showSaveButton();
      stopGenerator();
    }
  };

  const stopGenerator = () => {
    setIsStarted(false);
    clearInterval(interval.current);
  };

  const showSaveButton = () => {
    clearTimeout(saveButtonTimeout.current);
    saveButtonTimeout.current = setTimeout(() => {
      setShouldShowSave(true);
    }, 2000);
  };

  const hideSaveButton = () => {
    setShouldShowSave(false);
  };

  const handleSave = () => {
    handleSaveWinner({
      giftAmount: storage.winnerPageConfigs.giftAmount,
      giftSms: storage.winnerPageConfigs.giftSms || undefined,
      phone: winnerUser?.phone ?? '',
      giftValidUntil: storage.winnerPageConfigs.giftValidUntil
        ? moment(storage.winnerPageConfigs.giftValidUntil)
            .zone(-3)
            .endOf('day')
            .utc()
            .format()
        : undefined
    });

    resetPage();
  };

  const resetPage = (start = false) => {
    setIsStarted(start);
    setWinner(undefined);
    hideSaveButton();
    setIndex(0);
    winner.current = NO_WINNER;
    clearInterval(interval.current);
  };

  const handleSaveWinnerConfigs = (values: WinnerConfigs) => {
    setItem('winnerPageConfigs', values);
    resetPage();
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '10%',
          background:
            'linear-gradient(157deg, rgba(35,35,35,1) 11%, rgba(246,184,67,1) 100%)',
          display: 'flex',
          color: 'white',
          fontSize: 19,
          fontWeight: 'bold',
          boxShadow: '0px 10px 29px 4px rgb(0 0 0 / 45%)',
          borderBottomRightRadius: '40%',
          borderBottomLeftRadius: '40%',
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }}
      >
        {permissions.orders.write && (
          <IconButton onClick={() => setWinnerModalOpen(true)}>
            <Iconify
              icon='ant-design:setting-filled'
              width={20}
              height={20}
              sx={undefined}
            />
          </IconButton>
        )}
      </div>
      <RootStyle title='اكسترا كاش'>
        {winnerUser ? (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={100}
            recycle={true}
            run={!!winnerUser?.name}
            gravity={0.1}
            colors={['#e84393', '#0984e3', '#00b894', '#fdcb6e', '#d63031']}
          />
        ) : null}
        <Container>
          <ContentStyle>
            <Box
              component='img'
              src={storage.winnerPageConfigs.imgSrc}
              style={{
                width: '30%',
                paddingLeft: 24,
                paddingRight: 24,
                margin: 40
              }}
            />
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              justifyItems={'center'}
            >
              <Grid
                item
                xs={12}
                container
                spacing={2}
                justifyContent='center'
                alignItems='center'
                justifyItems={'center'}
              >
                <Grid item xs container spacing={1}>
                  <Grid item xs={12} justifyContent='left'>
                    <Typography
                      variant='h2'
                      component='div'
                      gutterBottom
                      textAlign='center'
                      style={{ float: 'left' }}
                    >
                      {storage.winnerPageConfigs.title}
                      <br />
                      {storage.winnerPageConfigs.subTitle}
                      <br />
                      <br />
                      {isStarted ? (
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            variant='contained'
                            color='secondary'
                            onClick={handleStop}
                            size='large'
                          >
                            ايقاف
                          </Button>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            variant='contained'
                            color='secondary'
                            onClick={handleStart}
                            size='large'
                            sx={{ marginLeft: 2 }}
                          >
                            بدء السحب
                          </Button>
                          {shouldShowSave && (
                            <Button
                              variant='contained'
                              color='secondary'
                              onClick={handleSave}
                              size='large'
                            >
                              حفظ
                            </Button>
                          )}
                        </div>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <CardDivider />
                </Grid>
                <Grid item xs style={{ height: 320 }}>
                  <ExtraCashCard
                    name={`${winnerUser?.name ?? ''}`}
                    qrCode={
                      `${winnerUser?.qr ?? ''}` ||
                      (users.users[index] ?? winner)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </ContentStyle>
        </Container>
        {winnerModalOpen && (
          <WinnerConfigModal
            onConfigsSaved={handleSaveWinnerConfigs}
            open={winnerModalOpen}
            setOpen={setWinnerModalOpen}
          />
        )}
      </RootStyle>
    </>
  );
};
export default Winner;

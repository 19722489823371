// component
import Iconify from '../../components/Iconify';
import { Permissions } from '../../hooks/useUserPermissions';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <Iconify icon={name} width={22} height={22} sx={undefined} />
);

const sidebarConfig = [
  {
    title: 'الرئيسية',
    path: '/pages/home',
    icon: getIcon('eva:pie-chart-2-fill'),
    canRoute: (permissions: Permissions) => {
      return (
        permissions.dashboard.points.read ||
        permissions.dashboard.totalCommissions.read ||
        permissions.dashboard.totalBills.read ||
        permissions.dashboard.monthlyPurchases.read ||
        permissions.dashboard.totalPurchases.read ||
        permissions.dashboard.totalReferrals.read ||
        permissions.dashboard.userProfilesCount.read
      );
    }
  },
  {
    title: 'المشرفين',
    path: '/pages/admins',
    icon: getIcon('eos-icons:admin'),
    canRoute: (permissions: Permissions) => permissions.admins.read
  },
  {
    title: 'مندوبي المبيعات',
    path: '/pages/sales',
    icon: getIcon('ant-design:user-switch-outlined'),
    canRoute: (permissions: Permissions) => permissions.sales.read
  },
  {
    title: 'المتاجر',
    path: '/pages/markets',
    icon: getIcon('bxs:store'),
    canRoute: (permissions: Permissions) => permissions.markets.read
  },
  {
    title: 'الزبائن',
    path: '/pages/users',
    icon: getIcon('bi:person-badge-fill'),
    canRoute: (permissions: Permissions) => permissions.users.read
  },
  {
    title: 'المشتريات',
    path: '/pages/orders',
    icon: getIcon('eva:shopping-bag-fill'),
    canRoute: (permissions: Permissions) => permissions.orders.read
  },
  {
    title: 'الحركات',
    path: '/pages/transactions',
    icon: getIcon('bx:transfer-alt'),
    canRoute: (permissions: Permissions) => permissions.transactions.read
  },
  {
    title: 'جوائز',
    path: '/pages/winner',
    icon: getIcon('mdi:prize'),
    canRoute: (permissions: Permissions) => permissions.prize.read
  },
  {
    title: 'انشاء كود',
    path: '/pages/generate',
    icon: getIcon('mdi:qrcode-plus'),
    canRoute: (permissions: Permissions) => permissions.qrCodes.generate
  }
];

export default sidebarConfig;

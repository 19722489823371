export const DEFAULT_FILTERS_STATE = {
  filterMarketName: '',
  filterAdminName: '',
  filterUserName: '',
  filterUserPhone: '',
  filterStartDate: '',
  filterEndDate: '',
  filterOnlineOrderId: '',
  onlyWithDiscount: false,
  onlyCreatedByAdmin: false,
  onlyArchivedOrders: false,
  onlyOnlineOrders: false
};

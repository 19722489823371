import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
// component
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

export interface ItemMore {
  label: string;
  icon: string;
  action: () => void;
}

export interface ItemMoreMenuProps {
  options?: Array<ItemMore>;
}

export const ItemMoreMenu: React.FC<ItemMoreMenuProps> = ({ options }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  if (!options?.length) {
    return null;
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify
          icon='eva:more-vertical-fill'
          width={20}
          height={20}
          sx={undefined}
        />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.label}
            component={RouterLink}
            to='#'
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              setIsOpen(false);
              option.action();
            }}
          >
            <ListItemIcon>
              <Iconify
                icon={option.icon}
                width={24}
                height={24}
                sx={undefined}
              />
            </ListItemIcon>
            <ListItemText
              primary={option.label}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ItemMoreMenu;
